import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getStoreLocator } from "../../reducer/userDetailsReducer";

import '../../css/Tiering.css';
import "../../css/Article.css";
import "../../css/StoreLocator.css";

import ComingSoon from "../../components/Modal/ComingSoon";
import { Button, Collapse, Select } from "antd";
import UserFooter from "../../components/UserFooter";
import whatsappIcon from "../../images/Haleon/Home/whatsappIcon.png";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../components/SubHeader";


const StoreLocator = () => {

  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSoon, setIsSoon] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const { mapStores, mapStates } = useSelector((state) => state.userDetails);
  const [storeList, setStoreList] = useState([]);
  const deliveryMethod = location.state.deliveryMethod || null;
  const addr = `4, Jalan Tun Mohd Fuad 2, TTDI, 6000 Kuala Lumpur \n 03-1234 5678`;

  useEffect(() => {
    dispatch(getStoreLocator());
  }, [getStoreLocator]);


  const handleOnChange = () => {
    if(!selectedState) {
      setErrorMsg('Please select the state name!');
      setTimeout(() => {
        setErrorMsg('');
      }, 1000);
    }
    else if(!selectedStore) {
      setErrorMsg('Please select the store name!');
      setTimeout(() => {
        setErrorMsg('');
      }, 1000);
    }
    else {
      setErrorMsg('');
      // console.log(deliveryMethod);
      // console.log(addr);
      // console.log(selectedStore);
      navigate("/cartSummary", { state: { deliveryMethod, addr: addr, selectedStore: selectedStore } });
    }
  };


  const handleStore = (valState) => {
    const filterStore = mapStores.filter((s) => s.state === valState);
    setStoreList(filterStore);
    setSelectedState(valState);
    setSelectedStore('');

  }

  return (
    <div>
    <SubHeader setPreviousPage={-1}/>
    <div className="ml-2 mr-2">

    <div className="mt-2">
      <h2 style={{ fontSize: '25px', fontWeight: '500' }}>Store Locator</h2>
      <p style={{ fontSize:'16px', fontWeight:'400', marginTop:'10px' }}>Find the closest outlets near you.</p>
    </div>

    {/* <div className='mt-2 scroll-img' style={{gap: '8px'}} >
        <Button className="active-btn">All</Button>
        <Button className="inactive-btn">AEON</Button>
        <Button className="inactive-btn">Lotus's</Button>
        <Button className="inactive-btn">Pharmacies</Button>
        <Button className="inactive-btn">Others</Button>
    </div> */}

    <div className="mt-2">

      <div>
      <Select
          value={selectedState}
          onChange={(e) => handleStore(e)}
          placeholder="State"
          style={{
                textAlign:'left',
                width: '100%', 
                }}
      >
        { mapStates.length  ?
          mapStates.map((state, index)=> {
          return (
              <Option key={index} value={state} style={{color:'white !important'}}>{state}</Option>
          )
          })
          :
          null
          
          }

      </Select>
      </div>

      <div className="mt-2">
      <Select
          value={selectedStore}
          onChange={e => setSelectedStore(e)}
          placeholder="Store Name"
          style={{
                textAlign:'left',
                width: '100%',
                }}
      >
        {storeList && storeList.length ? storeList.map((store, index) => (
          <Select.Option 
            key={index} 
            value={store.id}
          >
            {store?.name}
          </Select.Option>
        ))
      : 
       "Loading..."
      }
      </Select>
      </div>
      

      { selectedStore ?
         <div className="display-store-info-container mt-3 t-black">
          {/* <p style={{ fontSize:'18px', fontWeight:'420' }}>***Not yet fixed***</p> */}
          <p style={{ fontSize:'16px', fontWeight:'400' }}>{addr}</p>
         </div>
        : 
      null 
      }

    </div>

    {errorMsg && <div style={{ color: 'red', marginTop: '5px', textAlign:'center' }}>{errorMsg}</div>}

    <div style={{ marginTop:'30px', marginBottom:'120px' }}>
      <div style={{ width: '30%', paddingTop: '50px'  }}>
        <img src={whatsappIcon} alt="circle" style={{ width: '80px'}}/>
      </div>

     <Button
        style={{
          display:'flex',
          width:'100%',
          justifyContent:'center',
          alignItems:'center',
          borderRadius:'5px',
          backgroundColor: (selectedState && selectedStore) ?'#30E700' : "#DFDFDF",
          border: 'none',
          color: (selectedState && selectedStore) ?'black' : "#7C7C7C",
          marginTop:'30px',
        }}
        onClick = {handleOnChange}
      >Next</Button>

    </div>
    </div>
    
    <div>
      <UserFooter />
    </div>

    <ComingSoon isSoon={isSoon} setIsSoon={setIsSoon}/> 
  </div>
  )
}
export default StoreLocator;