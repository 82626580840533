import React, { useState } from 'react';
import { Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';
import carousel1 from '../images/Haleon/carousel1_sg.png';
import carousel2 from '../images/Haleon/carousel2_sg.png';

const Sg_carousel = () => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const navigate = useNavigate();
	const images = [
		{ img: carousel1, pageName: '/badges' },
		{ img: carousel2, pageName: null },
	];

	const handleImageClick = (pageName) => {
		if (pageName) {
			navigate(pageName);
		}
	};

	const onChange = (current) => {
		setCurrentSlide(current);
	};

	return (
		<div>
				<Carousel
					dots={true}
					afterChange={onChange}
					className="sg-carousel-container"
					dotPosition='bottom'
					// autoplay={true}
					// autoplaySpeed={5000}
				>
					{images.map((image, index) => (
						<div key={index} onClick={() => handleImageClick(image.pageName)}>
							<img
								src={image.img}
								alt={`carousel`}
								style={{ objectFit: 'contain', width:'100%', height:'100%' }}
							/>
						</div>
					))}
				</Carousel>
		</div>
	);
};

export default Sg_carousel;
