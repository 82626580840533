import React, { useEffect, useState } from 'react';
import SubHeader from '../../components/SubHeader';
import { Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import BadgesRedeemReward from '../../components/Modal/BadgesRedeemReward';
import axiosClient from '../../utils/axiosClient';
import tng from '../../images/Haleon/tng.png';
import grab from '../../images/Haleon/grab_green.svg';
import spay from '../../images/Haleon/spay.png';
import fairpriceLogo from '../../images/Haleon/Badges/FP-logo.png';
import infoLogo from '../../images/Haleon/Badges/info-logo.png';
import dateLogo from '../../images/Haleon/Badges/date-logo.png';
import watsonLogo from '../../images/Haleon/Badges/watsonLogo.png';
import guardianLogo from '../../images/Haleon/Badges/guardianLogo.png';
import lazShopLogo from '../../images/Haleon/Badges/lazShoppeLogo.png';
import { Select } from 'antd';
const { Option } = Select;


const Badges = ({ t }) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	const [userDetail, setUserDetail] = useState();
	const [missionCampaigns, setMissionCampaigns] = useState([]);

	const [selectedCampaign, setSelectedCampaign] = useState();
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const [totalRedemption, setTotalRedemption] = useState();
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');
	const [selectedChannel, setSelectedChannel] = useState('All');


	useEffect(() => {
		const fetchUserDetail = async () => {
			try {
				const res = await axiosClient.get('/users/details');

				if (res.data.data.personalInfo) {
					setUserDetail(res.data.data.personalInfo);
				} else {
					throw new Error('Fetching user details failed');
				}
			} catch (err) {
				console.error('result', err);
			}
		};
		fetchUserDetail();
	}, []);

	const fetchMissionWrapperValidity = async () => {
		try {
			const missionWrapperCampaignId =
				process.env.REACT_APP_BADGE_WRAPPER_CAMPAIGN_ID_SG;
			if (!missionWrapperCampaignId) {
				throw new Error('Invalid mission wrapper campaign id');
			}

			const res = await axiosClient.get(
				`/campaign-wrappers/${missionWrapperCampaignId}/validity`
			);

			if (
				res.data.data &&
				res.data.data.campaignValidations &&
				Array.isArray(res.data.data.campaignValidations)
			) {
				const filterMissionCampaigns = [];
				res.data.data.campaignValidations.forEach((validation) => {
					const _validation = validation.validationResults?.[0];
					if (_validation) {
						filterMissionCampaigns.push(_validation);
					}
				});

				setTotalRedemption(res.data.data);
				// setMissionCampaigns(filterMissionCampaigns);
				setMissionCampaigns(
					filterMissionCampaigns.filter((campaign) => {
						//   if (!userDetail?.registerSource?.haleonRewardsFirstLoginDate) {
						// 	return false;
						//
						const isCampaignEnded =
							new Date() > new Date(campaign.campaign.endAt);
						const isCampaignHaventStarted =
							new Date(campaign.campaign.startAt) > new Date();
						if (
							isCampaignHaventStarted ||
							(isCampaignEnded && !campaign.isValid)
						) {
							return false;
						} else {
							return true;
						}
					})
				);
			} else {
				throw new Error('Fetching badges failed');
			}
		} catch (err) {
			console.error('result', err);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchMissionBasedOnRegion = () => {
		if (isSGRegion) {
			fetchMissionWrapperValidity();
		} else {
			fetchMissionCampaigns();
		}
	};

	const fetchMissionCampaigns = async () => {
		try {
			const checkValidMission = await axiosClient.get(
				`/campaigns/validities?type=MISSION`
			);

			if (
				checkValidMission &&
				checkValidMission.data &&
				checkValidMission.data.data &&
				Array.isArray(checkValidMission.data.data)
			) {
				const filterMissionCampaigns = checkValidMission.data.data.filter(
					(campaign) => {
						if (
							userDetail?.registerSource?.haleonRewardsFirstLoginDate === null
						) {
							return false;
						}
						// if user registered date is greater than 30days
						// then, filter any mission campaign that is not valid and totalEntry is 0
						const userRegisteredDate = new Date(
							userDetail?.registerSource?.haleonRewardsFirstLoginDate
						);
						const currentDate = new Date();
						const diffTime = Math.abs(currentDate - userRegisteredDate);
						const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

						const isCampaignEnded = campaign?.campaign?.endAt
							? new Date(campaign?.campaign?.endAt) < new Date()
							: false;
						const isCampaignHaventStarted = campaign?.campaign?.startAt
							? new Date(campaign?.campaign?.startAt) > new Date()
							: false;

						if (isCampaignHaventStarted) {
							return false;
						} else if (isCampaignEnded) {
							return campaign.isValid;
						} else if (isCampaignEnded || diffDays > 30) {
							return (
								campaign.isValid || // show valid mission campaigns
								campaign?.totalEntry - campaign?.rejectedEntry > 0
							); // show redeemed mission campaigns
						} else {
							return true;
						}
					}
				);

				setMissionCampaigns(filterMissionCampaigns);
			} else {
				throw new Error('Fetching badges failed');
			}
		} catch (err) {
			console.error('result', err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (userDetail) {
			fetchMissionBasedOnRegion();
		}
	}, [userDetail]);

	const filteredBadges = missionCampaigns.filter(badge => 
		selectedChannel === 'All' || badge.campaign.config.channels.includes(selectedChannel)
	);

	const options = [
		{ value: 'All', label: 'All', image: '' },
		{ value: 'FP', label: 'Fair Price', image: 'assets/fairpriceLogo.png' },
		{ value: 'Watsons', label: 'Watsons', image: 'assets/watsonLogo.png' },
		{ value: 'GUARDIAN', label: 'Guardian', image: 'assets/guardianLogo.png' },
		{ value: 'SHOPEE', label: 'Lazada/Shopee', image: 'assets/lazShoppeLogo.png' },
	];


	return (
		<div>
			<SubHeader setPreviousPage={-1} />
			<div className="mt-1 ml-2 mr-2" style={{ paddingBottom: '10%' }}>
				<div className="mt-4">
					<h2>{t('Badges')}</h2>
					{isSGRegion && <p>Max redemption: {totalRedemption?.totalEntry}/5</p>}
				</div>
				{isLoading && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100px',
							width: '100%',
							textAlign: 'center',
						}}
					>
						Loading...
					</div>
				)}
				{isSGRegion && (
					 <Select
						value={selectedChannel}
						onChange={(value) => setSelectedChannel(value)}
						style={{ width: '100%', height: '60px', borderRadius: '12px',  }}
					>
						{options.map(option => (
						<Option key={option.value} value={option.value} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start', padding: '0.5rem 0.5rem' ,  width: '100%'}}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 'auto' }}>
								{option.value !== 'All' && (
									
								<img 
								src={option.image} 
								alt={option.label} 
								
								style={{width: option.label === 'Watsons' ? '30px' : '70px', objectFit: 'contain', marginRight: option.label === 'Watsons' ? '60px' : '20px'}} 
								/>
								)}
								<p style={{ fontSize: '16px'}}>

								{option.label}
								</p>
							</div>
						</Option>
						))}
					</Select>
				)}
				{selectedChannel === 'ALL' ? missionCampaigns : filteredBadges
					.sort((a, b) => {
						return (
							new Date(b?.campaign?.createdAt) -
							new Date(a?.campaign?.createdAt)
						);
					})
					.map((badge, index) => {
						const isRedeemed =
							badge.totalEntry >= badge?.campaign?.config?.maxEntry || // for individual mission based
							totalRedemption?.totalEntry >=
								totalRedemption?.campaign?.config?.maxEntry; // for mission wrapper based

						return (
							<div
								key={index}
								className="mt-2 d-flex"
							>
								{/* badges descripton and image */}
								{!isSGRegion ? (
									<>
									<div
									style={{border: '0.5px solid #565656',
									borderRadius: '12px', display: 'flex', width: '100%', padding: '2%'}}>
										{/* haleon MY */}
										{/* logo badges */}
										<div style={{ padding: '1rem', border: '2px solid #30E700', borderRadius: '12px' }}>
											<img
												src={badge?.campaign?.imageUrl}
												alt={`badge${index + 1}`}
												style={{
													objectFit:
														badge?.campaign?.id ===
														'34b2ea87-39ca-4f90-a749-a7e145aa700c'
															? null
															: 'contain',
													width:
														badge?.campaign?.id ===
														'85714899-59fe-4e5d-ba74-1268c591ed09'
															? '70px'
															: badge?.campaign?.id ===
															  '34b2ea87-39ca-4f90-a749-a7e145aa700c'
															? '88px'
															: '3rem',
													height:
														badge?.campaign?.id ===
														'34b2ea87-39ca-4f90-a749-a7e145aa700c'
															? 'auto'
															: '3rem',
													display: 'flex',
													margin: 'auto',
												}}
											/>
										</div>
										{/* description badges */}
										<div style={{ width: '50%', marginLeft: '2%' }}>
											{/* badges campaign name here */}
											<p className="font-weight-600 font-16">
												{badge.campaign.name}
											</p>
											{/* badges campaign description here */}
											<p
												className="font-weight-400"
												style={{ fontSize: '10px' }}
											>
												{badge.campaign.description}
											</p>
										</div>
										
										<div style={{ alignSelf: 'center' }}>
											{isRedeemed ? (
												<p
													style={{
														color: '#7C878E',
														fontFamily: 'Hero New',
														fontWeight: '500',
														fontSize: '14px',
														textAlign: 'center',
														width: '25%',
														marginRight: '2%',
													}}
												>
													{t('Claimed')}
												</p>
											) : (
												<Button
												// disabled={!badge.isValid}
												style={{
												
													background: badge.isValid ? '#30E700' : '#DCFF00',
													color: '#000000',
													borderRadius: '6px',
													border: 'none',
													fontSize: '14px',
													fontWeight: '500',
													fontFamily: 'Hero New'
												}}
												onClick={() => {
													if (
														totalRedemption?.totalEntry === 5 &&
														!badge.isValid
													) {
														message.error(
															'You have reached maximum redemption limit!'
														);
													} else if (badge.isValid) {
														setSelectedCampaign(badge);
													} else {
														navigate('/upload');
													}
												}}
												>
													{badge.isValid ? (
														<>{getLanguage === 'bm' ? 'Tuntut' : t('Claim')}</>
													) : (
														t('Redeem')
													)}
												</Button>
												
											)}
										</div>
									</div>
									</>
								) : (
									<>
									<div>
										<div>
											{/* haleon SG */}
											{badge?.campaign?.config?.channels?.includes('FP') && (
												<div
													style={{
														border: '0.5px solid #565656',
														borderRadius: '12px'
													}}
												>
													{/* upper badges */}
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															padding: '0.5rem 1rem',
															fontFamily: 'Hero New',
															fontSize: '18px',
															fontWeight: '600',
															alignItems: 'center',
														}}
													>
														<div>
															<img style={{ width: '100%' }} src={fairpriceLogo} alt="Fairprice Logo" />
														</div>
														<div style={{ width: '40%', textAlign: 'right' }}>
															{badge.campaign.name
																.replace(/^GET\s*/, '') // Remove 'GET ' from the beginning
																.split('\n')[0] // Get the first line
																}
														</div>
													</div>
													{/* bottom badges */}
													<div
														style={{
															display: 'flex',
															padding: '1rem',
															backgroundColor: 'red', 
															overflow: 'hidden',
															borderBottomLeftRadius: '12px',
															borderBottomRightRadius: '12px',
														}}
													>
														{/* left bottom badges */}
														<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'start',  }}>
															{/* info */}
															<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																<img
																	src={infoLogo}
																	alt={`info`}
																	style={{ width: '15px', height: '15px', marginRight: '4px' }}
																/>
																<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}>{badge.campaign.name.match(/with purchase of \$\d+/)}</p>
															</div>
															{/* date */}
															<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																<img
																	src={dateLogo}
																	alt={`date`}
																	style={{ width: '15px', height: '15px', marginRight: '4px' }}
																/>
																<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}> {badge.campaign.description.match(/\d{1,2} \w{3} - \d{1,2} \w{3} \d{4}/)}</p>
															</div>
															{/* description */}
															<div
																style={{ fontSize: '7px', color: 'white', margin: '0.5rem 0', fontFamily: 'Hero New' }}
															>
																{badge.campaign.description
																.split('\n')[0]
																.split(' Only ')[0] 
																.trim()
																.split('\n')
																
										}
															</div>
															{/* logo */}
															<div style={{backgroundColor:'white', borderRadius: '10px'}}>
																<img
																	src={badge?.campaign?.imageUrl}
																	alt={`badge${index + 1}`}
																	style={{
																		objectFit: 'contain',
																		// height: '60px',
																		width: '170px',
																		display: 'flex',
																		margin: 'auto',
																	}}
																/>
															</div>
														</div>
														{/* right bottom badges */}
														<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
															{isRedeemed ? (
																<p
																	style={{
																		color: '#7C878E',
																		fontFamily: 'Hero New',
																		fontWeight: '500',
																		fontSize: '14px',
																		textAlign: 'center',
																		width: '25%',
																		marginRight: '2%',
																	}}
																>
																	{t('Claimed')}
																</p>
															) : (
																<Button
																// disabled={!badge.isValid}
																style={{
																
																	background: badge.isValid ? '#30E700' : '#DCFF00',
																	color: '#000000',
																	borderRadius: '6px',
																	border: 'none',
																	fontSize: '14px',
																	fontWeight: '500',
																	fontFamily: 'Hero New'
																}}
																onClick={() => {
																	if (
																		totalRedemption?.totalEntry === 5 &&
																		!badge.isValid
																	) {
																		message.error(
																			'You have reached maximum redemption limit!'
																		);
																	} else if (badge.isValid) {
																		setSelectedCampaign(badge);
																	} else {
																		navigate('/upload');
																	}
																}}
																>
																	{badge.isValid ? (
																		<>{getLanguage === 'bm' ? 'Tuntut' : t('Claim')}</>
																	) : (
																		t('Redeem')
																	)}
																</Button>
																
															)}
														</div>
													</div>
												</div>
											)}

											{badge?.campaign?.config?.channels?.includes('Watsons') && (
												<div
													style={{
														border: '0.5px solid #565656',
														borderRadius: '12px'
													}}
												>
													{/* upper badges */}
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															padding: '0.5rem 1rem',
															fontFamily: 'Hero New',
															fontSize: '18px',
															fontWeight: '600',
															alignItems: 'center',
														}}
													>
														<div>
															<img style={{ width: '100%' }} src={watsonLogo} alt="Fairprice Logo" />
														</div>
														<div style={{ width: '40%', textAlign: 'right' }}>
															{badge.campaign.name
																.replace(/^GET\s*/, '') // Remove 'GET ' from the beginning
																.split('\n')[0] // Get the first line
															
																
																}
														</div>
													</div>
													{/* bottom badges */}
													<div
														style={{
															display: 'flex',
															padding: '1rem',
															backgroundColor: '#00A9A6', 
															overflow: 'hidden',
															borderBottomLeftRadius: '12px',
															borderBottomRightRadius: '12px',
														}}
													>
														{/* left bottom badges */}
														<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'start',  }}>
															{/* info */}
															<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																<img
																	src={infoLogo}
																	alt={`info`}
																	style={{ width: '15px', height: '15px', marginRight: '4px' }}
																/>
																<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}>{badge.campaign.name.match(/with purchase of \$\d+/)}</p>
															</div>
															{/* date */}
															<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																<img
																	src={dateLogo}
																	alt={`date`}
																	style={{ width: '15px', height: '15px', marginRight: '4px' }}
																/>
																<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}> {badge.campaign.description.match(/\d{1,2} \w{3} - \d{1,2} \w{3} \d{4}/)}</p>
															</div>
															{/* description */}
															<div
																style={{ fontSize: '7px', color: 'white', margin: '0.5rem 0', fontFamily: 'Hero New' }}
															>
																{badge.campaign.description
																.split('\n')[0]
																.split(' Only ')[0] 
																.trim()
																.split('\n')					
										}
															</div>
															{/* logo */}
															<div style={{backgroundColor:'white', borderRadius: '10px'}}>
																<img
																	src={badge?.campaign?.imageUrl}
																	alt={`badge${index + 1}`}
																	style={{
																		objectFit: 'contain',
																		// height: '60px',
																		width: '170px',
																		display: 'flex',
																		margin: 'auto',
																	}}
																/>
															</div>
														</div>
														{/* right bottom badges */}
														<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
															{isRedeemed ? (
																<p
																	style={{
																		color: '#7C878E',
																		fontFamily: 'Hero New',
																		fontWeight: '500',
																		fontSize: '14px',
																		textAlign: 'center',
																		width: '25%',
																		marginRight: '2%',
																	}}
																>
																	{t('Claimed')}
																</p>
															) : (
																<Button
																// disabled={!badge.isValid}
																style={{
																
																	background: badge.isValid ? '#30E700' : '#DCFF00',
																	color: '#000000',
																	borderRadius: '6px',
																	border: 'none',
																	fontSize: '14px',
																	fontWeight: '500',
																	fontFamily: 'Hero New'
																}}
																onClick={() => {
																	if (
																		totalRedemption?.totalEntry === 5 &&
																		!badge.isValid
																	) {
																		message.error(
																			'You have reached maximum redemption limit!'
																		);
																	} else if (badge.isValid) {
																		setSelectedCampaign(badge);
																	} else {
																		navigate('/upload');
																	}
																}}
																>
																	{badge.isValid ? (
																		<>{getLanguage === 'bm' ? 'Tuntut' : t('Claim')}</>
																	) : (
																		t('Redeem')
																	)}
																</Button>
																
															)}
														</div>
													</div>
												</div>
											)}

											{badge?.campaign?.config?.channels?.includes(
												'GUARDIAN'
											) && (
												<div
														style={{
															border: '0.5px solid #565656',
															borderRadius: '12px'
														}}
													>
														{/* upper badges */}
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																padding: '0.5rem 1rem',
																fontFamily: 'Hero New',
																fontSize: '18px',
																fontWeight: '600',
																alignItems: 'center',
															}}
														>
															<div>
																<img style={{ width: '100%' }} src={guardianLogo} alt="Fairprice Logo" />
															</div>
															<div style={{ width: '40%', textAlign: 'right' }}>
																{badge.campaign.name
																	.replace(/^GET\s*/, '') // Remove 'GET ' from the beginning
																	.split('\n')[0] // Get the first line
																
																	
																	}
															</div>
														</div>
														{/* bottom badges */}
														<div
															style={{
																display: 'flex',
																padding: '1rem',
																backgroundColor: '#F98300', 
																overflow: 'hidden',
																borderBottomLeftRadius: '12px',
																borderBottomRightRadius: '12px',
															}}
														>
															{/* left bottom badges */}
															<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'start',  }}>
																{/* info */}
																<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																	<img
																		src={infoLogo}
																		alt={`info`}
																		style={{ width: '15px', height: '15px', marginRight: '4px' }}
																	/>
																	<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}>{badge.campaign.name.match(/with purchase of \$\d+/)}</p>
																</div>
																{/* date */}
																<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																	<img
																		src={dateLogo}
																		alt={`date`}
																		style={{ width: '15px', height: '15px', marginRight: '4px' }}
																	/>
																	<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}> {badge.campaign.description.match(/\d{1,2} \w{3} - \d{1,2} \w{3} \d{4}/)}</p>
																</div>
																{/* description */}
																<div
																	style={{ fontSize: '7px', color: 'white', margin: '0.5rem 0', fontFamily: 'Hero New' }}
																>
																	{badge.campaign.description
																	.split('\n')[0]
																	.split(' Only ')[0] 
																	.trim()
																	.split('\n')
																	
											}
																</div>
																{/* logo */}
																<div style={{backgroundColor:'white', borderRadius: '10px'}}>
																	<img
																		src={badge?.campaign?.imageUrl}
																		alt={`badge${index + 1}`}
																		style={{
																			objectFit: 'contain',
																		// height: '60px',
																		width: '170px',
																		display: 'flex',
																		margin: 'auto',
																		}}
																	/>
																</div>
															</div>
															{/* right bottom badges */}
															<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
																{isRedeemed ? (
																	<p
																		style={{
																			color: '#7C878E',
																			fontFamily: 'Hero New',
																			fontWeight: '500',
																			fontSize: '14px',
																			textAlign: 'center',
																			width: '25%',
																			marginRight: '2%',
																		}}
																	>
																		{t('Claimed')}
																	</p>
																) : (
																	<Button
																	// disabled={!badge.isValid}
																	style={{
																	
																		background: badge.isValid ? '#30E700' : '#DCFF00',
																		color: '#000000',
																		borderRadius: '6px',
																		border: 'none',
																		fontSize: '14px',
																		fontWeight: '500',
																		fontFamily: 'Hero New'
																	}}
																	onClick={() => {
																		if (
																			totalRedemption?.totalEntry === 5 &&
																			!badge.isValid
																		) {
																			message.error(
																				'You have reached maximum redemption limit!'
																			);
																		} else if (badge.isValid) {
																			setSelectedCampaign(badge);
																		} else {
																			navigate('/upload');
																		}
																	}}
																	>
																		{badge.isValid ? (
																			<>{getLanguage === 'bm' ? 'Tuntut' : t('Claim')}</>
																		) : (
																			t('Redeem')
																		)}
																	</Button>
																	
																)}
															</div>
														</div>
												</div>
											)}

											{(badge?.campaign?.config?.channels?.includes(
												"SHOPEE"
											) && badge?.campaign?.config?.channels?.includes(
												"LAZADA"
											)) && (
											<div
													style={{
														border: '0.5px solid #565656',
														borderRadius: '12px'
													}}
												>
													{/* upper badges */}
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															padding: '0.5rem 1rem',
															fontFamily: 'Hero New',
															fontSize: '18px',
															fontWeight: '600',
															alignItems: 'center',
														}}
													>
														<div>
															<img style={{ width: '100%' }} src={lazShopLogo} alt="Fairprice Logo" />
														</div>
														<div style={{ width: '40%', textAlign: 'right' }}>
															{badge.campaign.name
																.replace(/^GET\s*/, '') // Remove 'GET ' from the beginning
																.split('\n')[0] // Get the first line
															
																
																}
														</div>
													</div>
													{/* bottom badges */}
													<div
														style={{
															display: 'flex',
															padding: '1rem',
															backgroundColor: '#F93189', 
															overflow: 'hidden',
															borderBottomLeftRadius: '12px',
															borderBottomRightRadius: '12px',
														}}
													>
														{/* left bottom badges */}
														<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'start',  }}>
															{/* info */}
															<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																<img
																	src={infoLogo}
																	alt={`info`}
																	style={{ width: '15px', height: '15px', marginRight: '4px' }}
																/>
																<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}>{badge.campaign.name.match(/with purchase of \$\d+/)}</p>
															</div>
															{/* date */}
															<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																<img
																	src={dateLogo}
																	alt={`date`}
																	style={{ width: '15px', height: '15px', marginRight: '4px' }}
																/>
																<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}> {badge.campaign.description.match(/\d{1,2} \w{3} - \d{1,2} \w{3} \d{4}/)}</p>
															</div>
															{/* description */}
															<div
																style={{ fontSize: '7px', color: 'white', margin: '0.5rem 0', fontFamily: 'Hero New' }}
															>
																{badge.campaign.description
																.split('\n')[0]
																.split(' Only ')[0] 
																.trim()
																.split('\n')
																
										}
															</div>
															{/* logo */}
															<div style={{backgroundColor:'white', borderRadius: '10px'}}>
																<img
																	src={badge?.campaign?.imageUrl}
																	alt={`badge${index + 1}`}
																	style={{
																		objectFit: 'contain',
																		// height: '60px',
																		width: '170px',
																		display: 'flex',
																		margin: 'auto',
																	}}
																/>
															</div>
														</div>
														{/* right bottom badges */}
														<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
															{isRedeemed ? (
																<p
																	style={{
																		color: '#7C878E',
																		fontFamily: 'Hero New',
																		fontWeight: '500',
																		fontSize: '14px',
																		textAlign: 'center',
																		width: '25%',
																		marginRight: '2%',
																	}}
																>
																	{t('Claimed')}
																</p>
															) : (
																<Button
																// disabled={!badge.isValid}
																style={{
																
																	background: badge.isValid ? '#30E700' : '#DCFF00',
																	color: '#000000',
																	borderRadius: '6px',
																	border: 'none',
																	fontSize: '14px',
																	fontWeight: '500',
																	fontFamily: 'Hero New'
																}}
																onClick={() => {
																	if (
																		totalRedemption?.totalEntry === 5 &&
																		!badge.isValid
																	) {
																		message.error(
																			'You have reached maximum redemption limit!'
																		);
																	} else if (badge.isValid) {
																		setSelectedCampaign(badge);
																	} else {
																		navigate('/upload');
																	}
																}}
																>
																	{badge.isValid ? (
																		<>{getLanguage === 'bm' ? 'Tuntut' : t('Claim')}</>
																	) : (
																		t('Redeem')
																	)}
																</Button>
																
															)}
														</div>
													</div>
											</div>
											)}

											{(	!badge?.campaign?.config?.channels?.includes('FP') &&
												!badge?.campaign?.config?.channels?.includes('Watsons') &&
												!badge?.campaign?.config?.channels?.includes('GUARDIAN') &&
												!badge?.campaign?.config?.channels?.includes('SHOPEE') &&
												!badge?.campaign?.config?.channels?.includes('LAZADA')) && (
												<div
													style={{
														border: '0.5px solid #565656',
														borderRadius: '12px'
													}}
												>
													{/* upper badges */}
													<div
														style={{
															display: 'flex',
															justifyContent: 'end',
															padding: '0.5rem 1rem',
															fontFamily: 'Hero New',
															fontSize: '18px',
															fontWeight: '600',
															alignItems: 'center',
														}}
													>
													
														<div style={{ width: '40%', textAlign: 'right' }}>
															{badge.campaign.name
																.replace(/^GET\s*/, '') // Remove 'GET ' from the beginning
																.split('\n')[0] // Get the first line
															
																
																}
														</div>
													</div>
													{/* bottom badges */}
													<div
														style={{
															display: 'flex',
															padding: '1rem',
															backgroundColor: '#000000', 
															overflow: 'hidden',
															borderBottomLeftRadius: '12px',
															borderBottomRightRadius: '12px',
														}}
													>
														{/* left bottom badges */}
														<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'start',  }}>
															{/* info */}
															<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																<img
																	src={infoLogo}
																	alt={`info`}
																	style={{ width: '15px', height: '15px', marginRight: '4px' }}
																/>
																<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}>{badge.campaign.name.match(/with purchase of \$\d+/)}</p>
															</div>
															{/* date */}
															<div style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center' }}>
																<img
																	src={dateLogo}
																	alt={`date`}
																	style={{ width: '15px', height: '15px', marginRight: '4px' }}
																/>
																<p style={{ color: 'white', marginLeft: '1rem', fontFamily: 'Hero New', fontSize: '10px' }}> {badge.campaign.description.match(/\d{1,2} \w{3} - \d{1,2} \w{3} \d{4}/)}</p>
															</div>
															{/* description */}
															<div
																style={{ fontSize: '7px', color: 'white', margin: '0.5rem 0', fontFamily: 'Hero New' }}
															>
																{badge.campaign.description
																.split('\n')[0]
																.split(' Only ')[0] 
																.trim()
																.split('\n')
																
													}
															</div>
															{/* logo */}
															<div style={{backgroundColor:'white', borderRadius: '10px'}}>
																<img
																	src={badge?.campaign?.imageUrl}
																	alt={`badge${index + 1}`}
																	style={{
																		objectFit: 'contain',
																		// height: '60px',
																		width: '170px',
																		display: 'flex',
																		margin: 'auto',
																	}}
																/>
															</div>
														</div>
														{/* right bottom badges */}
														<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
															{isRedeemed ? (
																<p
																	style={{
																		color: '#7C878E',
																		fontFamily: 'Hero New',
																		fontWeight: '500',
																		fontSize: '14px',
																		textAlign: 'center',
																		width: '25%',
																		marginRight: '2%',
																	}}
																>
																	{t('Claimed')}
																</p>
															) : (
																<Button
																// disabled={!badge.isValid}
																style={{
																
																	background: badge.isValid ? '#30E700' : '#DCFF00',
																	color: '#000000',
																	borderRadius: '6px',
																	border: 'none',
																	fontSize: '14px',
																	fontWeight: '500',
																	fontFamily: 'Hero New'
																}}
																onClick={() => {
																	if (
																		totalRedemption?.totalEntry === 5 &&
																		!badge.isValid
																	) {
																		message.error(
																			'You have reached maximum redemption limit!'
																		);
																	} else if (badge.isValid) {
																		setSelectedCampaign(badge);
																	} else {
																		navigate('/upload');
																	}
																}}
																>
																	{badge.isValid ? (
																		<>{getLanguage === 'bm' ? 'Tuntut' : t('Claim')}</>
																	) : (
																		t('Redeem')
																	)}
																</Button>
															)}
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
									</>
								)}
							</div>
						);
					})}
				{/* Back button */}
				{isLoading ? null : (
					<>
						{!isSGRegion && (
							<div
								style={{
									display: 'flex',
									paddingTop: '5%',
									alignItems: 'center',
								}}
							>
								<div style={{ width: '60%' }}>
									<p
										style={{
											fontSize: '14px',
											fontWeight: '600',
											paddingLeft: '7%',
										}}
									>
										eWallet Vouchers Partners
									</p>
								</div>
								<div
									style={{
										width: '40%',
										display: 'flex',
										justifyContent: 'center',
										gap: '10%',
										alignItems: 'center',
										paddingRight: '5%',
									}}
								>
									<img
										alt="tng"
										src={tng}
										style={{
											width: '22%',
											height: '22%',
											objectFit: 'contain',
										}}
									/>
									<img
										alt="grab"
										src={grab}
										style={{
											width: '25%',
											height: '25%',
											objectFit: 'contain',
										}}
									/>
									<img
										alt="spay"
										src={spay}
										style={{
											width: '22%',
											height: '22%',
											objectFit: 'contain',
										}}
									/>
								</div>
							</div>
						)}
						<Button
							style={{
								width: '100%',
								background: '#30E700',
								borderRadius: '5px',
								border: 'none',
								fontSize: '16px',
								fontWeight: '600',
								marginTop: '10%',
								height: '2.5rem',
							}}
							onClick={() => navigate(-1)}
						>
							{t('Back')}
						</Button>{' '}
					</>
				)}
			</div>
			<BadgesRedeemReward
				selectedMission={selectedCampaign}
				redeem={selectedCampaign}
				setRedeem={setSelectedCampaign}
				handleSuccess={() => {
					fetchMissionBasedOnRegion();
					setSelectedCampaign();
				}}
			/>
		</div>
	);
};

export default Badges;
