import '../../../css/Reward.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Collapse, Input, Modal, Rate, Select, message } from 'antd';
import moment from 'moment';
import { CopyToClipboard as CopyToClipboardLib } from 'react-copy-to-clipboard';
import {
	DownOutlined,
	UpOutlined,
	StarOutlined,
	StarFilled,
} from '@ant-design/icons';

import HowToRedeemGrab from '../../../components/Modal/HowToRedeemGrab';
import HowToRedeemTNG from '../../../components/Modal/HowToRedeemTNG';
import MainHeader from '../../../components/MainHeader';
import TermsGrab from '../../../components/Modal/TermsGrab';
import TermsTNG from '../../../components/Modal/TermsTNG';
import UserFooter from '../../../components/UserFooter';
import confirmationIcon from '../../../images/Haleon/confirmation.svg';
import copyPng from '../../../images/Haleon/copy.png';
import delivery from '../../../images/Haleon/deliveryOrder.svg';
import deliveryIcon from '../../../images/Haleon/deliveryIcon.svg';
import trackOrderIcon from '../../../images/Haleon/trackOrderIcon.svg';
import BarcodeTimer from '../../../components/BarcodeTimer';
import axiosClient from '../../../utils/axiosClient';
import HowToRedeemSpay from '../../../components/Modal/HowToRedeemSpay';
import TermsSpay from '../../../components/Modal/TermsSpay';
import ComingSoon from '../../../components/Modal/ComingSoon';
import HowToRedeemCapitaVoucher from '../../../components/Modal/HowToRedeemCapitaVoucher';
import TermsCapitaVoucher from '../../../components/Modal/TermsCapitaVoucher';
import capitaVoucher from '../../../images/Haleon/capitaVoucher.svg';
import { filterItems } from './constants';
import HowToRedeemGuardian from './components/HowToRedeemGuardian';
import TermsGuardian from './components/TermsGuardian';
import HowToRedeemWatsons from './components/HowToRedeemWatsons';
import TermsWatsons from './components/TermsWatsons';
import HowToRedeemFairprice from './components/HowToRedeemFairprice';
import TermsFairprice from './components/TermsFairprice';

const { Panel } = Collapse;

const Rewards = ({ t }) => {
	const navigate = useNavigate();
	const [showingRedeemModal, setShowingRedeemModal] = useState(null);
	const [showingTNCModal, setShowingTNCModal] = useState(null);
	const [rating, setRating] = useState('');
	const [productRating, setProductRating] = useState(false);
	const [review, setReview] = useState('');
	const [errorMsg, setErrorMsg] = useState(false);
	const [successMsg, setSuccessMsg] = useState(false);
	const [expandedItems, setExpandedItems] = useState([]);
	const [redeem, setRedeem] = useState(null);
	const [showBarcode, setShowBarcode] = useState(false);
	const [startCountdown, setStartCountdown] = useState(false);
	const [reviewProduct, setReviewProduct] = useState();
	const [product, setProduct] = useState();
	const [voucher, setVoucher] = useState();
	const [clickedVouchers, setClickedVouchers] = useState([]);
	const [forceRenderKey, setForceRenderKey] = useState(0);

	useEffect(() => {
		// Fetch data for the current month when the component mounts
		fetchDataForCurrentMonth();
	}, []); // Empty dependency array ensures this effect runs only once, equivalent to componentDidMount

	useEffect(() => {
		if (forceRenderKey && forceRenderKey !== 0) {
			fetchDataForCurrentMonth();
		}
	}, [forceRenderKey]);

	const fetchDataForCurrentMonth = async () => {
		try {
			const currentMonth = new Date().getMonth() + 1;
			const res = await axiosClient.get('/order/get', {
				params: {
					dateFilter: currentMonth.toString(),
				},
			});
			setProduct(res.data.checkProduct);
			setVoucher(res.data.checkVoucher);
		} catch (error) {
			console.error('Error fetching data:', error);
			// Handle error as needed
		}
	};

	const handleFilterChange = async (value) => {
		try {
			let dateFilter;

			if (value === 'Filter By') {
				// If "Filter By" is selected, set dateFilter to the current month
				const currentMonth = new Date().getMonth() + 1;
				dateFilter = currentMonth.toString();
			} else {
				// Use the selected value as dateFilter
				dateFilter = value;
			}

			const res = await axiosClient.get('/order/get', {
				params: {
					dateFilter,
				},
			});

			setProduct(res.data.checkProduct);
			setVoucher(res.data.checkVoucher);
		} catch (error) {
			console.error('Error fetching data:', error);
			// Handle error as needed
		}
	};

	const displayHowToRedeem = (val) => {
		if (val === 'TNG') {
			setShowingRedeemModal('TNG');
		} else if (val === 'GRABPAY') {
			setShowingRedeemModal('GRABPAY');
		} else if (val === 'SPAY') {
			setShowingRedeemModal('SPAY');
		} else if (val === 'Capita Voucher') {
			setShowingRedeemModal('Capita Voucher');
		} else if (val === 'Guardian') {
			setShowingRedeemModal('Guardian');
		} else if (val === 'Watsons') {
			setShowingRedeemModal('Watsons');
		} else if (val === 'Fairprice') {
			setShowingRedeemModal('Fairprice');
		} else {
			setShowingRedeemModal(null);
		}
	};

	const displayTNC = (val) => {
		if (val === 'TNG') {
			setShowingTNCModal('TNG');
		} else if (val === 'GRABPAY') {
			setShowingTNCModal('GRABPAY');
		} else if (val === 'SPAY') {
			setShowingTNCModal('SPAY');
		} else if (val === 'Capita Voucher') {
			setShowingTNCModal('Capita Voucher');
		} else if (val === 'Guardian') {
			setShowingTNCModal('Guardian');
		} else if (val === 'Watsons') {
			setShowingTNCModal('Watsons');
		} else if (val === 'Fairprice') {
			setShowingTNCModal('Fairprice');
		} else {
			return null;
		}
	};

	const voucherClick = async (index, ele) => {
		navigator.clipboard.writeText(ele.rewardInstance.value);
		message.success('Copied to clipboard!');

		try {
			const res = await axiosClient.post('/voucher/check', {
				id: ele.rewardInstanceId,
				value: ele.rewardInstance.value,
			});

			if (res) {
				setClickedVouchers((prevClickedVouchers) => [
					...prevClickedVouchers,
					index,
				]);
				// message.success('Copied to clipboard!');
			} else {
				message.warning(res.data.error);
			}
		} catch (error) {
			console.error('Error:', error);
			// Show a general error message for network or server errors
			message.warning('Failed to redeem code. Please try again.');
		}
	};

	const handleRatingChange = (value) => {
		setRating(value);
	};

	const handleUserReview = (e) => {
		setReview(e.target.value);
	};

	const handleReviewRating = (item) => {
		if (!rating) {
			setErrorMsg('Please leave the rating of the product!');
			setTimeout(() => {
				setErrorMsg(false);
			}, 1000);
		}

		if (!review) {
			setErrorMsg('Please leave the review of the product!');
			setTimeout(() => {
				setErrorMsg(false);
			}, 1000);
		}

		const data = {
			orderId: item.orderId,
			comment: review,
			rating: rating,
			shopItemId: item.id,
		};

		axiosClient
			.post('/item-reviews', data)
			.then((res) => {
				setSuccessMsg(true);
				setTimeout(() => {
					setSuccessMsg(false);
				}, 1000);
				setProductRating(false);
				setReviewProduct();
				setForceRenderKey((prev) => prev + 1);
			})
			.catch((err) => {
				console.error(err);
				setErrorMsg(err?.response?.data?.message || 'Failed to submit review.');
			});
	};

	const toggleDropdown = (index) => {
		if (expandedItems.includes(index)) {
			setExpandedItems(
				expandedItems.filter((itemIndex) => itemIndex !== index)
			);
		} else {
			setExpandedItems([...expandedItems, index]);
		}
	};

	return (
		<div>
			<div>
				<MainHeader />
			</div>

			<div className="ml-2 mr-2">
				<div
					className="mt-5"
					style={{
						justifyContent: 'space-evenly',
						display: 'flex',
						gap: '8px',
					}}
				>
					<Button
						className="btn-color"
						style={{
							backgroundColor: '#C2C2C2',
							color: 'white',
						}}
						onClick={() => {
							navigate('/pointshop');
						}}
					>
						{t('Point Shop')}
					</Button>

					<Button
						className="btn-color"
						style={{
							backgroundColor: 'black',
							color: 'white',
						}}
						onClick={() => navigate('/rewards')}
					>
						{t('My Rewards')}
					</Button>
				</div>

				<div style={{ paddingBottom: '120px' }}>
					<div
						className="mt-2"
						style={{ display: 'flex', justifyContent: 'right' }}
					>
						<Select
							defaultValue={t('Filter By')}
							className="select-tag text-center"
							onChange={handleFilterChange}
							popupClassName="select-dropdown"
						>
							{filterItems.map((item) => (
								<Select.Option key={item.key} value={item.value}>
									{item.label}
								</Select.Option>
							))}
						</Select>
					</div>

					{/* reward's voucher list */}
					<div>
						<div style={{ alignItems: 'center' }}>
							{product && product.length ? (
								<h2
									style={{
										fontSize: '20px',
										fontWeight: '500',
										marginBottom: '10px',
										fontFamily: 'Hero New',
									}}
								>
									Physical Rewards
								</h2>
							) : null}
							<Collapse
								defaultActiveKey={['']}
								bordered={false}
								expandIcon={({ isActive }) =>
									isActive ? (
										<UpOutlined
											style={{
												fontWeight: '500',
												marginBottom: '10px',

												position: 'absolute',
												top: '0',
											}}
										/>
									) : (
										<DownOutlined />
									)
								}
								expandIconPosition="end"
								style={{
									backgroundColor: 'white',
									boxShadow: '0px 1px 16px 0px rgba(0, 0, 0, 0.10)',
									color: 'black',
									position: 'relative',
								}}
							>
								{product?.length &&
									// <div>
									product.map((order, index) => {
										return (
											<Panel
												style={{
													borderRadius: '10px',
													background: 'white',
													boxShadow: '0px 1px 16px 0px rgba(0, 0, 0, 0.10)',
													marginTop: '10px',
													color: 'black',
												}}
												key={`physical_${index}`}
												header={
													<div key={`physical_${index}`}>
														<div
															className="delivery-container"
															onClick={() => toggleDropdown(index)}
														>
															<div className="delivery-icon-container">
																<img
																	src={delivery}
																	alt=""
																	style={{
																		width:
																			order.deliveryOption === 'pickup'
																				? '80%'
																				: '60%',
																		objectFit: 'contain',
																	}}
																/>
															</div>
															<div>
																<p
																	style={{
																		fontWeight: 500,
																		fontSize: '18px',
																		fontFamily: 'Hero New',
																		marginBottom: '4px',
																	}}
																>
																	Order ID:{' '}
																	<span
																		style={{
																			textTransform: 'uppercase',
																		}}
																	>
																		{order.id.replaceAll('-', '').slice(0, 7)}
																	</span>
																</p>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'left',
																		gap: '20px',
																	}}
																>
																	<p
																		style={{
																			fontWeight: '400',
																			fontSize: '16px',
																			color: '#666666',
																			fontFamily: 'Hero New',
																		}}
																	>
																		{' '}
																		{order.orderItem.reduce(
																			(sum, item) => sum + item.qty,
																			0
																		)}{' '}
																		item(s)
																	</p>
																	<p
																		style={{
																			color: '#505050',
																			fontSize: '16px',
																			fontWeight: '500',
																			fontFamily: 'Hero New',
																		}}
																	>
																		Delivery
																	</p>
																</div>
															</div>
														</div>
													</div>
												}
											>
												{React.Children.toArray(
													expandedItems.includes(index) && (
														<div>
															{React.Children.toArray(
																order.orderItem.map((item) => {
																	const hasReviewed =
																		item?.shopItem?.itemReview?.find(
																			(review) =>
																				review.comUserId === order?.comUserId &&
																				review?.orderId === order?.id
																		);

																	return (
																		<div>
																			<hr
																				style={{
																					height: '2px',
																					backgroundColor: '#F2F2F2',
																					border: 'none',
																				}}
																			/>
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center', // Center items vertically
																					gap: '30px',
																					marginTop: '10px',
																				}}
																			>
																				<img
																					src={item.shopItem.reward.imageUrl}
																					alt=""
																					style={{
																						width: '20%',
																						objectFit: 'contain',
																					}}
																				/>
																				<div
																					onClick={() => {
																						if (item?.shopItem?.id) {
																							navigate(
																								'/rewards/items/' +
																									item?.shopItem?.id
																							);
																						}
																					}}
																				>
																					<p
																						className="fontSize-12"
																						style={{
																							fontWeight: '700',
																							color: '#1B1C1A',
																							fontFamily: 'Hero New',
																							fontSize: '16px',
																						}}
																					>
																						{item.shopItem.name}
																					</p>
																					<p
																						className="fontSize-12"
																						style={{
																							fontWeight: '400',
																							color: '#000000',
																							fontFamily: 'Hero New',
																							fontSize: '13px',
																						}}
																					>
																						{item.shopItem.reward.description}
																					</p>
																					<span
																						className="fontSize-12"
																						style={{
																							fontWeight: '300',
																							color: '#6B6B6B',
																							fontFamily: 'Hero New',
																							fontSize: '12px',
																							paddingTop: '5%',
																						}}
																					>
																						{t('Redeemed')}{' '}
																						{moment(item.createdAt).format(
																							'DD/MM/YYYY'
																						)}
																					</span>
																				</div>
																				{/* <div>{`x${item.qty}`}</div> */}
																			</div>
																			<div
																				style={{
																					display: 'flex',
																					justifyContent: 'left',
																					padding: '12px auto',
																					opacity:
																						order?.isShipped && !hasReviewed
																							? 1
																							: '0.5',
																				}}
																				onClick={() => {
																					if (
																						order?.isShipped &&
																						!hasReviewed
																					) {
																						setProductRating(true);
																						setReviewProduct({
																							...item.shopItem,
																							orderId: order.id,
																						});
																					}
																				}}
																			>
																				<Rate
																					value={hasReviewed?.rating || 5}
																					className="custom-yellow-border-rate"
																					character={() =>
																						!hasReviewed ? (
																							<StarOutlined />
																						) : (
																							<StarFilled />
																						)
																					}
																				/>
																			</div>
																		</div>
																	);
																})
															)}
															<div>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'left',
																		gap: '10px',
																	}}
																>
																	<img
																		src={deliveryIcon}
																		alt=""
																		style={{
																			width: 'auto',
																			objectFit: 'contain',
																		}}
																	/>
																	<p
																		className="fontSize-18"
																		style={{
																			fontWeight: 500,
																			fontFamily: 'Hero New',
																			fontSize: '18px',
																		}}
																	>
																		Delivery Address
																	</p>
																</div>
																<div>
																	<p
																		style={{
																			fontWeight: 500,
																			fontFamily: 'Hero New',
																			fontSize: '16px',
																			margin: '10px auto 5px auto',
																			textTransform: 'capitalize',
																		}}
																		className="fontSize-16"
																	>
																		{order.address.name || ''}
																	</p>
																	<p
																		style={{
																			fontWeight: '300',
																			fontFamily: 'Roboto',
																			fontSize: '16px',
																			color: '#000000',
																		}}
																	>
																		{order.address.phone
																			? order.address.phone
																			: null}
																	</p>
																	<p
																		className="fontSize-12"
																		style={{
																			fontWeight: '400',
																			color: '#4A4A4A',
																			fontFamily: 'Hero New',
																			fontSize: '12px',
																		}}
																	>
																		{`${order.address.line1 || ''}, ${
																			order.address.line2 || ''
																		}, ${order.address.postcode || ''} ${
																			order.address.city || ''
																		}`}
																	</p>
																</div>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'left',
																		gap: '10px',
																	}}
																	className="mt-2"
																>
																	<img
																		src={trackOrderIcon}
																		alt=""
																		style={{
																			width: 'auto',
																			objectFit: 'contain',
																		}}
																	/>
																	<p
																		className="fontSize-18"
																		style={{
																			fontWeight: 500,
																			fontFamily: 'Hero New',
																			fontSize: '18px',
																		}}
																	>
																		Track Your Order
																	</p>
																</div>
																<p
																	className="fontSize-14"
																	style={{
																		fontWeight: 500,
																		fontFamily: 'Hero New',
																		fontSize: '14px',
																		margin: '10px auto -5px auto',
																		textTransform: 'capitalize',
																	}}
																>
																	Delivery Status: {order.status}
																</p>
																<p
																	className="fontSize-14"
																	style={{
																		fontWeight: 500,
																		fontFamily: 'Hero New',
																		fontSize: '14px',
																		marginBottom: '5px',
																	}}
																>
																	Remark:{' '}
																	{Array.isArray(order?.remark)
																		? order.remark.map((remark) => remark)
																		: '-'}
																</p>
																<p
																	style={{
																		fontWeight: 400,
																		fontFamily: 'Hero New',
																		fontSize: '12px',
																		lineHeight: '16.79px',
																		color: '#000000',
																	}}
																>
																	Call our careline for delivery status
																	<br />
																	+60 11-5412 5382 <br />
																	Monday - Friday (Except Public Holiday)
																	<br />
																	9am - 5pm
																</p>
															</div>
														</div>
													)
												)}
											</Panel>
										);
									})}
							</Collapse>
						</div>

						<div className="mt-2">
							{voucher && voucher.length ? (
								<h2
									style={{
										fontSize: '20px',
										fontWeight: '500',
										marginBottom: '10px',
										fontFamily: 'Hero New',
									}}
								>
									{t('Digital Rewards')}
								</h2>
							) : null}
							{voucher && voucher.length
								? voucher.map((ele, index) => {
										return (
											<div key={index}>
												<div className="mt-2" key={index}>
													<div
														style={{
															padding: '1.5vh 0.5vw',
															boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
															borderRadius: '10px',
														}}
													>
														<div
															className="d-flex"
															style={{
																justifyContent: 'center',
															}}
														>
															<div className="my-reward-card">
																<div
																	className="d-flex"
																	style={{
																		alignItems: 'center',
																		justifyContent: 'space-evenly',
																	}}
																>
																	<img
																		style={{
																			width:
																				ele.rewardInstance.reward.name.includes(
																					'Capita Voucher'
																				)
																					? '90px'
																					: '50px',
																		}}
																		className="fit-contain"
																		src={
																			ele.rewardInstance.reward.name.includes(
																				'Capita Voucher'
																			)
																				? capitaVoucher
																				: ele.rewardInstance.reward.imageUrl
																		}
																		alt=""
																	/>
																	<div
																		style={{ width: '60%' }}
																		className="ml-2"
																	>
																		<h3
																			style={{
																				fontWeight: 'bold',
																				textAlign: 'left',
																				fontFamily: 'Hero New',
																				fontSize: '15px',
																			}}
																		>
																			{ele.rewardInstance.reward.name}{' '}
																		</h3>
																		<p
																			style={{
																				fontSize: '12px',
																				textAlign: 'left',
																				fontFamily: 'Hero New',
																			}}
																			className="t-black"
																		>
																			{ele.shopper === true
																				? `${ele.type} reload pin valued at RM${
																						ele.rewardInstance.reward.name || 0
																				  }.`
																				: `Gift voucher valued at ${
																						ele.rewardInstance.reward.name || 0
																				  }.`}
																		</p>
																	</div>
																</div>
																<div
																	className="d-flex"
																	style={{
																		justifyContent: 'center',
																		gap: '40px',
																	}}
																>
																	<div style={{ textAlign: 'left' }}>
																		<p
																			className="fontSize-12"
																			style={{
																				position: 'relative',
																				top: '30px',
																				color: '#6B6B6B',
																				fontFamily: 'Hero New',
																				fontWeight: '300',
																			}}
																		>
																			{t('Redeemed')}{' '}
																			{/* {formatToDateMonthYear(
																				new Date(ele.createdAt)
																			)} */}
																			{moment(ele.createdAt).format(
																				'DD/MM/YYYY'
																			)}
																			<br />
																		</p>
																		<p
																			className="fontSize-12"
																			style={{
																				position: 'relative',
																				top: '30px',
																				color: '#6B6B6B',
																				fontFamily: 'Hero New',
																				fontWeight: '400',
																			}}
																		>
																			Expiry:{' '}
																			{ele.rewardInstance.expiredAt
																				? moment(
																						ele.rewardInstance.expiredAt
																				  ).format('MMMM DD')
																				: '-'}
																		</p>
																		<p
																			className="fontSize-12"
																			style={{
																				position: 'relative',
																				top: '25px',
																				color: '#0D5FA8',
																				cursor: 'pointer',
																				textDecoration: 'underline',
																				marginTop: '5px',
																				fontFamily: 'Hero New',
																				fontWeight: '400',
																			}}
																			onClick={() => {
																				if (
																					ele.rewardInstance.reward.name.includes(
																						'Capita Voucher'
																					)
																				) {
																					displayHowToRedeem('Capita Voucher');
																				} else if (
																					ele.rewardInstance.reward.name
																						.toLowerCase()
																						.includes('guardian')
																				) {
																					displayHowToRedeem('Guardian');
																				} else if (
																					ele.rewardInstance.reward.name
																						.toLowerCase()
																						.includes('watsons')
																				) {
																					displayHowToRedeem('Watsons');
																				} else if (
																					ele.rewardInstance.reward.name
																						.toLowerCase()
																						.includes('fairprice')
																				) {
																					displayHowToRedeem('Fairprice');
																				} else if (
																					ele.rewardInstance.reward
																						.voucherType !== 'OTHERS'
																				) {
																					displayHowToRedeem(
																						ele.rewardInstance.reward
																							.voucherType
																					);
																				}
																			}}
																		>
																			{t('How to Redeem?')}
																		</p>
																	</div>
																	<div className="mt-3">
																		<div
																			onClick={() => {
																				if (
																					ele.rewardInstance.reward
																						.voucherType !== 'BARCODE'
																				) {
																					voucherClick(index, ele);
																				}
																				// else if (
																				// 	ele.rewardInstance.reward
																				// 		.voucherType === 'BARCODE' &&
																				// 	ele.rewardInstance.value &&
																				// 	!ele.rewardInstance.usedAt
																				// ) {
																				// 	setBarcodeName(
																				// 		ele.rewardInstance.reward.name
																				// 	);
																				// 	setBarcodeValue(
																				// 		ele.rewardInstance.value
																				// 	);
																				// 	setRedeem({ ele });
																				// 	// if (remainingTime !== 0) {
																				// 	// 	setRedeem({ ele });
																				// 	// 	setBarcodeValue(
																				// 	// 		ele.rewardInstance.value
																				// 	// 	);
																				// 	// 	setBarcodeName(
																				// 	// 		ele.rewardInstance.reward.name
																				// 	// 	);
																				// 	// } else {
																				// 	// 	setRedeem(null);
																				// 	// 	setShowBarcode(false);
																				// 	// }
																				// }
																			}}
																			className="px-1 py-1"
																			style={{
																				// backgroundColor: !ele.rewardInstance
																				// 	.usedAt
																				// 	? '#30E700'
																				// 	: '#D0D3D4',
																				// borderRadius: '5px',
																				// color: !ele.rewardInstance.usedAt
																				// 	? 'black'
																				// 	: '#7C878E',
																				backgroundColor: ele.rewardInstance
																					.usedAt
																					? '#D0D3D4'
																					: clickedVouchers.includes(index)
																					? '#D0D3D4'
																					: '#30E700',
																				borderRadius: '5px',
																				color: ele.rewardInstance.usedAt
																					? '#7C878E'
																					: 'black',
																				height: '30px',
																				textAlign: 'center',
																				verticalAlign: 'middle',
																				lineHeight: '30px',
																				width: '120px',
																				cursor: 'pointer',
																			}}
																		>
																			<div
																				style={{
																					display: 'flex',
																					justifyContent: 'center',
																					marginTop: '2%',
																				}}
																			>
																				{ele.rewardInstance.reward
																					.voucherType !== 'BARCODE' ? (
																					<CopyToClipboardLib
																						text={ele.rewardInstance.value}
																					>
																						<p
																							style={{
																								overflow: 'hidden',
																								textOverflow: 'ellipsis',
																								display: 'flex',
																								alignItems: 'center',
																								fontSize: '14px',
																								fontWeight: '600',
																								fontFamily: 'Hero New',
																							}}
																						>
																							{ele.rewardInstance.value}
																							<img
																								src={copyPng}
																								style={{
																									width: '20px',
																									objectFit: 'contain',
																									textAlign: 'center',
																									verticalAlign: 'middle',
																									lineHeight: '30px',
																								}}
																								className="ml-1"
																								alt=""
																							/>
																						</p>
																					</CopyToClipboardLib>
																				) : (
																					<p style={{ fontSize: '18px' }}>
																						{ele.rewardInstance.usedAt &&
																						ele.rewardInstance.reward
																							.voucherType === 'BARCODE'
																							? 'Redeemed'
																							: 'Redeem Now'}
																					</p>
																				)}
																			</div>
																		</div>
																		<p
																			style={{
																				fontSize: '12px',
																				color: '#929292',
																				cursor: 'pointer',
																				textDecoration: 'underline',
																				fontFamily: 'Hero New',
																			}}
																			onClick={() => {
																				if (
																					ele.rewardInstance.reward.name.includes(
																						'Capita Voucher'
																					)
																				) {
																					displayTNC('Capita Voucher');
																				} else if (
																					ele.rewardInstance.reward.name
																						.toLowerCase()
																						.includes('guardian')
																				) {
																					displayTNC('Guardian');
																				} else if (
																					ele.rewardInstance.reward.name
																						.toLowerCase()
																						.includes('watsons')
																				) {
																					displayTNC('Watsons');
																				} else if (
																					ele.rewardInstance.reward.name
																						.toLowerCase()
																						.includes('fairprice')
																				) {
																					displayTNC('Fairprice');
																				} else if (
																					ele.rewardInstance.reward
																						.voucherType !== 'OTHERS'
																				) {
																					displayTNC(
																						ele.rewardInstance.reward
																							.voucherType
																					);
																				}
																			}}
																		>
																			{t('Terms & Conditions')}
																		</p>
																	</div>
																</div>
															</div>
														</div>
														<HowToRedeemTNG
															redeemTNG={showingRedeemModal === 'TNG'}
															setRedeemTNG={() => setShowingRedeemModal(null)}
														/>
														<HowToRedeemGuardian
															open={showingRedeemModal === 'Guardian'}
															onClose={() => setShowingRedeemModal(null)}
														/>
														<HowToRedeemWatsons
															open={showingRedeemModal === 'Watsons'}
															onClose={() => setShowingRedeemModal(null)}
														/>
														<HowToRedeemFairprice
															open={showingRedeemModal === 'Fairprice'}
															onClose={() => setShowingRedeemModal(null)}
														/>
														<HowToRedeemGrab
															redeemGrab={showingRedeemModal === 'GRABPAY'}
															setRedeemGrab={() => setShowingRedeemModal(null)}
														/>
														<HowToRedeemSpay
															redeemSpay={showingRedeemModal === 'SPAY'}
															setRedeemSpay={() => setShowingRedeemModal(null)}
														/>
														<HowToRedeemCapitaVoucher
															redeemCapitaVoucher={
																showingRedeemModal === 'Capita Voucher'
															}
															setRedeemCapitaVoucher={() =>
																setShowingRedeemModal(null)
															}
														/>
														<TermsSpay
															termsSpay={showingTNCModal === 'SPAY'}
															setTermsSpay={() => setShowingTNCModal(null)}
														/>
														<TermsTNG
															termsTNG={showingTNCModal === 'TNG'}
															setTermsTNG={() => setShowingTNCModal(null)}
														/>
														<TermsGrab
															termsGrab={showingTNCModal === 'GRABPAY'}
															setTermsGrab={() => setShowingTNCModal(null)}
														/>
														<TermsCapitaVoucher
															termsCapitaVoucher={
																showingTNCModal === 'Capita Voucher'
															}
															setTermsCapitaVoucher={() =>
																setShowingTNCModal(null)
															}
														/>
														<TermsGuardian
															open={showingTNCModal === 'Guardian'}
															onClose={() => setShowingTNCModal(null)}
														/>
														<TermsWatsons
															open={showingTNCModal === 'Watsons'}
															onClose={() => setShowingTNCModal(null)}
														/>
														<TermsFairprice
															open={showingTNCModal === 'Fairprice'}
															onClose={() => setShowingTNCModal(null)}
														/>
													</div>
												</div>
											</div>
										);
										// }
								  })
								: null}
						</div>
					</div>
				</div>
			</div>

			<UserFooter type={'rewardsPage'} />

			<div>
				<Modal
					open={productRating}
					centered
					footer={null}
					width={300}
					onCancel={() => {
						setProductRating(false);
					}}
				>
					<div className="text-center">
						<div className="mt-1">
							<h3
								style={{
									fontSize: '24px',
									width: 'auto',
									fontWeight: '500',
									marginTop: '20px',
								}}
							>
								Rate This Reward
							</h3>
						</div>
						<div>
							<Rate value={rating} onChange={handleRatingChange} required />
						</div>
						<div>
							<Input
								style={{
									width: '80%',
									height: '45px',
									marginTop: '10px',
								}}
								placeholder="Write a review!"
								onChange={handleUserReview}
							/>
						</div>
						<p style={{ color: 'red', fontSize: '10px', textAlign: 'center' }}>
							{errorMsg}
						</p>
						{successMsg && (
							<p
								style={{
									color: 'green',
									fontSize: '10px',
									textAlign: 'center',
								}}
							>
								Success!
							</p>
						)}

						<Button
							type="primary"
							className="mt-2"
							onClick={() => handleReviewRating(reviewProduct)}
							style={{
								fontSize: '12px',
								borderRadius: '8px',
								background: '#30E700',
								color: 'black',
								border: 'none',
								fontWeight: '500',
								height: '40px',
								width: '100%',
							}}
						>
							Submit
						</Button>
					</div>
				</Modal>

				<Modal
					open={redeem}
					centered
					footer={null}
					width={350}
					closable={false}
				>
					<div className="text-center">
						<div className="mt-1">
							<img
								src={confirmationIcon}
								alt="confirmation_icon"
								style={{ width: '20%', objectFit: 'contain' }}
							/>
							<h3
								style={{
									fontSize: '24px',
									width: 'auto',
									fontWeight: '700',
								}}
							>
								Confirmation
							</h3>
						</div>
						<div>
							<p className="mt-1 text-center fontSize-14">
								This is a one time voucher redemption code. You need to redeem
								within
							</p>
							<p
								className="mt-1 fontSize-16 text-center"
								style={{ fontWeight: '700' }}
							>
								5 Minutes
							</p>
							<p className="text-center mt-2">Note:</p>
							<p className="text-center fontSize-14">
								Show this code to the cashier when
								<br />
								you want to redeem the e-voucher.
							</p>
						</div>

						<Button
							type="primary"
							className="mt-2"
							style={{
								fontSize: '16px',
								borderRadius: '5px',
								background: '#30E700',
								color: 'black',
								border: 'none',
								fontWeight: '500',
								height: '40px',
								width: '100%',
							}}
							onClick={() =>
								// async () => {
								// const res = await axiosClient.post('/barcode/validate', {});
								// if (res) {
								// 	setShowBarcode(true);
								// 	setRedeem(null);
								// 	setStartCountdown(true);
								// }
								{
									// setRedeem(null);
									setShowBarcode(true);
									setStartCountdown(true);
								}
							}
						>
							Use now
						</Button>

						<Button
							type="primary"
							className="mt-1"
							style={{
								fontSize: '16px',
								borderRadius: '5px',
								background: 'white',
								color: 'black',
								fontWeight: '500',
								height: '40px',
								width: '100%',
								border: '1px solid black',
							}}
							onClick={() => setRedeem(null)}
						>
							Use later
						</Button>
					</div>
				</Modal>
				<BarcodeTimer
					startNow={showBarcode}
					setShowBarcode={setShowBarcode}
					startCountdown={startCountdown}
				/>

				 
			</div>
		</div>
	);
};

export default Rewards;
