import React, { useState, useRef, useEffect } from 'react';
import { Button, Progress, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkUserSession, logoutUser } from '../../reducer/userReducer';
import { editUserDetails } from '../../reducer/userDetailsReducer';
import './profile.css';
import icon from '../../images/Haleon/user.png';
import backArrow from '../../images/Haleon/blackArrow.svg';
import UserFooter from '../../components/UserFooter';
import MainHeader from '../../components/MainHeader';
import silverMedal from '../../images/Haleon/silverMedal1.png';
import bronzeMedal from '../../images/Haleon/bronzeMedal.png';
import platinumMedal from '../../images/Haleon/platinum.png';
import goldMedal from '../../images/Haleon/goldMedal1.png';
import axiosClient from '../../utils/axiosClient';

const ProfilePage = ({ t }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userToken = localStorage.getItem('HALEON-REWARDS_USER_TOKEN');
	const [userData, setUserData] = useState();
	const [error, setError] = useState();
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');

	useEffect(() => {
		if (!userToken) {
			navigate('/welcome' + window.location.search);
		}
	}, [userToken]);

	useEffect(() => {
		if (!userToken) return;

		const check = async () => {
			try {
				const res = await axiosClient.get('/users/details');
				setUserData(res.data.data.personalInfo);
				// setUploadHistory(res.data.data.)
			} catch (error) {
				// navigate(`/login?store=${store}`);
			}
		};
		check();
	}, [userToken]);

	const logOut = () => {
		dispatch(logoutUser());
		localStorage.removeItem('HALEON-REWARDS_USER_TOKEN');
		navigate('/login');
	};

	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;

	return (
		<div>
			<div>
				<MainHeader />

				<div className="mt-3 ml-2 mr-2">
					<div className="user-container">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div className="ml-2 mt-2">
								<h2 style={{ fontSize: '25px' }}>{userData?.name}</h2>
								<img
									src={icon}
									alt=""
									style={{ width: 'auto', objectFit: 'contain' }}
								/>
								<span className="ml-1" style={{ fontSize: '16px' }}>
									****{userData?.phone?.slice(3, 7) || '***'}
								</span>
							</div>
							<div className="mr-2 mt-2">
								<h3
									style={{
										fontWeight: '400',
										fontSize: getLanguage === 'bm' ? '15px' : 'normal',
									}}
									className="text-center"
								>
									{t('Points')}
								</h3>
								<h1
									style={{
										fontSize: '33px',
										fontWeight: '800',
										textAlign: 'right',
									}}
								>
									{' '}
									{userData?.totalUnitsBalance || 0}
								</h1>
							</div>
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div
								className="ml-2 mt-2"
								style={{
									width: '30%',
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}
							>
								<div>
									<img
										src={
											userData?.tier?.name === 'Bronze'
												? bronzeMedal
												: userData?.tier?.name === 'Silver'
												? silverMedal
												: userData?.tier?.name === 'Gold'
												? goldMedal
												: userData?.tier?.name === 'Platinum'
												? platinumMedal
												: bronzeMedal
										}
										alt=""
										style={{ width: '16%' }}
									/>
									{isSGRegion ? (
										<span
											className=""
											style={{ fontWeight: '800', fontSize: '16px' }}
										>
											{' '}
											{userData?.tier?.name || 'Bronze'}
										</span>
									) : (
										<span
											className=""
											style={{ fontWeight: '800', fontSize: '14px' }}
										>
											{' '}
											{userData?.tier?.name === 'Platinum'
												? t('userPlatinum')
												: userData?.tier?.name === 'Gold'
												? t('userGold')
												: userData?.tier?.name === 'Silver'
												? t('userSilver')
												: t('userBronze') || t('userBronze')}
										</span>
									)}
								</div>
							</div>
							<div className="" style={{ width: '70%' }}>
								<div style={{ position: 'relative', right: '20px' }}>
									<Progress
										percent={
											userData?.tier?.name === 'Bronze'
												? (userData?.totalUnitsEarnedLastCycle / 250) * 100
												: userData?.tier?.name === 'Silver'
												? (userData?.totalUnitsEarnedLastCycle / 600) * 100
												: userData?.tier?.name === 'Gold'
												? (userData?.totalUnitsEarnedLastCycle / 1850) * 100
												: userData?.tier?.name === 'Platinum'
												? // ? ((userData?.totalUnitsEarnedLastCycle - 1850) /
												  // 		1850) *
												  //   100
												  (userData?.totalUnitsEarnedLastCycle / 1) * 100
												: 0 / 250
										}
										strokeColor={'#30E700'}
										showInfo={false}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'right',
										marginRight: '30px',
									}}
								>
									<p style={{ fontWeight: '800' }} className="fontSize-16">
										{userData?.tier?.name === 'Bronze'
											? `${userData?.totalUnitsEarnedLastCycle || 0}/250`
											: userData?.tier?.name === 'Silver'
											? `${userData?.totalUnitsEarnedLastCycle || 0}/600`
											: userData?.tier?.name === 'Gold'
											? `${userData?.totalUnitsEarnedLastCycle || 0}/1000`
											: userData?.tier?.name === 'Platinum'
											? userData?.totalUnitsEarnedLastCycle
											: `0/250`}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-2">
						<div>
							<Space direction="vertical" style={{ width: '100%' }} size={10}>
								<div
									className="redirection-box"
									onClick={() => navigate('/personal/details')}
								>
									<div className="t-black profile-title-font">
										{t('Personal Details')}
									</div>
									<img src={backArrow} alt="backArrow" className="arrow-pos" />
								</div>

								<div
									className="redirection-box"
									onClick={() => navigate('/address')}
								>
									<div className="t-black profile-title-font">
										{t('Delivery Address')}
									</div>
									<img src={backArrow} alt="backArrow" className="arrow-pos" />
								</div>

								{!isSGRegion && (
									<div
										className="redirection-box"
										onClick={() => navigate('/language')}
									>
										<div className="t-black profile-title-font">
											{t('Language')}{' '}
										</div>
										<img
											src={backArrow}
											alt="backArrow"
											className="arrow-pos"
										/>
									</div>
								)}

								<div
									className="redirection-box"
									onClick={() => navigate('/referFriend')}
								>
									<div className="t-black profile-title-font">
										{t('Refer A Friend')}{' '}
									</div>
									<img src={backArrow} alt="backArrow" className="arrow-pos" />
								</div>

								<div
									className="redirection-box"
									onClick={() => navigate('/tiering')}
								>
									<div className="t-black profile-title-font">
										{t('Tiering')}{' '}
									</div>
									<img src={backArrow} alt="backArrow" className="arrow-pos" />
								</div>

								<div
									className="redirection-box"
									onClick={() => {
										navigate('/badges');
									}}
								>
									<div className="t-black profile-title-font">
										{t('Badges')}{' '}
									</div>
									<img src={backArrow} alt="backArrow" className="arrow-pos" />
								</div>

								{!isSGRegion && (
									<div
										className="redirection-box"
										onClick={() => navigate('/contact-preference')}
									>
										<div className="t-black profile-title-font">
											{' '}
											{t('Contact Preference')}{' '}
										</div>
										<img
											src={backArrow}
											alt="backArrow"
											className="arrow-pos"
										/>
									</div>
								)}

								<div
									className="redirection-box"
									onClick={() => navigate('/help')}
								>
									<div className="t-black profile-title-font">
										{' '}
										{t('Help')}{' '}
									</div>
									<img src={backArrow} alt="backArrow" className="arrow-pos" />
								</div>

								<div style={{ marginTop: '10px', marginBottom: '120px' }}>
									<Button className="logout-btn" onClick={logOut}>
										{t('Log Out')}
									</Button>
								</div>
							</Space>
						</div>
					</div>
				</div>
				<UserFooter type={'profilePage'} />
			</div>
		</div>
	);
};

export default ProfilePage;
