import React from 'react';
import { Modal } from 'antd';
import rejected from "../../images/Haleon/rejected.png";

const InvalidQrCode = ({ errorModal, setErrorModal }) => {
	return (
		<div>
			<Modal
				open={errorModal}
				centered
				footer={null}
				closable={false}
				width={300}
			>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<img src={rejected} alt="" style={{ width: '20%' }}></img>
				</div>
				<br />
				<div
					style={{
						fontWeight: 'bold',
						fontSize: '20px',
						textAlign: 'center',
					}}
				>
					Invalid QR Code, please rescan a valid QR again
				</div>
			</Modal>
		</div>
	);
};

export default InvalidQrCode;
