import "./index.css";

import { Button, Form, Input, Modal, Select, Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  clearError,
  createArticle,
  createCategory,
  getArticle,
  getItems,
  updateArticle,
} from "../../../reducer/adminReducer";
import { useDispatch, useSelector } from "react-redux";

import TextArea from "antd/es/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { adminTokenConfig } from "../../../reducer/helperFunc";

const { Option } = Select;

const ArticlesManagement = () => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({
    authorName: "",
    authorNameCH: "",
    title: "",
    titleBM: "",
    titleCH: "",
    description: "",
    descriptionBM: "",
    descriptionCH: "",
    articleCategory: "",
  });
  const [editData, setEditData] = useState({});
  const [newModal, setNewModal] = useState(false);
  const [categoryName, setCategoryName] = useState({
    name: "",
    chName: "",
    bmName: "",
  });

  const uploadImage = useRef();
  const uploadImageBM = useRef();
  const uploadImageCH = useRef();
  const [viewModal, setViewModal] = useState();
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [imageBM, setImageBM] = useState("");
  const [imageNameBM, setImageNameBM] = useState("");
  const [imageCH, setImageCH] = useState("");
  const [imageNameCH, setImageNameCH] = useState("");
  const [submit, setSubmit] = useState(false);
  const [imageName, setImageName] = useState("");
  const { message, error, dataList, articleCategories } = useSelector(
    (state) => state.admin
  );
  const [categoryModal, setCategoryModal] = useState(false);
  const MAX_IMAGE_SIZE = 500 * 500;

  useEffect(() => {
    dispatch(getArticle());
    // eslint-disable-next-line
  }, []);

  const openView = (rowId) => {
    let rowData = dataList.filter((i) => i.id === rowId)[0];
    if (rowData) {
      setEditData(rowData);
      setViewModal(true);
    }
  };

  const addNew = () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("file2", imageBM);
    formData.append("file3", imageCH);
    formData.append("sendData", JSON.stringify(newData));
    const config = adminTokenConfig(true);
    dispatch(createArticle(formData, config));
    setSubmit(true);
  };

  const editDetails = () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("file2", imageBM);
    formData.append("file3", imageCH);
    formData.append("sendData", JSON.stringify(editData));
    dispatch(updateArticle(formData));
    setSubmit(true);
  };

  // const handleImage = (e) => {
  //     let inputFile = false
  //     const tempFile = e.target.files[0]
  //     if (tempFile) {
  //         inputFile = true
  //     }
  //     if (inputFile) {
  //         if (tempFile.type === 'image/png' || tempFile.type === 'image/jpg' || tempFile.type === 'image/jpeg') {
  //             setImage(tempFile)
  //             setImageName(tempFile.name)
  //         }
  //         else {
  //             errorInfo()
  //         }
  //     }
  // };

  const handleImage = (e) => {
    let inputFile = false;
    const tempFile = e.target.files[0];
    if (tempFile) {
      inputFile = true;
    }
    if (inputFile) {
      if (
        tempFile.type === "image/png" ||
        tempFile.type === "image/jpg" ||
        tempFile.type === "image/jpeg"
      ) {
        if (tempFile.size <= MAX_IMAGE_SIZE) {
          // console.log(tempFile.size);
          setImage(tempFile);
          setImageName(tempFile.name);
        } else {
          errorInfo("Image size exceeds the maximum allowed size (1MB).");
        }
      } else {
        errorInfo("Please upload image files only (png, jpg, jpeg).");
      }
    }
  };

  const handleImageBM = (e) => {
    let inputFile = false;
    const tempFile = e.target.files[0];
    if (tempFile) {
      inputFile = true;
    }
    if (inputFile) {
      if (
        tempFile.type === "image/png" ||
        tempFile.type === "image/jpg" ||
        tempFile.type === "image/jpeg"
      ) {
        if (tempFile.size <= MAX_IMAGE_SIZE) {
          // console.log(tempFile.size);
          setImageBM(tempFile);
          setImageNameBM(tempFile.name);
        } else {
          errorInfo("Image size exceeds the maximum allowed size (1MB).");
        }
      } else {
        errorInfo("Please upload image files only (png, jpg, jpeg).");
      }
    }
  };

  const handleImageCH = (e) => {
    let inputFile = false;
    const tempFile = e.target.files[0];
    if (tempFile) {
      inputFile = true;
    }
    if (inputFile) {
      if (
        tempFile.type === "image/png" ||
        tempFile.type === "image/jpg" ||
        tempFile.type === "image/jpeg"
      ) {
        if (tempFile.size <= MAX_IMAGE_SIZE) {
          // console.log(tempFile.size);
          setImageCH(tempFile);
          setImageNameCH(tempFile.name);
        } else {
          errorInfo("Image size exceeds the maximum allowed size (1MB).");
        }
      } else {
        errorInfo("Please upload image files only (png, jpg, jpeg).");
      }
    }
  };

  const errorInfo = () => {
    Modal.info({
      title: "Upload Failed.",
      content: (
        <div>
          <p>Please Upload Image Files only.</p>
        </div>
      ),
      onOk() {},
    });
  };

  const clearAll = () => {
    setNewData({
      authorName: "",
      authorNameCH: "",
      title: "",
      titleBM: "",
      titleCH: "",
      description: "",
      descriptionBM: "",
      descriptionCH: "",
      articleCategory: "",
    });
    setNewModal(false);
    setViewModal(false);
    setCategoryName({ name: "", chName: "", bmName: "" });
    setSubmit(false);
    dispatch(clearError());
    form.resetFields();
  };

  const addCategory = () => {
    if (categoryName) {
      dispatch(createCategory(categoryName));
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };

  useEffect(() => {
    if (message && !error && submit) {
      clearAll();
    } else {
      setSubmit(false);
    } // eslint-disable-next-line
  }, [message, error]);

  const columns = [
    {
      title: "Author Name ENG/BM",
      dataIndex: "authorName",
      key: "authorName",
      align: "center",
    },
    {
      title: "Author Name CH",
      dataIndex: "authorNameCH",
      key: "authorNameCH",
      align: "center",
    },
    {
      title: "Article Category",
      dataIndex: "articleCategory",
      key: "articleCategory",
      align: "center",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: "Title BM",
      dataIndex: "titleBM",
      key: "titleBM",
      align: "center",
    },
    {
      title: "Title CH",
      dataIndex: "titleCH",
      key: "titleCH",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "Description BM",
      dataIndex: "descriptionBM",
      key: "descriptionBM",
      align: "center",
    },
    {
      title: "Description CH",
      dataIndex: "descriptionCH",
      key: "descriptionCH",
      align: "center",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",

      render: (i) => {
        return <img src={i} alt="" style={{ width: "5vw" }} />;
      },
    },
    {
      title: "Image BM",
      dataIndex: "imageBM",
      key: "imageBM",
      align: "center",

      render: (i) => {
        return <img src={i} alt="" style={{ width: "5vw" }} />;
      },
    },
    {
      title: "Image CH",
      dataIndex: "imageCH",
      key: "imageCH",
      align: "center",

      render: (i) => {
        return <img src={i} alt="" style={{ width: "5vw" }} />;
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",

      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",

      render: (id) => {
        return (
          <div key={id}>
            <Tag onClick={() => openView(id)} color="blue">
              Update
            </Tag>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <br />
      <div style={{ color: "black", fontSize: "35px", textAlign: "center" }}>
        Article Management
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: '20px',
        }}
      >
        <Button
          style={{ width: "200px", background: "lightblue", marginTop: "2%" }}
          onClick={() => setNewModal(true)}
        >
          Add New Article
        </Button>
        <Button
          style={{
            width: "200px",
            background: "white",
            marginTop: "2%",
            marginLeft: "2%",
          }}
          onClick={() => clearAll()}
        >
          Refresh
        </Button>
      </div>
      <div className="table-container">
        <Table
          className="management-table"
          columns={columns}
          dataSource={dataList}
        />
      </div>
      <Modal
        title={<div style={{ fontSize: "25px" }}>Create New Article</div>}
        open={newModal}
        onOk={() => addNew()}
        onCancel={() => {
          clearAll();
          setNewModal(false);
          setCategoryModal(false);
        }}
      >
        <Form form={form} name="create_codebatch">
          <div style={{ textAlign: "right", marginTop: "5%" }}>
            <Form.Item label="Category">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.articleCategory}
                onChange={(e) =>
                  setNewData({ ...newData, articleCategory: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Author Name ENG/BM">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.authorName}
                onChange={(e) =>
                  setNewData({ ...newData, authorName: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Author Name CH">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.authorNameCH}
                onChange={(e) =>
                  setNewData({ ...newData, authorNameCH: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Title">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.title}
                onChange={(e) =>
                  setNewData({ ...newData, title: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Title BM">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.titleBM}
                onChange={(e) =>
                  setNewData({ ...newData, titleBM: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Title CH">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.titleCH}
                onChange={(e) =>
                  setNewData({ ...newData, titleCH: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Description">
              <TextArea
                required
                style={{ width: "300px", height: "200px" }}
                value={newData.description}
                onChange={(e) =>
                  setNewData({ ...newData, description: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Description BM">
              <TextArea
                required
                style={{ width: "300px", height: "200px" }}
                value={newData.descriptionBM}
                onChange={(e) =>
                  setNewData({ ...newData, descriptionBM: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Description CH">
              <TextArea
                required
                style={{ width: "300px", height: "200px" }}
                value={newData.descriptionCH}
                onChange={(e) =>
                  setNewData({ ...newData, descriptionCH: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Image">
              <div
                onClick={() => uploadImage.current.click()}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "left",
                  marginRight: "10%",
                }}
              >
                <input
                  onChange={handleImage}
                  type="file"
                  ref={uploadImage}
                  hidden
                  style={{ opacity: "0" }}
                />
                {imageName ? (
                  <>
                    <h4>{imageName} </h4>
                  </>
                ) : (
                  <Button
                    style={{ border: "none", marginRight: "5%" }}
                    icon={<UploadOutlined />}
                    type="submit"
                  >
                    Upload Image
                  </Button>
                )}
              </div>
            </Form.Item>

            <Form.Item label="Image BM">
              <div
                onClick={() => uploadImageBM.current.click()}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "left",
                  marginRight: "15%",
                }}
              >
                <input
                  onChange={handleImageBM}
                  type="file"
                  ref={uploadImageBM}
                  hidden
                  style={{ opacity: "0" }}
                />
                {imageNameBM ? (
                  <>
                    <h4>{imageNameBM} </h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>

            <Form.Item label="Image CH">
              <div
                onClick={() => uploadImageCH.current.click()}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "left",
                  marginRight: "15%",
                }}
              >
                <input
                  onChange={handleImageCH}
                  type="file"
                  ref={uploadImageCH}
                  hidden
                  style={{ opacity: "0" }}
                />
                {imageNameCH ? (
                  <>
                    <h4>{imageNameCH} </h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
          </div>
          <div>
            {message && submit ? (
              <div className="success-modal-msg"> {message}</div>
            ) : (
              <div className="error-modal-msg">{error}</div>
            )}
          </div>
        </Form>
      </Modal>

      <Modal
        title={<div style={{ fontSize: "25px" }}>Update Articles</div>}
        open={viewModal}
        closable={true}
        onOk={() => editDetails()}
        onCancel={() => {
          clearAll();
          setViewModal(false);
        }}
      >
        <Form form={form} name="create_codebatch">
          <div style={{ textAlign: "right", marginTop: "5%" }}>
            <Form.Item label="Category">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.articleCategory}
                onChange={(e) =>
                  setEditData({ ...editData, articleCategory: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Author Name ENG/BM">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.authorName}
                onChange={(e) =>
                  setEditData({ ...editData, authorName: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Author Name CH">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.authorNameCH}
                onChange={(e) =>
                  setEditData({ ...editData, authorNameCH: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Title">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.title}
                onChange={(e) =>
                  setEditData({ ...editData, title: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Title BM">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.titleBM}
                onChange={(e) =>
                  setEditData({ ...editData, titleBM: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Title CH">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.titleCH}
                onChange={(e) =>
                  setEditData({ ...editData, titleCH: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Description">
              <TextArea
                required
                style={{ width: "300px" }}
                value={editData.description}
                onChange={(e) =>
                  setEditData({ ...editData, description: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Description BM">
              <TextArea
                required
                style={{ width: "300px" }}
                value={editData.descriptionBM}
                onChange={(e) =>
                  setEditData({ ...editData, descriptionBM: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Description CH">
              <TextArea
                required
                style={{ width: "300px" }}
                value={editData.descriptionCH}
                onChange={(e) =>
                  setEditData({ ...editData, descriptionCH: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Image">
              <div
                onClick={() => uploadImage.current.click()}
                style={{ textAlign: "center", marginLeft: "25%" }}
              >
                <input
                  onChange={handleImage}
                  type="file"
                  ref={uploadImage}
                  hidden
                  style={{ opacity: "0" }}
                />
                {image ? (
                  <>
                    <h4>{imageName}</h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload New Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item label="Image BM">
              <div
                onClick={() => uploadImageBM.current.click()}
                style={{ textAlign: "center", marginLeft: "25%" }}
              >
                <input
                  onChange={handleImageBM}
                  type="file"
                  ref={uploadImageBM}
                  hidden
                  style={{ opacity: "0" }}
                />
                {imageBM ? (
                  <>
                    <h4>{imageNameBM}</h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload New Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item label="Image CH">
              <div
                onClick={() => uploadImageCH.current.click()}
                style={{ textAlign: "center", marginLeft: "25%" }}
              >
                <input
                  onChange={handleImageCH}
                  type="file"
                  ref={uploadImageCH}
                  hidden
                  style={{ opacity: "0" }}
                />
                {imageCH ? (
                  <>
                    <h4>{imageNameCH}</h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload New Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
          </div>

          <div>
            {message && submit ? (
              <div className="success-modal-msg"> {message}</div>
            ) : (
              <div className="error-modal-msg">{error}</div>
            )}
          </div>
        </Form>
      </Modal>

      {/* <Modal
                title={<div style={{ fontSize: '25px' }}>Create New Article Category</div>}
                open={categoryModal}
                onOk={() => addCategory()}
                onCancel={() => {
                    clearAll();
                    setCategoryModal(false);
                }}
            >
                <Form
                    form={form}
                    name="create_category"
                    onFinish={addCategory}
                >
                    <div style={{ textAlign: 'right', marginTop: '5%' }}>
                        <Form.Item label="Name (ENG)">
                            <Input
                                required
                                style={{ width: '300px' }}
                                value={categoryName.name}
                                onChange={(e) => setCategoryName({...categoryName, name: e.target.value })}
                            />
                        </Form.Item>

                        <Form.Item label="Name (BM)">
                            <Input
                                required
                                style={{ width: '300px' }}
                                value={categoryName.bmName}
                                onChange={(e) => setCategoryName({...categoryName, bmName: e.target.value })}
                            />
                        </Form.Item>

                        <Form.Item label="Name (CH)">
                            <Input
                                required
                                style={{ width: '300px' }}
                                value={categoryName.chName}
                                onChange={(e) => setCategoryName({...categoryName, chName: e.target.value })}
                            />
                        </Form.Item>

                    </div>
                    <div>
                        {message && submit ? (
                            <div className="success-modal-msg"> {message}</div>
                        ) : (
                            <div className="error-modal-msg">{error}</div>
                        )}
                    </div>
                </Form>
            </Modal> */}
    </div>
  );
};

export default ArticlesManagement;
