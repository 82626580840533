import '../../css/Loading.css';

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import HaleonLogo from '../../images/Haleon/Frame.svg';
import { Modal } from 'antd';
import axiosClient from '../../utils/axiosClient';
// import axios from 'axios';
import rejected from '../../images/Haleon/rejected.png';
// import { checkQr } from '../../reducer/userReducer';
// import { useDispatch } from 'react-redux';
import { useLocalStorage } from '@uidotdev/usehooks';

const Loading = () => {
	// const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [errorModal, setErrorModal] = useState(false);
	const _storeId = searchParams.get('store');
	const _referId = searchParams.get('referral');

	const [storeId, setStoreId] = useLocalStorage('storeId', _storeId);
	const [referCode, setReferCode] = useLocalStorage('referCode', _referId);
	const [syncedQRScan, setSyncedQRScan] = useLocalStorage('syncedQRScan');

	// http://localhost:3000/loading?store=08ccce5d-4579-4061-af43-3bc52f6af75a&referral=ABC123
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const checkId = async () => {
		if (_storeId) {
			setStoreId(_storeId);
		}

		if (_referId) {
			// localStorage.setItem('referCode', _referId);
			setReferCode(_referId);
		}

		// const syncedQRScan = localStorage.getItem('syncedQRScan');
		if (!syncedQRScan) {
			try {
				// TODO: support checkQr api
				const res = await axiosClient.post('/api/user/checkqr', {
					id: _storeId,
					referId: _referId,
				});

				if (res.ok) {
					setSyncedQRScan(_storeId);
				} else {
					window.alert('Invalid QR Code, please rescan a validate QR again');
				}
			} catch (error) {
				console.error(error);
			}
		}

		navigate('/welcome', {
			state: { referId: _referId, storeId: _storeId },
		});
	};

	useEffect(() => {
		if (_storeId || _referId) {
			checkId();
		} else {
			navigate('/welcome');
		}
	}, [_storeId, _referId]);

	return (
		<div className="img-position">
			<div className="loading-home">
				<img src={HaleonLogo} alt="text" className="full-width" />
			</div>
			<div>
				<Modal
					open={errorModal}
					centered
					footer={null}
					closable={false}
					width={300}
				>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={rejected} alt="" style={{ width: '20%' }}></img>
					</div>
					<br />
					<div
						style={{
							fontWeight: 'bold',
							fontSize: '20px',
							textAlign: 'center',
						}}
					>
						Invalid QR Code, please rescan a validate QR again
					</div>
				</Modal>
			</div>
		</div>
	);
};

export default Loading;
