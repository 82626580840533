import "./index.css";

import { Button, Divider, Form, Input, Modal, Select, Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  clearError,
  createCategory,
  createItems,
  getItems,
  updateItems,
} from "../../../reducer/adminReducer";
import { useDispatch, useSelector } from "react-redux";

import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { adminTokenConfig } from "../../../reducer/helperFunc";

const { TextArea } = Input;
const { Option } = Select;
function PointShopManagement() {
  const [newModal, setNewModal] = useState();
  const [form] = Form.useForm();
  const uploadImage = useRef();
  const uploadImageBM = useRef();
  const uploadImageCH = useRef();
  const [newData, setNewData] = useState({
    productId: "",
    itemName: "",
    uom: "",
    description: "",
    points: "",
    totalBalance: "",
    active: true,
    bmName: "",
    bmDescription: "",
    chName: "",
    chDescription: "",
    pickup_optional: "",
    filter_by: "",
  });
  const [editData, setEditData] = useState({
    productId: "",
    itemName: "",
    uom: "",
    description: "",
    points: "",
    totalBalance: "",
    active: true,
    bmName: "",
    bmDescription: "",
    chName: "",
    chDescription: "",
    pickup_optional: "",
    filter_by: "",
  });
  const [categoryName, setCategoryName] = useState({
    name: "",
    chName: "",
    bmName: "",
  });
  const [categoryModal, setCategoryModal] = useState(false);

  const [editModal, setEditModal] = useState();
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageBM, setImageBM] = useState("");
  const [imageNameBM, setImageNameBM] = useState("");
  const [imageCH, setImageCH] = useState("");
  const [imageNameCH, setImageNameCH] = useState("");
  const [submit, setSubmit] = useState(false);
  const { itemList, categoryList, message, error } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    dispatch(getItems());
    dispatch(clearError());
    // eslint-disable-next-line
  }, []);

  const editDetails = () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("file2", imageBM);
    formData.append("file3", imageCH);
    formData.append("sendData", JSON.stringify(editData));
    dispatch(updateItems(formData));
    // console.log("formData",formData);
    setSubmit(true);
  };

  const addItem = () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("file2", imageBM);
    formData.append("file3", imageCH);
    formData.append("sendData", JSON.stringify(newData));
    const config = adminTokenConfig(true);
    dispatch(createItems(formData, config));
    setSubmit(true);
  };


  const openEdit = (rowId) => {
    let rowData = itemList.filter((i) => i.id === rowId)[0];
    if (rowData) {
      // setImage(rowData.image)
      // setImageBM(rowData.imageBM)
      setEditData(rowData);
      setEditModal(true);
    }
  };

  const addCategory = () => {
    dispatch(createCategory(categoryName));
    setSubmit(true);
  };

  const clearAll = () => {
    setNewData({
      productId: "",
      itemName: "",
      uom: "",
      description: "",
      points: "",
      totalBalance: "",
      active: true,
      bmName: "",
      bmDescription: "",
      chName: "",
      chDescription: "",
      category: "",
      pickup_optional: "",
    });
    setEditData({
      productId: "",
      itemName: "",
      uom: "",
      description: "",
      points: "",
      totalBalance: "",
      active: true,
      bmName: "",
      bmDescription: "",
      chName: "",
      chDescription: "",
      category: "",
      pickup_optional: "",
    });
    setImageName("");
    setImage("");
    setImageNameBM("");
    setImageBM("");
    setCategoryName("");
    setCategoryModal(false);
    setNewModal(false);
    setEditModal(false);
    setSubmit(false);
    dispatch(getItems());
    dispatch(clearError());
    form.resetFields();
  };

  useEffect(() => {
    if (message && !error) {
      clearAll();
      setSubmit(false);
    } else {
      setSubmit(false);
    } // eslint-disable-next-line
  }, [message, error]);

  const errorInfo = () => {
    Modal.info({
      title: "Upload Failed.",
      content: (
        <div>
          <p>Please Upload Image Files only.</p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleImage = (e) => {
    let inputFile = false;
    const tempFile = e.target.files[0];
    if (tempFile) {
      inputFile = true;
    }
    if (inputFile) {
      if (
        tempFile.type === "image/png" ||
        tempFile.type === "image/jpg" ||
        tempFile.type === "image/jpeg"
      ) {
        setImage(tempFile);
        setImageName(tempFile.name);
      } else {
        errorInfo();
      }
    }
  };

  const handleImageBM = (e) => {
    let inputFile = false;
    const tempFile = e.target.files[0];
    if (tempFile) {
      inputFile = true;
    }
    if (inputFile) {
      if (
        tempFile.type === "image/png" ||
        tempFile.type === "image/jpg" ||
        tempFile.type === "image/jpeg"
      ) {
        setImageBM(tempFile);
        setImageNameBM(tempFile.name);
      } else {
        errorInfo();
      }
    }
  };

  const handleImageCH = (e) => {
    let inputFile = false;
    const tempFile = e.target.files[0];
    if (tempFile) {
      inputFile = true;
    }
    if (inputFile) {
      if (
        tempFile.type === "image/png" ||
        tempFile.type === "image/jpg" ||
        tempFile.type === "image/jpeg"
      ) {
        setImageCH(tempFile);
        setImageNameCH(tempFile.name);
      } else {
        errorInfo();
      }
    }
  };

  const columns = [
    {
      title: "Product ID",
      dataIndex: "productId",
      key: "productId",
      align: "center",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      align: "center",
    },
    {
      title: "Filter By",
      dataIndex: "filter_by",
      key: "filter_by",
      align: "center",
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      align: "center",
    },
    {
      title: "Inventory",
      dataIndex: "totalBalance",
      key: "totalBalance",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      align: "center",

      render: (status) => {
        return status ? "Active" : "Inactive";
      },
    },
    {
      title: "BM Name ",
      dataIndex: "bmName",
      key: "bmName",
      align: "center",
    },
    {
      title: "BM Description ",
      dataIndex: "bmDescription",
      key: "bmDescription",
      align: "center",
    },
    {
      title: "CH Name ",
      dataIndex: "chName",
      key: "chName",
      align: "center",
    },
    {
      title: "CH Description ",
      dataIndex: "chDescription",
      key: "chDescription",
      align: "center",
    },
    {
      title: "Item Image ",
      dataIndex: "image",
      key: "image",
      align: "center",

      render: (i) => {
        return <img src={i} alt="" style={{ width: "5vw" }} />;
      },
    },
    {
      title: "Item Image (BM)",
      dataIndex: "imageBM",
      key: "imageBM",
      align: "center",

      render: (i) => {
        return <img src={i} alt="" style={{ width: "5vw" }} />;
      },
    },
    {
      title: "Item Image (CH)",
      dataIndex: "imageCH",
      key: "imageCH",
      align: "center",

      render: (i) => {
        return <img src={i} alt="" style={{ width: "5vw" }} />;
      },
    },
    {
      title: "Optional for Pick Up",
      dataIndex: "pickup_optional",
      key: "pickup_optional",
      align: "center",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",

      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",

      render: (id) => {
        return (
          <div key={id}>
            <Tag onClick={() => openEdit(id)} color="blue">
              Update
            </Tag>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <br />
      <div style={{ color: "black", fontSize: "35px", textAlign: "center" }}>
        Point Shop Management
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: "20px",
        }}
      >
        {" "}
        <Button
          style={{ width: "200px", background: "lightblue", marginTop: "2%" }}
          onClick={() => setNewModal(true)}
        >
          Add New Item
        </Button>
        <Button
          style={{
            width: "200px",
            background: "lightblue",
            marginTop: "2%",
            marginLeft: "2%",
          }}
          onClick={() => setCategoryModal(true)}
        >
          Add New Category
        </Button>
        <Button
          style={{
            width: "200px",
            background: "white",
            marginTop: "2%",
            marginLeft: "2%",
          }}
          onClick={() => clearAll()}
        >
          Refresh
        </Button>
      </div>
      <div className="table-container">
        <Table
          className="management-table"
          columns={columns}
          dataSource={itemList}
        />
      </div>
      <Modal
        title={<div style={{ fontSize: "25px" }}>Add New Item</div>}
        open={newModal}
        onOk={() => addItem()}
        onCancel={() => {
          clearAll();
          setNewModal(false);
        }}
      >
        <Form
          form={form}
          name="create_brand"
          // initialValues={mainData.skuData}
        >
          <div style={{ textAlign: "right", marginTop: "5%" }}>
            <Form.Item label="Category">
              <Select
                required
                mode="multiple"
                style={{ width: "300px", textAlign: "center" }}
                placeholder="Choose Category"
                onChange={(e) => setNewData({ ...newData, category: e })}
                className="admin-portal-dropdown"
              >
                {categoryList &&
                  categoryList.map((data, idx) => (
                    <Option key={idx} value={data.id}>
                      {data.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Product ID">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.productId}
                onChange={(e) =>
                  setNewData({ ...newData, productId: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Name">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.itemName}
                onChange={(e) =>
                  setNewData({ ...newData, itemName: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Filter By">
              <Select
                style={{ width: "300px", textAlign: "left" }}
                value={newData.filter_by}
                onChange={(value) =>
                  setNewData({ ...newData, filter_by: value })
                }
              >
                <Select.Option value="RECOMMENDED">RECOMMENDED</Select.Option>
                <Select.Option value="N/A">N/A</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Unit of Measurement">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.uom}
                onChange={(e) =>
                  setNewData({ ...newData, uom: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Points for redemption">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.points}
                onChange={(e) =>
                  setNewData({ ...newData, points: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Inventory Stock">
              <Input
                required
                style={{ width: "300px" }}
                value={newData.totalBalance}
                onChange={(e) =>
                  setNewData({ ...newData, totalBalance: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Description">
              <TextArea
                required
                style={{ width: "300px" }}
                rows={4}
                value={newData.description}
                onChange={(e) =>
                  setNewData({ ...newData, description: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Image">
              <div
                onClick={() => uploadImage.current.click()}
                style={{ textAlign: "center", marginLeft: "25%" }}
              >
                <input
                  onChange={handleImage}
                  type="file"
                  ref={uploadImage}
                  hidden
                  style={{ opacity: "0" }}
                />
                {image ? (
                  <>
                    <h4>{imageName}</h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
            <Divider />
            <div>
              <Form.Item label="Item Name (BM)">
                <Input
                  required
                  style={{ width: "300px" }}
                  value={newData.bmName}
                  onChange={(e) =>
                    setNewData({ ...newData, bmName: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Item Description (BM)">
                <TextArea
                  required
                  style={{ width: "300px" }}
                  rows={4}
                  value={newData.bmDescription}
                  onChange={(e) =>
                    setNewData({ ...newData, bmDescription: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Item Image (BM)">
                <div
                  onClick={() => uploadImageBM.current.click()}
                  style={{ textAlign: "center", marginLeft: "25%" }}
                >
                  <input
                    onChange={handleImageBM}
                    type="file"
                    ref={uploadImageBM}
                    hidden
                    style={{ opacity: "0" }}
                  />
                  {imageBM ? (
                    <>
                      <h4>{imageNameBM}</h4>
                    </>
                  ) : (
                    <div>
                      <Button
                        style={{ border: "none" }}
                        icon={<UploadOutlined />}
                        type="submit"
                      >
                        Upload Image
                      </Button>
                    </div>
                  )}
                </div>
              </Form.Item>
            </div>
            <Divider />
            <div>
              <Form.Item label="Item Name (CH)">
                <Input
                  required
                  style={{ width: "300px" }}
                  value={newData.chName}
                  onChange={(e) =>
                    setNewData({ ...newData, chName: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Item Description (CH)">
                <TextArea
                  required
                  style={{ width: "300px" }}
                  rows={4}
                  value={newData.chDescription}
                  onChange={(e) =>
                    setNewData({ ...newData, chDescription: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Item Image (CH)">
                <div
                  onClick={() => uploadImageCH.current.click()}
                  style={{ textAlign: "center", marginLeft: "25%" }}
                >
                  <input
                    onChange={handleImageCH}
                    type="file"
                    ref={uploadImageCH}
                    hidden
                    style={{ opacity: "0" }}
                  />
                  {imageCH ? (
                    <>
                      <h4>{imageNameCH}</h4>
                    </>
                  ) : (
                    <div>
                      <Button
                        style={{ border: "none" }}
                        icon={<UploadOutlined />}
                        type="submit"
                      >
                        Upload Image
                      </Button>
                    </div>
                  )}
                </div>
              </Form.Item>
              <Form.Item label="Optional for Pick Up">
                <Select
                  style={{ width: "300px", textAlign: "left" }}
                  value={newData.pickup_optional}
                  onChange={(value) =>
                    setNewData({ ...newData, pickup_optional: value })
                  }
                >
                  <Select.Option value="Y">Yes</Select.Option>
                  <Select.Option value="N">No</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              {message && submit ? (
                <div className="success-modal-msg"> {message}</div>
              ) : (
                <div className="error-modal-msg">{error}</div>
              )}
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        title={<div style={{ fontSize: "25px" }}>Update Item</div>}
        open={editModal}
        onOk={editDetails}
        onCancel={() => {
          clearAll();
          setEditModal(false);
        }}
      >
        <Form
          form={form}
          name="create_brand"
          // initialValues={mainData.skuData}
        >
          <div style={{ textAlign: "right", marginTop: "5%" }}>
            <Form.Item label="Category">
              <Select
                required
                mode="multiple"
                value={editData.category}
                style={{ width: "300px", textAlign: "center" }}
                placeholder="Choose Catgory"
                className="admin-portal-dropdown"
                onChange={(e) => setEditData({ ...editData, category: e })}
              >
                {categoryList &&
                  categoryList.map((data, idx) => (
                    <Option key={idx} value={data.id}>
                      {data.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Product ID">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.productId}
                onChange={(e) =>
                  setEditData({ ...editData, productId: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Name">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.itemName}
                onChange={(e) =>
                  setEditData({ ...editData, itemName: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Filter By">
              <Select
                style={{ width: "300px", textAlign: "center" }}
                value={editData.filter_by}
                className="admin-portal-dropdown"
                // placeholder="Select from the dropdown box"
                onChange={(value) =>
                  setEditData({ ...editData, filter_by: value })
                }
              >
                <Select.Option value="RECOMMENDED">RECOMMENDED</Select.Option>
                <Select.Option value="N/A">N/A</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Unit of Measurement">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.uom}
                onChange={(e) =>
                  setEditData({ ...editData, uom: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Points for redemption">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.points}
                onChange={(e) =>
                  setEditData({ ...editData, points: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Inventory Stock">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.totalBalance}
                onChange={(e) =>
                  setEditData({ ...editData, totalBalance: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Description">
              <TextArea
                required
                style={{ width: "300px" }}
                rows={4}
                value={editData.description}
                onChange={(e) =>
                  setEditData({ ...editData, description: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Image">
              <div
                onClick={() => uploadImage.current.click()}
                style={{ textAlign: "center", marginLeft: "25%" }}
              >
                <input
                  onChange={handleImage}
                  type="file"
                  ref={uploadImage}
                  hidden
                  style={{ opacity: "0" }}
                />
                {image ? (
                  <>
                    <h4>{imageName}</h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload New Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item label="Item Name (BM)">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.bmName}
                onChange={(e) =>
                  setEditData({ ...editData, bmName: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Description (BM)">
              <TextArea
                required
                style={{ width: "300px" }}
                rows={4}
                value={editData.bmDescription}
                onChange={(e) =>
                  setEditData({ ...editData, bmDescription: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Image (BM)">
              <div
                onClick={() => uploadImageBM.current.click()}
                style={{ textAlign: "center", marginLeft: "25%" }}
              >
                <input
                  onChange={handleImageBM}
                  type="file"
                  ref={uploadImageBM}
                  hidden
                  style={{ opacity: "0" }}
                />
                {imageBM ? (
                  <>
                    <h4>{imageNameBM}</h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload New Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item label="Item Name (Ch)">
              <Input
                required
                style={{ width: "300px" }}
                value={editData.chName}
                onChange={(e) =>
                  setEditData({ ...editData, chName: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Description (CH)">
              <TextArea
                required
                style={{ width: "300px" }}
                rows={4}
                value={editData.chDescription}
                onChange={(e) =>
                  setEditData({ ...editData, chDescription: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Item Image (CH)">
              <div
                onClick={() => uploadImageCH.current.click()}
                style={{ textAlign: "center", marginLeft: "25%" }}
              >
                <input
                  onChange={handleImageCH}
                  type="file"
                  ref={uploadImageCH}
                  hidden
                  style={{ opacity: "0" }}
                />
                {imageCH ? (
                  <>
                    <h4>{imageNameCH}</h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload New Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item label="Optional for Pick Up">
              <Select
                style={{ width: "300px", textAlign: "left" }}
                value={editData.pickup_optional}
                className="admin-portal-dropdown"
                onChange={(value) =>
                  setEditData({ ...editData, pickup_optional: value })
                }
              >
                <Select.Option value="Y">Yes</Select.Option>
                <Select.Option value="N">No</Select.Option>
              </Select>
            </Form.Item>
            <div>
              {message && submit ? (
                <div className="success-modal-msg"> {message}</div>
              ) : (
                <div className="error-modal-msg">{error}</div>
              )}
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        title={<div style={{ fontSize: "25px" }}>Create New Category</div>}
        open={categoryModal}
        onOk={() => addCategory()}
        onCancel={() => {
          clearAll();
          setCategoryModal(false);
        }}
      >
        <Form
          form={form}
          name="create_category"
          onFinish={addCategory}
          // initialValues={mainData.skuData}
        >
          <div style={{ textAlign: "right", marginTop: "5%" }}>
            <Form.Item label="Name (ENG)">
              <Input
                required
                style={{ width: "300px" }}
                value={categoryName.name}
                onChange={(e) =>
                  setCategoryName({ ...categoryName, name: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Name (BM)">
              <Input
                required
                style={{ width: "300px" }}
                value={categoryName.bmName}
                onChange={(e) =>
                  setCategoryName({ ...categoryName, bmName: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item label="Name (CH)">
              <Input
                required
                style={{ width: "300px" }}
                value={categoryName.chName}
                onChange={(e) =>
                  setCategoryName({ ...categoryName, chName: e.target.value })
                }
              />
            </Form.Item>
          </div>
          <div>
            {message && submit ? (
              <div className="success-modal-msg"> {message}</div>
            ) : (
              <div className="error-modal-msg">{error}</div>
            )}
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default PointShopManagement;
