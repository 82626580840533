import footer1 from '../images/Haleon/home.png';
import footer2 from '../images/Haleon/gift2.png';
import footer3 from '../images/Haleon/uploadIcon.svg';
import footer4 from '../images/Haleon/file.png';
import footer5 from '../images/Haleon/user4.png';
import footer1green from '../images/Haleon/NavIcon/home_green.png';
import footer2green from '../images/Haleon/NavIcon/rewards_green.png';
import footer4green from '../images/Haleon/NavIcon/history_green.png';
import footer5green from '../images/Haleon/NavIcon/profile_green.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserFooter = ({ type }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');

	return (
		<div
			className="footer-container mt-5"
			style={{
				position: 'fixed',
				bottom: '0',
				zIndex: '99',
			}}
		>
			<div className="mt-1" onClick={() => navigate('/home')}>
				<img
					alt=""
					src={type === 'homePage' ? footer1green : footer1}
					style={{ width: '40%' }}
				/>
				<p
					className="text-center"
					style={{
						color: type === 'homePage' ? '#30E700' : 'white',
						fontSize: getLanguage === 'bm' ? '10px' : '12px',
					}}
				>
					{t('Home')}
				</p>
			</div>

			<div className="text-center mt-1" onClick={() => navigate('/rewards')}>
				<img
					alt=""
					src={type === 'rewardsPage' ? footer2green : footer2}
					style={{ width: '40%' }}
				/>
				<p
					className=" text-center"
					style={{
						color: type === 'rewardsPage' ? '#30E700' : 'white',
						fontSize: getLanguage === 'bm' ? '10px' : '12px',
					}}
				>
					{t('Rewards')}
				</p>
			</div>

			<div
				className="text-center"
				style={{ marginTop: '-40px' }}
				onClick={() => navigate('/upload')}
			>
				<div style={{ position: 'relative', bottom: '0px' }}>
					<img
						alt=""
						src={footer3}
						style={{ border: '5px solid white', borderRadius: '50px' }}
						className="img-upload-receipt"
					/>
				</div>
				<p className="t-white text-center upload-receipt-foooter">
					{t('Upload Receipt')}
				</p>
			</div>

			<div
				className="text-center mt-1"
				onClick={() => navigate('/submissions')}
			>
				<img
					alt=""
					src={type === 'submissionsPage' ? footer4green : footer4}
					style={{ width: '40%' }}
				/>
				<p
					className="text-center"
					style={{
						color: type === 'submissionsPage' ? '#30E700' : 'white',
						fontSize: getLanguage === 'bm' ? '10px' : '12px',
					}}
				>
					{t('History')}
				</p>
			</div>

			<div className="text-center mt-1" onClick={() => navigate('/profile')}>
				<img
					alt=""
					src={type === 'profilePage' ? footer5green : footer5}
					style={{ width: '40%' }}
				/>
				<p
					className="text-center"
					style={{
						color: type === 'profilePage' ? '#30E700' : 'white',
						fontSize: getLanguage === 'bm' ? '10px' : '12px',
					}}
				>
					{t('Profile')}
				</p>
			</div>
		</div>
	);
};

export default UserFooter;
