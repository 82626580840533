import React from 'react';
import { Modal, Button, message } from 'antd';
import axiosClient from '../../utils/axiosClient';

const CancelSubscription = ({
	isSuccess,
	setIsSuccess,
	email,
	sms_Wa,
	phone,
	handleUnsubscribeType,
	setHandleUnsubscribeType,
}) => {
	const cancelChanges = () => {
		setIsSuccess(false);
		// message.info('Changes cancelled');
	};

	const confirmUnsubscribe = async () => {
		const updatedEmail = handleUnsubscribeType === 'EMAIL' ? !email : email;
		const updatePhone = handleUnsubscribeType === 'PHONE' ? !phone : phone;
		const updateSms_Wa = handleUnsubscribeType === 'SMS_WA' ? !sms_Wa : sms_Wa;

		try {
			const res = await axiosClient.post('/profile/update', {
				configNoti: {
					general: {
						email: updatedEmail,
						phone: updatePhone,
						sms_Wa: updateSms_Wa,
					},
				},
			});
			if (res && res.data) {
				message.success('Successfully updated contact preferences');
				setIsSuccess(false);
				window.location.reload();
			}
		} catch (error) {
			console.error('Error updating contact preferences:', error);
		}
	};

	return (
		<Modal
			open={isSuccess}
			centered
			header={null}
			footer={null}
			onCancel={() => setIsSuccess(false)}
			closable={false}
			width={330}
		>
			<div className="text-center">
				<div className="submit-receipt-success-header">Cancel Subscription</div>
				<div className="submit-pending-modal-font mt-2">
					<p
						style={{
							textAlign: 'center',
							fontSize: '14px',
							lineHeight: '15px',
						}}
					>
						Are you sure you want to unsubscribe from receiving updates,
						promotions, or offers via{' '}
						<b>
							{handleUnsubscribeType === 'EMAIL'
								? 'Email Newsletter'
								: handleUnsubscribeType === 'PHONE'
								? 'Phone Call'
								: 'SMS/WhatsApp'}
						</b>
						?
					</p>
					<p
						style={{
							textAlign: 'center',
							fontSize: '14px',
							lineHeight: '15px',
							paddingTop: '5%',
						}}
					>
						please click 'Cancel' to stay subscribed or 'Confirm' to
						unsubscribe.
					</p>
				</div>
				<Button
					onClick={confirmUnsubscribe}
					className="coming-soon-button mt-1"
					style={{ fontWeight: '700' }}
				>
					Confirm
				</Button>
				<Button
					className="receipt-success-button mt-1"
					onClick={cancelChanges}
					style={{
						width: '90%',
						background: 'transparent',
						color: 'black',
						borderRadius: '5px',
						height: '35px',
						border: '1px solid black',
						fontWeight: 'bold',
					}}
				>
					Cancel
				</Button>
			</div>
		</Modal>
	);
};

export default CancelSubscription;
