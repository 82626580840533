import React, { useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import axiosClient from '../../utils/axiosClient';
import panaflex from '../../images/Haleon/panaflex.svg';
import sensodyne from '../../images/Haleon/sensodyne.svg';
import polident from '../../images/Haleon/polident.svg';
import scotts from '../../images/Haleon/scotts.svg';
import centrum from '../../images/Haleon/centrum.svg';
import caltrate from '../../images/Haleon/caltrate.svg';
import paradontax from '../../images/Haleon/paradontax.svg';
import calsource from '../../images/Haleon/calsource.svg';
import eno from '../../images/Haleon/eno.svg';
import { useTranslation } from 'react-i18next';

const Questionnaire = ({ displayQues, setDisplayQues, channel }) => {
	const { t } = useTranslation();
	const options = [t('Yes'), t('No')];
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [success, setSuccess] = useState();
	const [error, setError] = useState();

	const products = [
		{ img: panaflex, name: 'Panaflex', width: '85px', height: '85px' },
		{ img: sensodyne, name: 'Sensodyne', width: '100px', height: '100px' },
		{ img: polident, name: 'Polident', width: '85px', height: '85px' },
		{ img: scotts, name: "Scott's", width: '73px', height: '73px' },
		{ img: centrum, name: 'Centrum', width: '83px', height: '85px' },
		{ img: caltrate, name: 'Caltrate', width: '85px', height: '85px' },
		{ img: paradontax, name: 'Paradontax', width: '100px', height: '100px' },
		{ img: calsource, name: 'Calsource', width: '85px', height: '83px' },
		{ img: eno, name: 'Eno', width: '85px', height: '85px' },
	];

	const handleCheckboxChange = (value) => {
		setSelectedOption(value);
		setSelectedProducts([]);
	};

	const handleProductSelect = (selectedProduct) => {
		const isSelected = selectedProducts.includes(selectedProduct);

		if (isSelected) {
			const updatedProducts = selectedProducts.filter(
				(product) => product !== selectedProduct
			);
			setSelectedProducts(updatedProducts);
		} else {
			setSelectedProducts([...selectedProducts, selectedProduct]);
		}
	};

	const handleSubmit = async () => {
		if (selectedOption) {
			const remark = {
				Question_Haleon_Rewards: t(
					'Have you purchase any Haleon products in the past 6 months?'
				),
				Answer: { selectedOption },
				'Product(s)': selectedOption === t('Yes') ? selectedProducts : 'none',
			};

			try {
				if (selectedOption === t('Yes') && !selectedProducts.length) {
					setError(t('Please select at least one product!'));
					setTimeout(() => {
						setError(null);
					}, 1000);
					return;
				}

				const res = await axiosClient.post('/campaign/questionnaire', {
					remark: remark,
					campaignId: channel,
				});

				if (res) {
					setSuccess(res.data.message);
					setTimeout(() => {
						setDisplayQues(false);
					}, 1000);
				}
			} catch (err) {
				setError(err.response.data.message);
				console.error('error', err);
			}
		} else {
			setError('Please select an option first!');
			setTimeout(() => {
				setError(null);
			}, 1000);
		}
	};

	return (
		<div>
			<Modal
				open={displayQues}
				centered
				footer={null}
				closable={true}
				maskClosable={false}
				onCancel={() => setDisplayQues(false)}
				width={350}
			>
				<p
					className="font-weight-600 text-center mt-3"
					style={{ fontSize: '13px' }}
				>
					{t('Have you purchase any Haleon products in the past 6 months?')}
				</p>
				<div className="text-center" style={{ paddingTop: '5%' }}>
					{options.map((option) => (
						<div key={option} style={{ paddingTop: '3%' }}>
							<div
								style={{
									justifyContent: 'space-between',
									display: 'flex',
									alignItems: 'center',
									padding: '3% 5% 3% 5%',
									backgroundColor:
										option === t('Yes') && selectedOption === t('Yes')
											? '#FFEBAA'
											: '#F6F6F6',
								}}
							>
								{option}{' '}
								<Checkbox
									onChange={() => handleCheckboxChange(option)}
									checked={option === selectedOption}
									className="options-checkbox"
								/>
							</div>
							<div>
								{option === t('Yes') && selectedOption === t('Yes') ? (
									<div>
										<div
											mode="multiple"
											placeholder="Select products"
											style={{ width: '100%' }}
										>
											{products.map((product, index) => (
												<div
													key={index}
													value={product}
													style={{
														justifyContent: 'space-between',
														alignItems: 'center',
														display: 'flex',
														marginTop: '0.3rem',
														height: '42px',
														background: '#F6F6F6',
													}}
												>
													<img
														src={product.img}
														alt={product.name}
														style={{
															width: product.width,
															height: product.height,
															objectFit: 'contain',
															padding: '5% 16px',
														}}
													/>
													<Checkbox
														onChange={() => handleProductSelect(product.name)}
														checked={selectedProducts.includes(product.name)}
														className={selectedProducts && 'products-checkbox'}
														style={{ paddingRight: '7%' }}
													/>
												</div>
											))}
										</div>
									</div>
								) : null}
							</div>
						</div>
					))}
				</div>

				<div
					style={{
						color: error ? 'red' : 'green',
						textAlign: 'center',
						fontSize: '12px',
					}}
				>
					{error ? error : success}
				</div>

				{selectedOption && (
					<Button
						style={{
							width: '100%',
							textAlign: 'center',
							borderRadius: '5px',
							background: '#30E700',
							border: 'none',
							color: 'black',
							fontWeight: '700',
							height: '40px',
							marginTop: '5%',
						}}
						onClick={handleSubmit}
					>
						{t('Submit')}
					</Button>
				)}
			</Modal>
		</div>
	);
};

export default Questionnaire;
