import "./index.css";

import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  clearError,
  getReceipt,
  updateValidate,
} from "../../../reducer/adminReducer";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
// const { TextArea } = Input
function ReceiptValidation() {
  const [form] = Form.useForm();
  const [viewData, setViewData] = useState({});
  const [viewModal, setViewModal] = useState();
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const [err, setErr] = useState("");
  const { receiptList, message, storeList, skuList } = useSelector(
    (state) => state.admin
  );
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [filteredTransList, setFilteredTransList] = useState([]);


  useEffect(() => {
    dispatch(getReceipt());
    dispatch(clearError());
    // eslint-disable-next-line
  }, []);

  const goFilter = () => {
    dispatch(getReceipt(filter));
  };
  const openView = (rowId) => {
    let rowData = receiptList.filter((i) => i.id === rowId)[0];
    if (rowData) {
      setViewData({ ...rowData });
      setViewModal(true);
    }
  };
  const callApi = async ({ status }) => {
    try {
      // const res = await axios.post("/api/admin/validate", { status, viewData });
      dispatch(updateValidate({ status, viewData }));
      setViewModal(false);
      // if (res) {
      //   dispatch(getReceipt());
      //   setViewModal(false);
      // }
    } catch (error) {
      // setErr(error.response.data.error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (message && !err) {
    }
    // eslint-disable-next-line
  }, [message, err, submit]);

  const columns = [
		{
			title: "User ID",
			dataIndex: "userId",
			key: "userId",
			align: "center",
		},
		{
			title: "Phone Number",
			dataIndex: "userNumber",
			key: "userNumber",
			align: "center",
		},
		{
			title: "Upload Date",
			dataIndex: "uploadDate",
			key: "uploadDate",
			align: "center",
			render: (date) => {
				return moment(date).format("DD-MM-YYYY");
			},
		},
		{
			title: "Receipt Number",
			dataIndex: "invoiceNo",
			key: "invoiceNo",
			align: "center",
		},
		{
			title: "Transaction ID",
			dataIndex: "id",
			key: "id",
			align: "center",
		},
		{
			title: "SKU ID",
			dataIndex: "skuId",
			key: "skuId",
			align: "center",
		},
		{
			title: "Product Name",
			dataIndex: "productName",
			key: "productName",
			align: "center",
		},
		{
			title: "Total Quantity",
			dataIndex: "quantity",
			key: "quantity",
			align: "center",
		},
		{
			title: "Unit Price",
			dataIndex: "price",
			key: "price",
			align: "center",
		},
		{
			title: "Subtotal Price",
			dataIndex: "subtotal",
			key: "subtotal",
			align: "center",
		},
		{
			title: "Receipt Image",
			dataIndex: "url",
			key: "url",
			align: "center",
			render: (i) => {
				return <img src={i} alt="" style={{ width: "5vw" }} />;
			},
		},
		{
			title: "Allocated Points",
			dataIndex: "points",
			key: "points",
		},
		{
			title: "Vouchers Earned",
			dataIndex: "voucherCode",
			key: "voucherCode",
		},
		// {
		//   title: "Store Name",
		//   dataIndex: "storename",
		//   key: "storename",
		// },
		// {
		//   title: "Store State",
		//   dataIndex: "state",
		//   key: "state",
		// },
		// {
		//   title: "Receipt Number",
		//   dataIndex: "invoiceNo",
		//   key: "invoiceNo",
		// },
		// {
		//   title: "Allocated Points",
		//   dataIndex: "sales",
		//   key: "sales",
		// },
		// {
		//   title: 'Total Sold Unit(Qty)',
		//   dataIndex: 'totalSold',
		//   key: 'totalSold',
		// },
		{
			title: "Reward Type",
			dataIndex: "rewardType",
			key: "rewardType",
			align: "center",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			align: "center",
		},
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
			align: "center",
		},
		{
			title: "Action",
			dataIndex: "id",
			key: "id",
			align: "center",
			render: (id) => {
				return (
					<div key={id}>
						<Tag onClick={() => openView(id)} color="blue">
							Update
						</Tag>
					</div>
				);
			},
		},
	];
	const callApprove = () => {
		setSubmit(true);
		callApi({ status: "APPROVED" });
	};
	const callIssue = () => {
		setSubmit(true);
		callApi({ status: "ISSUE" });
	};
	const callReject = () => {
		setSubmit(true);
		callApi({ status: "REJECTED" });
	};

	const generateCSV = (columns, data, filename) => {
		const header = columns.map((column) => column.title).join(",");

		const csvData = [header]
			.concat(
				data.map((item) => {
					return columns
						.map((column) => {
							// Convert the value to a string and enclose in double quotes if it contains commas
							const value = String(item[column.dataIndex]);
							if (value.includes(",")) {
								return `"${value}"`;
							} else {
								return value;
							}
						})
						.join(",");
				})
			)
			.join("\n");

		const blob = new Blob([csvData], { type: "text/csv" });
		const downloadLink = document.createElement("a");
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = filename;
		downloadLink.click();
	};

	const handleDownload = () => {
		generateCSV(columns, receiptList, "receiptValidationReport.csv");
	};

	return (
		<div>
			<br />
			<div style={{ color: "black", fontSize: "35px", textAlign: "center" }}>
				Receipt Validation
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginTop: "5px",
					marginBottom: "20px",
				}}
			>
				<div>
					<Space.Compact>
						<Input
							prefix={<SearchOutlined />}
							placeholder=" Search here..."
							onChange={(e) => {
								const searchTerm = e.target.value.toLowerCase();
								const filterData = receiptList.filter((record) => {
									return Object.values(record).some((value) =>
										String(value).toLowerCase().includes(searchTerm)
									);
								});
								setFilteredTransList(filterData);
							}}
							style={{
								height: "40px",
								display: "flex",
								alignItems: "center",
								width: "80%",
							}}
						/>
					</Space.Compact>
				</div>
				<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
					<DatePicker
						label="Start Date"
						value={filter.startDate}
						onChange={(newValue) => {
							setFilter({ ...filter, startDate: newValue });
						}}
						renderInput={(params) => <Input {...params} />}
					/>{" "}
					<DatePicker
						style={{ fontSize: "10px" }}
						label="End Date"
						value={filter.endDate}
						onChange={(newValue) => {
							setFilter({ ...filter, endDate: newValue });
						}}
						renderInput={(params) => <Input {...params} />}
					/>
					<Button
						style={{
							width: "150px",
							background: "lightblue",
							marginLeft: "10px",
							height: "40px",
						}}
						onClick={() => goFilter()}
					>
						Search
					</Button>
				</div>
				<div>
					<p
						style={{ textDecoration: "underline", color: "gray" }}
						onClick={handleDownload}
					>
						Download Report
					</p>
				</div>
			</div>
			<div className="table-container">
				<Table
					className="management-table"
					columns={columns}
					dataSource={
						filteredTransList.length ? filteredTransList : receiptList
					}
					style={{ objectFit: "contain" }}
				/>
			</div>
			<Modal
				title={<div style={{ fontSize: "25px" }}>Update Details</div>}
				open={viewModal}
				closable={true}
				className="validate-modal"
				footer={null}
				onClose={() => {
					dispatch(clearError());
					setViewModal(false);
				}}
				onCancel={() => {
					dispatch(clearError());
					setViewModal(false);
				}}
			>
				<div style={{ display: "flex", textAlign: "right" }}>
					<div style={{ padding: "3%" }}>
						<img
							src={viewData.url}
							alt=""
							style={{ width: "500px", height: "400px" }}
						/>
					</div>
					<div style={{ marginTop: "3%" }}>
						<Row>
							<Col span={12}>State : </Col>
							<Col span={12} push={5}>
								<Select
									className="validate-input admin-portal-dropdown"
									placeholder="Select State"
									value={viewData.state}
									onChange={(value) =>
										setViewData({ ...viewData, state: value })
									}
								>
									<Select.Option value="Johor">Johor</Select.Option>
									<Select.Option value="Kedah">Kedah</Select.Option>
									<Select.Option value="Kelantan">Kelantan</Select.Option>
									<Select.Option value="Melaka">Melaka</Select.Option>
									<Select.Option value="Negeri Sembilan">
										Negeri Sembilan
									</Select.Option>
									<Select.Option value="Pahang">Pahang</Select.Option>
									<Select.Option value="Penang">Penang</Select.Option>
									<Select.Option value="Perak">Perak</Select.Option>
									<Select.Option value="Perlis">Perlis</Select.Option>
									<Select.Option value="Sabah">Sabah</Select.Option>
									<Select.Option value="Sarawak">Sarawak</Select.Option>
									<Select.Option value="Selangor">Selangor</Select.Option>
									<Select.Option value="Terengganu">Terengganu</Select.Option>
									<Select.Option value="Wilayah Persekutuan">
										Wilayah Persekutuan
									</Select.Option>
									{/* <Option value="Ecommerce">Ecommerce</Option> */}
								</Select>{" "}
							</Col>
						</Row>
						<Row style={{ marginTop: "2%" }}>
							<Col span={12}>Store Name : </Col>
							<Col span={12} push={5}>
								<Select
									required
									className="validate-input admin-portal-dropdown"
									placeholder="Choose Store Name"
									onChange={(e) => setViewData({ ...viewData, name: e })}
								>
									{storeList &&
										storeList.map((data, idx) => (
											<Option key={idx} value={data}>
												{data}
											</Option>
										))}
								</Select>{" "}
							</Col>
						</Row>
						<Row style={{ marginTop: "2%" }}>
							<Col span={12}>Receipt Number : </Col>
							<Col span={12} push={5}>
								<Input
									required
									className="validate-input"
									value={viewData.invoice_no}
									onChange={(e) =>
										setViewData({ ...viewData, invoiceNo: e.target.value })
									}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: "2%" }}>
							<Col span={12}>Receipt Date : </Col>
							<Col span={12} push={5}>
								<DatePicker
									className="validate-input"
									onChange={
										(e) => setViewData({ ...viewData, receiptDate: moment(e) })
										// console.log(moment(e))
									}
								/>
							</Col>
						</Row>

						<Row style={{ marginTop: "2%" }}>
							<Col span={12}></Col>
							<Col span={12} push={5}>
								<Button
									className="btn btn-primary validate-input"
									onClick={() =>
										setViewData({
											...viewData,
											skuData: [
												...viewData.skuData,
												{ name: "", quantity: 0, price: 0 },
											],
										})
									}
								>
									{" "}
									Add More SKU
								</Button>
							</Col>
						</Row>
						<div style={{ marginTop: "2%" }} />
						<center>
							{viewData.skuData && viewData.skuData.length
								? viewData.skuData.map((item, index) => (
										<div>
											<div key={index}>
												<Row style={{ marginTop: "2%", textAlign: "right" }}>
													<Col span={12}>SKU Name :</Col>
													<Col span={12} push={5}>
														<Select
															required
															className="validate-input admin-portal-dropdown"
															label="SKU"
															value={item.name}
															onChange={(e) => {
																const value = e;
																const updatedSkuData = [...viewData.skuData];
																updatedSkuData[index].name = value;
																setViewData({
																	...viewData,
																	skuData: updatedSkuData,
																	index,
																});
															}}
															filterOption={(inputValue, option) =>
																option.value
																	.toUpperCase()
																	.indexOf(inputValue.toUpperCase()) !== -1
															}
															options={skuList}
														/>
													</Col>
												</Row>
												<Row style={{ marginTop: "2%", textAlign: "right" }}>
													<Col span={12}>Qty : </Col>
													<Col span={12} push={5}>
														<Input
															name="quantity"
															className="validate-input"
															type="number"
															value={item.quantity}
															onChange={(e) => {
																const { valueAsNumber } = e.target;
																const updatedSkuData = [...viewData.skuData];
																updatedSkuData[index].quantity = valueAsNumber;
																setViewData({
																	...viewData,
																	skuData: updatedSkuData,
																});
																let firstAmount =
																	viewData.skuData[0]?.price *
																	viewData.skuData[0]?.quantity;
																let newAmount = viewData.skuData.reduce(
																	(a, b) =>
																		a.price * a.quantity + b.price * b.quantity
																);
																// console.log(newAmount)
																setViewData({
																	...viewData,
																	recamount:
																		viewData.skuData.length > 1
																			? newAmount
																			: firstAmount,
																});
															}}
														/>
													</Col>
												</Row>
												<Row style={{ marginTop: "2%", textAlign: "right" }}>
													<Col span={12}>Price : </Col>
													<Col span={12} push={5}>
														<Input
															name="price"
															className="validate-input"
															type="number"
															value={item.price}
															onChange={(e) => {
																const { valueAsNumber } = e.target;
																const updatedSkuData = [...viewData.skuData];
																updatedSkuData[index].price = valueAsNumber;
																setViewData({
																	...viewData,
																	skuData: updatedSkuData,
																});
																let firstAmount =
																	viewData.skuData[0]?.price *
																	viewData.skuData[0]?.quantity;
																let newAmount = viewData.skuData.reduce(
																	(a, b) =>
																		a.price * a.quantity + b.price * b.quantity
																);
																// console.log('new', newAmount)
																setViewData({
																	...viewData,
																	recamount:
																		viewData.skuData.length > 1
																			? newAmount
																			: firstAmount,
																});
															}}
														/>
													</Col>
												</Row>
												<Row style={{ marginTop: "2%" }}>
													<Col span={12}></Col>
													<Col span={8} push={10}>
														<p
															onClick={() => {
																// console.log('delete',this.state.skuData[index].name)
																// const updatedSkuData = viewData.skuData.filter(n => n.name[0].label !== viewData.skuData[index].name[0]?.label)
																const updatedSkuData = viewData.skuData.filter(
																	(n) => n.name !== viewData.skuData[index].name
																);
																// console.log('keep this ', updatedSkuData)

																let firstAmount = updatedSkuData.length
																	? updatedSkuData[0]?.price *
																	  updatedSkuData[0]?.quantity
																	: 0;
																let newAmount = updatedSkuData.length
																	? updatedSkuData.reduce(
																			(a, b) =>
																				a.price * a.quantity +
																				b.price * b.quantity
																	  )
																	: 0;
																// console.log(updatedSkuData.length > 1 ? newAmount : firstAmount )
																setViewData({
																	...viewData,
																	recamount:
																		updatedSkuData.length > 1
																			? newAmount
																			: firstAmount,
																	skuData: updatedSkuData,
																});
															}}
															style={{
																color: "red",
																width: "3vw",
																fontSize: "20px",
															}}
														>
															X
														</p>
													</Col>
												</Row>
											</div>
										</div>
								  ))
								: ""}
						</center>

						<Row style={{ marginTop: "2%" }}>
							<Col span={12}>Receipt Amount : </Col>
							<Col span={12} push={5}>
								<Input
									disabled
									required
									className="validate-input"
									value={viewData.recamount}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: "2%" }}>
							<Col span={12}>Promotion : </Col>
							<Col span={12} push={5}>
								<Switch
									className="validate-input"
									value={viewData.promo}
									checkedChildren="YES"
									unCheckedChildren="NO"
									onChange={() =>
										setViewData({ ...viewData, promo: !viewData.promo })
									}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: "2%" }}>
							<Col span={12}>Reason : </Col>
							<Col span={12} push={5}>
								<Select
									className="validate-input admin-portal-dropdown"
									placeholder="Select Reason"
									value={viewData.reason}
									onChange={(e) => setViewData({ ...viewData, reason: e })}
								>
									<Option value="Receipt Amount not reach">
										Receipt Amount not reach
									</Option>
									<Option value="Receipt Invalid">Receipt Invalid</Option>
								</Select>
							</Col>
						</Row>
						{err && submit ? (
							<Col span={24} push={13}>
								<div className="error-modal-msg">{err}</div>
							</Col>
						) : (
							<div className="success-modal-msg"> {message}</div>
						)}
						<div style={{ marginTop: "5%" }}>
							<Col span={24} push={9}>
								<Button
									style={{ fontWeight: "bold" }}
									onClick={() => callApprove()}
								>
									APPROVED
								</Button>{" "}
								<Button
									style={{ fontWeight: "bold" }}
									onClick={() => callReject()}
								>
									REJECTED
								</Button>{" "}
								<Button
									style={{ fontWeight: "bold" }}
									onClick={() => callIssue()}
								>
									ISSUE
								</Button>{" "}
							</Col>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default ReceiptValidation;
