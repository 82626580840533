import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, message } from 'antd';
import '../../css/Address.css';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/SubHeader';
import axiosClient from '../../utils/axiosClient';
import { objectToURLSearchParamsString } from '../../utils/axiosHelper';

const DeliveryAddress = ({t}) => {
	const navigate = useNavigate();
	const [allAddress, setAllAddress] = useState([]);
	const [addr, setAddr] = useState('');
	const [displayError, setDisplayError] = useState(false);
	const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1);
	const [selectedAddrId, setSelectedAddrId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const fetchUserAddress = () => {
		const params = {
			isActive: true,
		};
		const queryString = objectToURLSearchParamsString(params);
		axiosClient
			.get('/address?' + queryString)
			.then((result) => {
				if (result?.data?.data && Array.isArray(result?.data?.data)) {
					setAllAddress(result?.data?.data);
				} else {
					console.error('result: ', result);
				}
			})
			.catch((err) => {
				console.error('fetchUserCartsSummary: ', err);
				message.error(err?.message || 'Error occur on fetching address');
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		fetchUserAddress();
	}, []);

	const getAddressInfo = (address, index) => {
		setSelectedAddrId(address.id);
		const selectedAddressInfo = {
		  id: address.id,
		  name: address.name,
		  phone: address.phone,
		  line1: address.line1,
		  line2: address.line2,
		  postcode: address.postcode,
		  city: address.city,
		};
		setAddr(selectedAddressInfo);
		setSelectedAddressIndex(index);
	  };


	const handleToNextPage = () => {
		if (addr !== '') {
			navigate('/cartSummary', {
				state: { addrId: selectedAddrId, addr: addr },
			});
			// navigate("/cartSummary", { state: { addr: addr }});
		} else {
			setDisplayError(true);
		}
	};

	return (
		<div
			style={{
				opacity: isLoading ? '0.5' : '1',
			}}
		>
			<SubHeader setPreviousPage={-1} />

			<div className="mt-1">
				<hr />
			</div>

			<div className="ml-3 mr-3 mt-2">
				<div className="d-flex" style={{ justifyContent: 'space-between' }}>
					<p className="delivery-details-font">{t('Confirm Address')}</p>
				</div>

				<div>
					<p>Choose your delivery destination below</p>
				</div>

				{allAddress.map((ele, index) => {
					const isSelected = index === selectedAddressIndex;

					return (
						<div className="mt-2" key={index}>
							<div
								style={{
									boxShadow:
										'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
									borderRadius: '15px',
									height: '130px',
									position: 'relative',
									border: isSelected ? '2px solid grey' : null,
								}}
								onClick={() => getAddressInfo(ele, index)}
							>
								<div style={{ justifyContent: 'left' }}>
									<div className="reward-card">
										<div style={{ textAlign: 'left' }}>
											<p className="fontSize-18" style={{
												textTransform: 'capitalize',
											}}>{ele.name}</p>
											<p
												className="fontSize-16"
												style={{ color: '#64656A', width: '100%' }}
												key={ele.id}
											>
												{ele.line1} {ele.line2},<br />
												{ele.postcode}, {ele.city}
											</p>
											<p className="fontSize-16" style={{ color: '#64656A' }}>
												{ele.phone}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}

				{displayError ? (
					<p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
						Please choose the delivery address!
					</p>
				) : null}

				<Button
					style={{
						backgroundColor: '#30E700',
						width: '100%',
						marginTop: '20px',
						border: 'none',
					}}
					onClick={handleToNextPage}
				>
					Confirm
				</Button>

				<div className="mt-2" style={{ marginBottom: '80px' }}></div>
			</div>
		</div>
	);
};

export default DeliveryAddress;
