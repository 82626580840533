import React, { useState, useEffect } from "react";
import { Table, Tag, Modal, DatePicker, Input, Button, Space } from "antd";
import { getPoint, clearError } from "../../../reducer/adminReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";

function PointHistoryReport() {
  const dispatch = useDispatch();
  const { pointList } = useSelector((state) => state.admin);
  const [viewList, setViewList] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [filteredTransList, setFilteredTransList] = useState([]);

  useEffect(() => {
    dispatch(getPoint());
    dispatch(clearError());
    // eslint-disable-next-line
  }, []);

  const openView = (rowId) => {
    let rowData = pointList.filter((i) => i.id === rowId)[0];
    if (rowData) {
      setViewList(rowData.transactions);
      setViewModal(true);
    }
  };
  const goFilter = () => {
    dispatch(getPoint(filter));
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: 'center',
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: 'center',
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      align: 'center',
    },
    // {
    //   title: 'Tier',
    //   dataIndex: 'tier',
    //   key: 'tier',
    // },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: 'center',
      render: (id) => {
        return (
          <div key={id}>
            <Tag onClick={() => openView(id)} color="blue">
              View
            </Tag>
          </div>
        );
      },
    },
  ];
  const columnsView = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "Point Description",
      dataIndex: "desc",
      key: "desc",
    },
  ];


  const generateCSV = (columns, data, filename) => {
    const header = columns.map((column) => column.title).join(",");

    const csvData = [header]
      .concat(
        data.map((item) => {
          return columns
            .map((column) => {
              // Convert the value to a string and enclose in double quotes if it contains commas
              const value = String(item[column.dataIndex]);
              if (value.includes(",")) {
                return `"${value}"`;
              } else {
                return value;
              }
            })
            .join(",");
        })
      )
      .join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  };

  const handleDownload = () => {
    generateCSV(columns, pointList, "pointHistoryReport.csv");
  };

  return (
    <div>
      <br />
      <div style={{ color: "black", fontSize: "35px", textAlign:'center' }}>
        Point History Report
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: "20px",
        }}
      >
        <div>
          <Space.Compact>
            <Input
              prefix={<SearchOutlined />}
              placeholder=" Search here..."
              onChange={(e) => {
                const searchTerm = e.target.value.toLowerCase();
                const filterData = pointList.filter((record) => {
                  return Object.values(record).some((value) =>
                    String(value).toLowerCase().includes(searchTerm)
                  );
                });
                setFilteredTransList(filterData);                
              }}
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                width: "80%",
              }}
            />
          </Space.Compact>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <DatePicker
            label="Start Date"
            value={filter.startDate}
            onChange={(newValue) => {
              setFilter({ ...filter, startDate: newValue });
            }}
            renderInput={(params) => <Input {...params} />}
          />{" "}
          <DatePicker
            style={{ fontSize: "10px" }}
            label="End Date"
            value={filter.endDate}
            onChange={(newValue) => {
              setFilter({ ...filter, endDate: newValue });
            }}
            renderInput={(params) => <Input {...params} />}
          />
          <Button
            style={{
              width: "150px",
              background: "lightblue",
              marginLeft: "10px",
              height: "40px",
            }}
            onClick={() => goFilter()}
          >
            Search
          </Button>
        </div>
        <div>
          <p
            style={{ textDecoration: "underline", color: "gray" }}
            onClick={handleDownload}
          >
            Download Report
          </p>
        </div>
      </div>
      <div className="table-container">
        <Table
          className="management-table"
          columns={columns}
          dataSource={filteredTransList.length ? filteredTransList : pointList}
          style={{ objectFit: "contain" }}
        />
      </div>
      <Modal
        title={
          <div style={{ fontSize: "25px" }}>View Transaction's Details</div>
        }
        open={viewModal}
        closable={true}
        footer={null}
        onCancel={() => {
          setViewModal(false);
        }}
      >
        <Table
          className="management-table"
          columns={columnsView}
          dataSource={viewList}
        />
      </Modal>
    </div>
  );
}

export default PointHistoryReport;
