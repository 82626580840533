import { Modal, Button } from 'antd';
import termsIcon from '../../images/Haleon/Paper.png';
import { useTranslation } from 'react-i18next';

const TermsGrab = ({ termsGrab, setTermsGrab }) => {
	const { t } = useTranslation();

	return (
		<Modal open={termsGrab} centered footer={null} closable={false}>
			<div className="text-center">
				<img style={{ width: '18%' }} src={termsIcon} alt="T&C_img" />
				<div className="mt-1" style={{ lineHeight: '40px' }}>
					<h3 className="mt-2" style={{ fontSize: '21px' }}>
						{t('Terms & Conditions')}
					</h3>
				</div>
				<ul
					style={{
						padding: '0 15px',
						fontSize: '14px',
						textAlign: 'justify',
						textJustify: 'auto',
					}}
				>
					<li className="my-1">
						{' '}
						{t(
							'Users must enter promo code at checkout to be entitled to the discount.'
						)}
					</li>
					<li className="my-1">
						{t(
							'This promo code is valid for (1) ONE order from services on GrabFood, GrabRide, GrabExpress or GrabMart.'
						)}
					</li>
					<li className="my-1">
						{t('This promo code will be expired on 31 December 2024')}
					</li>
					<li className="my-1">
						{t('Promotion is not valid with any other vouchers or promotions.')}
					</li>
					<li className="my-1">
						{t(
							'Grab reserves the right to alter, extend or terminate the promotion, or amend the terms and conditions at its sole discretion at any time without prior notice. In case of any disputes directly or indirectly arising from the promotion, the decision of Grab shall be final.'
						)}
					</li>
					<li className="my-1">
						{t(
							'These terms and conditions shall be governed by the laws of Malaysia and any dispute arising out of or in connection with promotion shall be referred to the exclusive jurisdiction of courts of Malaysia.'
						)}
					</li>
				</ul>
				<Button
					type="primary"
					className="mt-1"
					onClick={() => setTermsGrab(false)}
					style={{
						fontSize: '12px',
						borderRadius: '8px',
						background: '#CB0E07',
						color: 'white',
						border: 'none',
						fontWeight: '500',
						height: '40px',
						width: '90%',
					}}
				>
					{t('DONE')}
				</Button>
			</div>
		</Modal>
	);
};

export default TermsGrab;
