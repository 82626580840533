export const filterItems = [
    {
        key: '1',
        label: 'Overview',
        value: 'overview',
    },
    {
        key: '2',
        label: 'Last Month',
        value: 'last_month',
    },
    {
        key: '3',
        label: 'Last 3 Months',
        value: 'last_three_months',
    },
    {
        key: '4',
        label: 'Last 6 Months',
        value: 'last_six_months',
    },
];