import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  clearError,
  getRating,
  updateRating,
} from "../../../reducer/adminReducer";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";

import moment from "moment";

function RatingReport() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const { ratingList, message, error } = useSelector((state) => state.admin);
  const [viewModal, setViewModal] = useState(false);
  const [filter, setFilter] = useState({ startDate: "", endDate: "" });
  const [editData, setEditData] = useState();
  const [editModal, setEditModal] = useState();
  const [filteredRatingList, setFilterRatingList] = useState([]);
  
  useEffect(() => {
    dispatch(getRating({}));
    dispatch(clearError());
    // eslint-disable-next-line
  }, []);

  // initially show all list
    useEffect(() => {
   setFilterRatingList(ratingList)
  }, [ratingList])

  const openEdit = (rowId) => {
    const rowData = ratingList.filter((i) => i.id === rowId)[0];
    if (rowData) {
      setEditData(rowData);
      setEditModal(true);
    }
  };

  const goFilter = () => {
    // let filterList = ratingList.filter((i) => i.id === filter);
    // setDataSource(filterList);
    dispatch(getRating(filter));
    //  JS should filter in frontend only
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      align: "center",
    },
    {
      title: "Product ID",
      dataIndex: "productId",
      key: "productId",
      align: "center",
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
      align: "center",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",

      render: (id) => {
        return (
          <div key={id}>
            <Tag onClick={() => openEdit(id)} color="blue">
              Update
            </Tag>
          </div>
        );
      },
    },
  ];

  const generateCSV = (columns, data, filename) => {
    const header = columns.map((column) => column.title).join(",");

    const csvData = [header]
      .concat(
        data.map((item) => {
          return columns
            .map((column) => {
              // Convert the value to a string and enclose in double quotes if it contains commas
              const value = String(item[column.dataIndex]);
              if (value.includes(",")) {
                return `"${value}"`;
              } else {
                return value;
              }
            })
            .join(",");
        })
      )
      .join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  };

  const handleDownload = () => {
    generateCSV(columns, ratingList, "ratingReport.csv");
  };

  const editDetails = () => {
    dispatch(updateRating({ editData }));
    setSubmit(true);
  };

  const clearAll = () => {
    setEditData({ status: "" });
    setSubmit(false);
    setEditModal(false);
    dispatch(getRating());
    dispatch(clearError());
    form.resetFields();
  };

  useEffect(() => {
    if (message && !error && submit) {
      clearAll();
    } else {
      setSubmit(false);
    } // eslint-disable-next-line
  }, [message, error]);
  
  return (
    <div>
      <br />
      <div style={{ color: "black", fontSize: "35px", textAlign: "center" }}>
        Review & Rating Report
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: "20px",
        }}
      >
        <div>
          <Space.Compact>
            <Input
              prefix={<SearchOutlined />}
              placeholder=" Search here..."
              onChange={(e) => {
                const searchTerm = e.target.value.toLowerCase();
                const filterData = ratingList.filter((record) => {
                  return Object.values(record).some((value) =>
                    String(value).toLowerCase().includes(searchTerm)
                  );
                });

                setFilterRatingList(filterData);
              }}
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                width: "80%",
              }}
            />
          </Space.Compact>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <DatePicker
            label="Start Date"
            value={filter.startDate}
            onChange={(newValue) => {
              setFilter({ ...filter, startDate: newValue });
            }}
            renderInput={(params) => <Input {...params} />}
          />{" "}
          <DatePicker
            style={{ fontSize: "10px" }}
            label="End Date"
            value={filter.endDate}
            onChange={(newValue) => {
              setFilter({ ...filter, endDate: newValue });
            }}
            renderInput={(params) => <Input {...params} />}
          />
          <Button
            style={{
              width: "150px",
              background: "lightblue",
              marginLeft: "10px",
              height: "40px",
            }}
            onClick={() => goFilter()}
          >
            Search
          </Button>
        </div>
        <div>
          <p
            style={{ textDecoration: "underline", color: "gray" }}
            onClick={handleDownload}
          >
            Download Report
          </p>
        </div>
      </div>
      <div className="table-container">
        <Table
          className="management-table"
          columns={columns}
          dataSource={filteredRatingList}
        />
      </div>

      <Modal
        title={
          <div style={{ fontSize: "25px" }}>Update Rating & Review Status</div>
        }
        open={editModal}
        closable={true}
        onOk={() => editDetails()}
        onCancel={() => {
          clearAll();
          setViewModal(false);
        }}
      >
        <Form form={form} name="create_codebatch">
          <div style={{ textAlign: "right", marginTop: "5%" }}>
            <Form.Item label="Rating">
              <Input style={{ width: "300px" }} value={editData?.rating} />
            </Form.Item>
            <Form.Item label="Review">
              <Input style={{ width: "300px" }} value={editData?.review} />
            </Form.Item>
            <Form.Item label="Status">
              {/* <Input
                                required
                                style={{ width: '300px' }}
                                value = {editData?.status}
                                onChange = {(e) => setEditData({...editData, status: e.target.value })}
                            /> */}
              <Select
                style={{ width: "300px", textAlign: "left" }}
                value={editData?.status}
                onChange={(value) =>
                  setEditData({ ...editData, status: value })
                }
                className="admin-portal-dropdown"
              >
                <Select.Option value="PENDING">PENDING</Select.Option>
                <Select.Option value="APPROVED">APPROVED</Select.Option>
                <Select.Option value="REJECTED">REJECTED</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div>
            {message && submit ? (
              <div className="success-modal-msg"> {message}</div>
            ) : (
              <div className="error-modal-msg">{error}</div>
            )}
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default RatingReport;
