import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import axios from "axios";
import { Table, Tag, Modal, Form, Input, Button, Select } from "antd";
import {
  clearError,
  getVoucher,
  createVoucherType,
  uploadVoucherCSV,
  createVoucherBatch,
} from "../../../reducer/adminReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import { UploadOutlined } from "@ant-design/icons";
import { adminTokenConfig } from "../../../reducer/helperFunc";
const { Option } = Select;

const VoucherManagement = () => {
  const [form] = Form.useForm();
  const [newData, setNewData] = useState({
    productId: "",
    name: "",
    value: 0,
    points: 0,
    type: "",
  });
  const [typeData, setTypeData] = useState({});
  const [newModal, setNewModal] = useState(false);
  const [csvModal, setCSVModal] = useState(false);
  const [batchData, setBatchData] = useState([]);
  const [codeData, setCodeData] = useState([]);
  const uploadImage = useRef();
  const uploadcsv = useRef();
  const [viewModal, setViewModal] = useState();
  const [viewCodeModal, setViewCodeModal] = useState();
  const [voucherTypeId, setVoucherTypeId] = useState("");
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [submit, setSubmit] = useState(false);
  const [imageName, setImageName] = useState("");
  const { voucherList, message, error, voucherCodes, duplicateCodes } =
    useSelector((state) => state.admin);
  let columnCSV = [
    {
      label:
        "Voucher Code (Please insert the voucher codes in this column only)",
    },
  ];

  useEffect(() => {
    dispatch(getVoucher());
    // eslint-disable-next-line
  }, []);

  const openView = (rowId) => {
    let rowData = voucherList.filter((i) => i.id === rowId)[0];
    if (rowData) {
      setVoucherTypeId(rowId);
      setTypeData(rowData);
      setBatchData(rowData.voucherBatches);
      setViewModal(true);
    }
  };

  const openViewCode = (cdata) => {
    setCodeData(cdata);
    setViewCodeModal(true);
  };

  const addNew = () => {
    const formData = new FormData();
    const config = adminTokenConfig(true);
    formData.append("file", image);
    formData.append("sendData", JSON.stringify(newData));
    dispatch(createVoucherType(formData, config));
    setSubmit(true);
  };

  const handleImage = (e) => {
    let inputFile = false;
    const tempFile = e.target.files[0];
    if (tempFile) {
      inputFile = true;
    }
    if (inputFile) {
      if (
        tempFile.type === "image/png" ||
        tempFile.type === "image/jpg" ||
        tempFile.type === "image/jpeg"
      ) {
        setImage(tempFile);
        setImageName(tempFile.name);
      } else {
        errorInfo();
      }
    }
    // setImage(tempFile);
    // setImageName(tempFile.name);
  };

  const handleFile = (e) => {
    const csvFile = e.target.files[0];
    // if (file.type === "text/csv") {
    const formData = new FormData();
    formData.append("file", csvFile);
    dispatch(uploadVoucherCSV(formData, "multipart/form-data"));
    setCSVModal(true);
    setViewModal(false);
  };

  const handleConfirm = () => {
    dispatch(createVoucherBatch({ id: voucherTypeId, codes: voucherCodes }));
    dispatch(getVoucher());
    setCSVModal(false);
  };

  const errorInfo = () => {
    Modal.info({
      title: "Upload Failed.",
      content: (
        <div>
          <p>Please Upload Image Files only.</p>
        </div>
      ),
      onOk() {},
    });
  };

  const clearAll = () => {
    setNewData({ productId: "", name: "", value: 0, points: 0, type: "" });
    setNewModal(false);
    setViewModal(false);
    setVoucherTypeId("");
    setSubmit(false);
    dispatch(getVoucher());
    dispatch(clearError());
    form.resetFields();
  };

  useEffect(() => {
    if (message && !error && submit) {
      clearAll();
    } else {
      setSubmit(false);
    } // eslint-disable-next-line
  }, [message, error]);

  const columns = [
    {
      title: "Product ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Voucher Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Voucher Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      align: "center",
    },
    {
      title: "Total Vouchers",
      dataIndex: "total",
      key: "totalVoucher",
      align: "center",
    },
    {
      title: "Available Vouchers",
      dataIndex: "available",
      key: "available",
      align: "center",
    },
    {
      title: "Redeemed Vouchers",
      dataIndex: "redeemed",
      key: "redeemed",
      align: "center",
    },
    {
      title: "Voucher Image",
      dataIndex: "image",
      key: "image",
      label: "Voucher Image",
      align: "center",
      render: (im) => {
        return <img src={im} alt="" width="100vw" />;
      },
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",

      render: (id) => {
        return (
          <div key={id}>
            <Tag onClick={() => openView(id)} color="blue">
              Update
            </Tag>
          </div>
        );
      },
    },
  ];

  const columnsViewBatch = [
    {
      title: "BatchId",
      dataIndex: "id",
      key: "id",
      label: "Batch ID",
    },
    {
      title: "Upload Date",
      dataIndex: "createdAt",
      key: "createdAt",
      label: "Upload Date",
      render: (date) => {
        return date ? moment(date).format("DD-MM-YYYY hh:mm") : "-";
      },
    },
    {
      title: "Total Codes",
      dataIndex: "vouchers",
      key: "vouchers",
      label: "Number of Codes",
      render: (code) => {
        return code.length;
      },
    },
    {
      title: "Redeemed Codes",
      dataIndex: "vouchers",
      key: "vouchers",
      label: "Redeemed Code",
      render: (code) => {
        return code.filter((c) => c.redeemed === true).length;
      },
    },
    {
      title: "Action",
      dataIndex: "vouchers",
      key: "vouchers",
      render: (vouchers, i) => {
        return (
          <div key={i}>
            <Tag onClick={() => openViewCode(vouchers)} color="blue">
              View
            </Tag>
            <Tag color="blue">
              <CSVLink
                data={vouchers}
                filename={"code.csv"}
                headers={columnsViewCode}
              >
                Download
              </CSVLink>
            </Tag>
          </div>
        );
      },
    },
  ];

  const columnsViewCode = [
    {
      title: "Voucher Code",
      dataIndex: "code",
      key: "code",
      label: "Voucher Code",
    },
    {
      title: "Status",
      dataIndex: "redeemed",
      key: "redeemed",
      label: "Status",
      render: (st) => {
        return st ? "Redeemed" : "Available";
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      label: "Created Date",
      render: (date) => {
        return date ? moment(date).format("DD-MM-YYYY hh:mm") : "-";
      },
    },
    {
      title: "Username",
      dataIndex: "name",
      key: "name",
      label: "Username",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      label: "Email Address",
    },
  ];

  // console.log(voucherList);

  return (
    <div>
      <br />
      <div style={{ color: "black", fontSize: "35px", textAlign: "center" }}>
        Voucher Management
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: "20px",
        }}
      >
        <Button
          style={{ width: "200px", background: "lightblue", marginTop: "2%" }}
          onClick={() => setNewModal(true)}
        >
          Add Voucher Type
        </Button>
        <Button
          style={{
            width: "200px",
            background: "white",
            marginTop: "2%",
            marginLeft: "2%",
          }}
          onClick={() => clearAll()}
        >
          Refresh
        </Button>
      </div>

      <div className="table-container">
        <Table
          className="management-table"
          columns={columns}
          dataSource={voucherList}
        />
      </div>
      <Modal
        title={<div style={{ fontSize: "25px" }}>Create New Voucher Type</div>}
        open={newModal}
        onOk={() => addNew()}
        onCancel={() => {
          clearAll();
          setNewModal(false);
        }}
      >
        <Form form={form} name="create_codebatch">
          <div style={{ textAlign: "right", marginTop: "5%" }}>
            <Form.Item label="Product ID">
              <Input
                required
                style={{ width: "250px" }}
                value={newData.productId}
                onChange={(e) =>
                  setNewData({ ...newData, productId: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Voucher Name">
              <Input
                required
                style={{ width: "250px" }}
                value={newData.name}
                onChange={(e) =>
                  setNewData({ ...newData, name: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Voucher Type">
              <Select
                placeholder="Voucher Type"
                style={{ width: "250px" }}
                onChange={(e) => setNewData({ ...newData, type: e })}
                className="admin-portal-dropdown"
              >
                <Option value="TNG">TNG</Option>
                <Option value="GRAB">GRAB</Option>
                <Option value="SHOPEE">SHOPEE</Option>
                <Option value="BARCODE">BARCODE</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Voucher Value">
              <Input
                type="number"
                required
                style={{ width: "250px" }}
                value={newData.value}
                onChange={(e) =>
                  setNewData({ ...newData, value: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Points for Redemption">
              <Input
                type="number"
                required
                style={{ width: "250px" }}
                value={newData.points}
                onChange={(e) =>
                  setNewData({ ...newData, points: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Image">
              <div
                onClick={() => uploadImage.current.click()}
                style={{ textAlign: "center", marginLeft: "25%" }}
              >
                <input
                  onChange={handleImage}
                  type="file"
                  ref={uploadImage}
                  hidden
                  style={{ opacity: "0" }}
                />
                {imageName ? (
                  <>
                    <h4>{imageName} </h4>
                  </>
                ) : (
                  <div>
                    <Button
                      style={{ border: "none" }}
                      icon={<UploadOutlined />}
                      type="submit"
                    >
                      Upload Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
          </div>
          <div>
            {message && submit ? (
              <div className="success-modal-msg"> {message}</div>
            ) : (
              <div className="error-modal-msg">{error}</div>
            )}
          </div>
        </Form>
      </Modal>
      <Modal
        title={<div style={{ fontSize: "25px" }}>Update Voucher Batch</div>}
        open={viewModal}
        closable={true}
        footer={null}
        onCancel={() => {
          clearAll();
          setViewModal(false);
        }}
      >
        <Form form={form} name="create_codebatch">
          <div style={{ textAlign: "right", marginTop: "5%" }}>
            <Form.Item label="Product ID">
              <Input
                disabled
                style={{ width: "250px" }}
                value={typeData.productId}
              />
            </Form.Item>
            <Form.Item label="Voucher Name">
              <Input
                disabled
                style={{ width: "250px" }}
                value={typeData.name}
              />
            </Form.Item>
            <Form.Item label="Voucher Type">
              <Input
                disabled
                style={{ width: "250px" }}
                value={typeData.type}
              />
            </Form.Item>
            <Form.Item label="Voucher Value">
              <Input
                disabled
                style={{ width: "250px" }}
                value={typeData.value}
              />
            </Form.Item>
            <Form.Item label="Points for Redemption">
              <Input
                disabled
                style={{ width: "250px" }}
                value={typeData.points}
              />
            </Form.Item>
            <div
              onClick={() => uploadcsv.current.click()}
              style={{ textAlign: "center" }}
            >
              <input
                onChange={handleFile}
                type="file"
                ref={uploadcsv}
                hidden
                style={{ opacity: "0" }}
              />
              <div>
                <Button
                  style={{ width: "250px", background: "lightblue" }}
                  type="submit"
                >
                  Upload CSV
                </Button>
              </div>
            </div>
            <div
              style={{ textDecoration: "underline", textAlign: "center" }}
              onClick={() => {}}
            >
              <CSVLink data={[]} filename={"code.csv"} headers={columnCSV}>
                Download CSV Template
              </CSVLink>
            </div>
          </div>

          <div>
            {message && submit ? (
              <div className="success-modal-msg"> {message}</div>
            ) : (
              <div className="error-modal-msg">{error}</div>
            )}
          </div>
        </Form>
        <Table
          className="management-table"
          columns={columnsViewBatch}
          dataSource={batchData}
        />
      </Modal>
      <Modal
        title={<div style={{ fontSize: "25px" }}>View Voucher Code</div>}
        open={viewCodeModal}
        closable={true}
        footer={null}
        onCancel={() => {
          setViewCodeModal(false);
        }}
      >
        <Table
          className="management-table"
          columns={columnsViewCode}
          dataSource={codeData}
        />
      </Modal>
      <Modal
        title={<div style={{ fontSize: "25px" }}>Confirm Vouchers Upload</div>}
        open={csvModal}
        footer={null}
      >
        <div>
          <div style={{ fontSize: "20px" }}>
            Success :{" "}
            <strong style={{ color: "green" }}>
              {voucherCodes.length - duplicateCodes.length}{" "}
            </strong>{" "}
            Error :{" "}
            <strong style={{ color: "red" }}>{duplicateCodes.length}</strong>
          </div>
          {/* <Table
                            className="management-table"
                            columns={[{
                                title: 'Code',
                                dataIndex: 'code',
                                key: 'code',
                            }, {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                            }
                            ]}
                            dataSource={voucherCodes}
                        /> */}
          <Table
            className="management-table"
            columns={[
              {
                title: "Code",
                dataIndex: "code",
                key: "code",
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
              },
            ]}
            dataSource={voucherCodes}
          />
        </div>
        <center>
          {" "}
          <Button
            style={{
              width: "200px",
              background: "white",
            }}
            onClick={() => {
              clearAll();
              setCSVModal(false);
            }}
          >
            Cancel
          </Button>
          {error || (duplicateCodes && duplicateCodes.length) ? (
            ""
          ) : (
            <Button
              style={{
                width: "200px",
                background: "white",
                marginTop: "2%",
                marginLeft: "2%",
              }}
              onClick={() => handleConfirm()}
            >
              Submit
            </Button>
          )}
        </center>
        <div>
          {message && submit ? (
            <div className="success-modal-msg"> {message}</div>
          ) : (
            <div className="error-modal-msg">{error}</div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default VoucherManagement;
