import { Button, Modal } from 'antd';
import React from 'react';

import "../../css/Home.css";
import soon from "../../images/Haleon/soon.png";

const ComingSoon = ({isSoon, setIsSoon}) => {
    return (
        <div>
        <Modal
            open={isSoon}
            centered
            header={null}
            footer={null}
            closable={false}
            width={330}
          >
            <div className="text-center"> 
              <img style={{ width: '30%' }} src={soon} alt="" className="submit-success-icon"></img>
              <h2>Coming Soon!</h2>
              <div className="submit-pending-modal-font mt-1">
              <p className='fontSize-14 text-center'>
                This function will be available soon!           
              </p>  
              </div>
              <Button
                className="coming-soon-button mt-1"
                onClick={() => setIsSoon(false)}>
                Sounds Good!
              </Button>
            </div>
          </Modal>
        </div>
    )
}

export default ComingSoon;
