import '../../css/Login.css';

import React, { useState } from 'react';

import LoginForm from '../../components/forms/LoginForm';
import haleonLogo from '../../images/Haleon/HaleonLogo.svg';
import img1 from '../../images/Haleon/happyFamily.png';
// import { useDispatch } from 'react-redux';
// import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginPage = ({t}) => {
	return (
		<div className="text-center">
			<div className="logo-position">
				<img src={haleonLogo} alt="haleon" style={{ width: '30%' }} />
			</div>
			<img src={img1} alt="pic" className="w-100" />
			<div className="layout-position">
				<div className="login-card" style={{ textAlign: 'center' }}>
					<br />

					<h1 className='mt-2'>{t('Get Started')}!</h1>
					<div className="mt-1"></div>
					<hr
						style={{
							backgroundColor: '#30EA03',
							height: '3px',
							width: '40%',
							marginTop: '5px',
							border: 'none',
							margin: 'auto',
						}}
					/>
					<p className="mt-2 text-center">
						{t('Step into the world of exciting rewards now')}.
					</p>
					<LoginForm
						formConfig={{
							label: 'Phone Number',
							placeholder: 'Enter Your Phone Number',
							layout: 'vertical',
							btnText: 'Log In / Sign Up',
						}}
					/>
				</div>
				{/* <Modal
                open={false}
                centered
                footer={null}
                className="login-age-modal"
                closable={false} >
               <div className="login-error-img-container">
             <img src={errorImg} alt="" style={{ width: '20vw' }}></img>
                </div>
                 <br />
               <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    Invalid QR Code, please rescan a validate QR again
                </div>
             </Modal> */}
			</div>
		</div>
	);
};

export default LoginPage;
