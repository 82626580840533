import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Form, Input, Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import successImg from '../../images/Haleon/checkmark.png';
import SubHeader from '../../components/SubHeader';
import './profile.css';
import axiosClient from '../../utils/axiosClient';

const PersonalDetails = ({ t }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [submit, setSubmit] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorEmail, setErrorEmail] = useState(' ');
	const [errorName, setErrorName] = useState(' ');
	const [errorNumber, setErrorNumber] = useState(' ');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [number, setNumber] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [errorDob, setErrorDob] = useState('');
	const [error, setError] = useState();
	const [userData, setUserData] = useState();

	const onFinish = async (values) => {
		try {
			const res = await axiosClient.post('/profile/update', {
				values: { ...values },
			});
			setSubmit(true);
			if (res) {
				setIsSuccess(true);
			}
		} catch (err) {
			console.log('error', err);
		}
	};

	useEffect(() => {
		const checkUserInfo = async () => {
			try {
				const res = await axiosClient.get('/users/details');
				setUserData(res.data.data.personalInfo);
				// console.log(res);
			} catch (error) {
				setError(error.res.data.error);
				console.log('error', error);
			}
		};
		checkUserInfo();
	}, []);

	useEffect(() => {
		if (userData) {
			form.setFieldsValue({
				name: userData?.name,
				email: userData?.email,
				phone: userData?.phone,
				dob: userData?.dob ? moment(userData?.dob).format('yyyy-MM') : null,
			});
		}
	}, [userData, form]);

	const isName = (e) => {
		var regex = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
		// /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
		setName(e.target.value);
		if (!regex.test(e.target.value)) {
			setErrorName('Invalid name format');
		} else {
			setErrorName('');
		}
		if (!e.target.value) {
			setErrorName('');
		}
	};


	const isEmail = (e) => {
		setEmail(e.target.value);

		// eslint-disable-next-line no-useless-escape
		let regEmail = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
		if (!regEmail.test(e.target.value)) {
			setErrorEmail('Invalid email format');
		} else {
			setErrorEmail('');
		}
		if (!e.target.value) {
			setErrorEmail('');
		}
	};

	return (
		<div>
			<SubHeader setPreviousPage={'/profile'} />
			<div className="mt-1">
				<hr />
			</div>

			<div className="ml-2 mr-2">
				<div className="mt-2">
					<p style={{ fontWeight: '500', fontSize: '25px' }}>
						{t('Personal Details')}
					</p>
				</div>

				<div>
					<div className="mx-5">
						<Form
							layout="vertical"
							name="register"
							form={form}
							onFinish={onFinish}
							autoComplete="off"
						>
							<div className="mt-2">
								<Form.Item
									name="name"
									label={
										<label className="form-item-label">
											{' '}
											{t('Full Name')}
										</label>
									}
									rules={[
										{
											// required: true,
											message: t('inputName'),
										},
									]}
								>
									<Input
										type="text"
										placeholder={t('fullName')}
										onChange={isName}
									/>
								</Form.Item>
								{errorName ? (
									<>
										{' '}
										<div
											style={{
												color: 'red',
												marginTop: '-5%',
												textAlign: 'left',
											}}
										>
											{errorName}
										</div>
										<br />
									</>
								) : null}

								<Form.Item
									name="email"
									label={
										<label className="form-item-label">
											{t('Email Address')}
										</label>
									}
									rules={[
										{
											// required: true,
											message: t('inputEmail'),
										},
									]}
									style={{ color: 'red' }}
								>
									<Input
										type="email"
										placeholder="Enter email Address"
										onChange={isEmail}
									/>
								</Form.Item>
								{errorEmail ? (
									<>
										{' '}
										<div
											style={{
												color: 'red',
												marginTop: '-5%',
												textAlign: 'left',
											}}
										>
											{errorEmail}
										</div>
										<br />
									</>
								) : null}
								<Form.Item
									name="phone"
									label={
										<label className="form-item-label">
											{' '}
											{t('Mobile Number')}
										</label>
									}
								>
									<Input
										type="number"
										placeholder="Enter your phone number"
										// onChange={isNumber}
										disabled
									/>
								</Form.Item>
								{errorNumber ? (
									<>
										{' '}
										<div
											style={{
												color: 'red',
												marginTop: '-5%',
												textAlign: 'left',
											}}
										>
											{errorNumber}
										</div>
										<br />
									</>
								) : null}

								<Form.Item
									name="dob"
									label={
										<label className="form-item-label">{t('Month of Birth')}</label>
									}
								>
									<Input type="month" style={{ width: '100%' }} />
								</Form.Item>
							</div>
							<div style={{ height: '25vh' }}></div>
							{errorMsg ? (
								<p
									style={{
										color: 'red',
										fontSize: '12px',
										textAlign: 'center',
									}}
								>
									{errorMsg}
								</p>
							) : null}
							<Form.Item className="mt-2">
								<Button
									// loading={loading}
									htmlType="submit"
									className="save-btn"
								>
									{t('Save')}
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>

				<div>
					<Modal
						open={isSuccess}
						centered
						header={null}
						footer={null}
						onCancel={() => setIsSuccess(false)}
						closable={false}
						width={300}
					>
						<div className="text-center">
							<img
								style={{ width: '20%' }}
								src={successImg}
								alt=""
								className="submit-success-icon"
							></img>
							<div className="update-details-success-header">{t('Success')}!</div>
							<div className="mt-1 w-100">
								<p className="text-center">
									{t('Your details have been successfully updated')}.
								</p>
							</div>
							<Button
								className="receipt-success-button mt-1"
								style={{ backgroundColor: '#02BC7D' }}
								onClick={() => navigate('/profile')}
							>
								{t('Done')}
							</Button>
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
};

export default PersonalDetails;
