import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import watsons_logo from '../../../../images/Haleon/watsons-logo.png';

const HowToRedeemWatsons = ({ open, onClose }) => { 
	const { t } = useTranslation('translation', {
		keyPrefix: 'rewards.modal.watsons',
	  });
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');
	return (
		<Modal open={open} centered footer={null} closable={false} width={350}>
			<div className="text-center">
				<img className='mt-2' style={{ width: '50%' }} src={watsons_logo} alt="" />
				<div className="mt-1" style={{ lineHeight: '25px' }}>
					<h3
						style={{
							fontSize: '20px',
							width: 'auto',
						}}
					>
						{getLanguage === 'bm' ? (
							<>Cara Menebus PIN Tambah Nilai Touch ‘n Go eWallet Anda?</>
						) : getLanguage === 'cn' ? (
							<>如何兑换您的 Touch 'n Go eWallet 充值码？</>
						) : (
							<>
								How to Redeem Your <br />
								Watsons Voucher? 
							</>
						)}
					</h3>
				</div>
				<div className="mt-2 " style={{
					textAlign:"justify"
				}}>
					 
						 
							{t('how_to')}
				 
				</div>
				<Button
					type="primary"
					className="mt-1"
					onClick={() => onClose()}
					style={{
						textTransform:"uppercase",
						fontSize: '12px',
						borderRadius: '8px',
						background: '#CB0E07',
						color: 'white',
						border: 'none',
						fontWeight: '500',
						height: '40px',
						width: '90%',
					}}
				>
					{t('close')}
				</Button>
			</div>
		</Modal>
	);
};

export default HowToRedeemWatsons;
