import React, { useEffect, useState } from 'react';
import '../../css/Badges.css';
import SubHeader from '../../components/SubHeader';
import i18n from '../../utilities/translation';
import icon from '../../images/Haleon/Icon.png';

const Language = ({t}) => {
	const [lang, setLang] = useState('');
	const languages = [
		{ name: 'English', code: 'en' },
		{ name: 'Bahasa Melayu', code: 'bm' },
		{ name: '中文', code: 'cn' },
	];

	useEffect(() => {
		const storedLangCode = localStorage.getItem('HALEON-REWARDS_LANG');
		const foundLang = languages.find((lang) => lang.code === storedLangCode);
		if (foundLang) {
			setLang(foundLang.name);
			i18n.changeLanguage(storedLangCode);
		}
	}, []);

	const handleLangChange = (selectedLang) => {
		let { name, code } = selectedLang;
		setLang(name);
		i18n.changeLanguage(code);
		localStorage.setItem('HALEON-REWARDS_LANG', code);
	};

	return (
		<div>
			<SubHeader setPreviousPage={-1} />

			<div className="mt-1">
				<hr />
			</div>

			<div className="ml-3 mr-3 mt-2">
				<div>
					<div className="mt-3">
						<p style={{ fontSize: '25px', fontWeight: '500' }}>{t('Language')}</p>
					</div>
					<div className="mt-3">
						{languages.map((item, index) => (
							<>
								<div
									key={index}
									onClick={() => handleLangChange(item)}
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										padding: '10px',
										paddingRight: '2%',
										paddingLeft: '1%',
										marginBottom: '5px',
										cursor: 'pointer',
									}}
								>
									<p style={{ fontSize: '18px', fontWeight: 'none' }}>
										{item.name}
									</p>
									{lang === item.name && (
										<img
											src={icon}
											alt="checkmark"
											style={{ width: 'auto', objectFit: 'contain' }}
										/>
									)}
								</div>
								{index !== 2 && <hr />}
							</>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Language;
