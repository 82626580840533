import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
let language = localStorage.getItem('HALEON-REWARDS_LANG');
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
	en: {
		translation: {
			'Welcome To': 'Welcome To',
			'Get Started': 'Get Started',
			'How It Works': 'How It Works',
			'Purchase your favorite Haleon participating products from participating retail outlets, pharmacies, or e-stores':
				'Purchase your favorite Haleon participating products from participating retail outlets, pharmacies, or e-stores',
			'Snap and upload a photo of your physical receipt or take a screenshot of the e-receipt from your online purchase':
				'Snap and upload a photo of your physical receipt or take a screenshot of the e-receipt from your online purchase',
			'Brace yourself as you unlock a world of exciting rewards using your accumulated points! Start indulging in a plethora of enticing rewards today':
				'Brace yourself as you unlock a world of exciting rewards using your accumulated points! Start indulging in a plethora of enticing rewards today',
			'Step into the world of exciting rewards now':
				'Step into the world of exciting rewards now',
			'Send OTP Code': 'Send OTP Code',
			'OTP Verification': 'OTP Verification',
			Verify: 'Verify',
			'Resend Code': 'Resend Code',
			'Your phone number has been successfully verified.':
				'Your phone number has been successfully verified.',
			Registration: 'Registration',
			'Please complete the form to proceed':
				'Please complete the form to proceed',
			'Referral Code/Store Code/Promoter Code':
				'Referral Code/Store Code/Promoter Code',
			Submit: 'Submit',
			Points: 'Points',
			Rejected: 'Rejected',
			Approved: 'Approved',
			Pending: 'Pending',
			'Visit Haleon Online Store': 'Visit Haleon Online Store',
			"What's New": "What's New",
			'Our Star Brands': 'Our Star Brands',
			'Popular Rewards': 'Popular Rewards',
			'View All': 'View All',
			'Welcome Reward': 'Welcome Reward',
			Claim: 'Claim',
			userBronze: 'Bronze',
			userSilver: 'Silver',
			userGold: 'Gold',
			userPlatinum: 'Platinum',
			Upload: 'Upload',
			Purchase: 'Purchase',
			Earn: 'Earn',
			'Redeem Rewards': 'Redeem Rewards',
			'Convert your points into rewards': 'Convert your points into rewards',
			'Sounds Good': 'Sounds Good',
			Submissions: 'Submissions',
			'Filter By': 'Filter By',
			'Sample Receipt': 'Sample Receipt',
			or: 'or',
			// here onwards
			'Point Shop': 'Point Shop',
			'My Rewards': 'My Rewards',
			'How to Redeem?': 'How to Redeem?',
			'Terms & Conditions': 'Terms & Conditions',
			'Digital Rewards': 'Digital Rewards',
			'Only ONE (1) Reload PIN Reward can be used per reload.':
				'Only ONE (1) Reload PIN Reward can be used per reload.',
			"To utilize the Reload Pin, log in on the microsite. At 'Rewards', tap on the 'View Now' button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.":
				"To utilize the Reload Pin, log in on the microsite. At 'Rewards', tap on the 'View Now' button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.",
			'Used Reload PIN Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.':
				'Used Reload PIN Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.',
			'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.':
				'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.',
			'Reload Pin will be expired on 31st December 2024.':
				'Reload Pin will be expired on 31st December 2024.',
			DONE: 'DONE',
			'Open the Touch ‘n Go eWallet Reload PIN app.':
				'Open the Touch ‘n Go eWallet Reload PIN app.',
			'Click ‘Reload’.': 'Click ‘Reload’.',
			'Click ‘eWallet Balance’.': 'Click ‘eWallet Balance’.',
			'Click on ‘Touch ‘n Go eWallet Reload PIN at the bottom of the page’.':
				'Click on ‘Touch ‘n Go eWallet Reload PIN at the bottom of the page’.',
			'Key in the Touch ‘n Go eWallet Reload PIN and click ‘Reload Now’.':
				'Key in the Touch ‘n Go eWallet Reload PIN and click ‘Reload Now’.',
			'Successfully redeem Touch ‘n Go eWallet Reload PIN.':
				'Successfully redeem Touch ‘n Go eWallet Reload PIN.',
			'How to Redeem Your GrabGift?': 'How to Redeem Your GrabGift?',
			'Open the Grab app and choose the “Gifts” Tab.':
				'Open the Grab app and choose the “Gifts” Tab.',
			'Click the Gifts icon at the upper right corner':
				'Click the Gifts icon at the upper right corner',
			'Key-in your Gift Code': 'Key-in your Gift Code',
			'Click Pick Your Gift, then proceed with saving the voucher':
				'Click Pick Your Gift, then proceed with saving the voucher',
			'Choose the gift': 'Choose the gift',
			'Your saved voucher will be stored in “My Rewards”':
				'Your saved voucher will be stored in “My Rewards”',
			'Terms & Conditions': 'Terms & Conditions',
			'Users must enter promo code at checkout to be entitled to the discount.':
				'Users must enter promo code at checkout to be entitled to the discount.',
			'This promo code is valid for (1) ONE order from services on GrabFood, GrabRide, GrabExpress or GrabMart.':
				'This promo code is valid for (1) ONE order from services on GrabFood, GrabRide, GrabExpress or GrabMart.',
			'This promo code will be expired on 31 December 2024':
				'This promo code will be expired on 31 December 2024',
			'Promotion is not valid with any other vouchers or promotions.':
				'Promotion is not valid with any other vouchers or promotions.',
			'Grab reserves the right to alter, extend or terminate the promotion, or amend the terms and conditions at its sole discretion at any time without prior notice. In case of any disputes directly or indirectly arising from the promotion, the decision of Grab shall be final.':
				'Grab reserves the right to alter, extend or terminate the promotion, or amend the terms and conditions at its sole discretion at any time without prior notice. In case of any disputes directly or indirectly arising from the promotion, the decision of Grab shall be final.',
			'These terms and conditions shall be governed by the laws of Malaysia and any dispute arising out of or in connection with promotion shall be referred to the exclusive jurisdiction of courts of Malaysia.':
				'These terms and conditions shall be governed by the laws of Malaysia and any dispute arising out of or in connection with promotion shall be referred to the exclusive jurisdiction of courts of Malaysia.',
			'How to Redeem Your SPAY Voucher?': 'How to Redeem Your SPAY Voucher?',
			'Click on Loyalty Rewards Icon': 'Click on Loyalty Rewards Icon',
			'Click on Redeem': 'Click on Redeem',
			'Key in the redemption code': 'Key in the redemption code',
			'Key in the verification code': 'Key in the verification code',
			'Click on Get Voucher Now': 'Click on Get Voucher Now',
			'Upon successful redemption, the voucher with its value will appear':
				'Upon successful redemption, the voucher with its value will appear',
			'The vouchers will be delivered to the Company in the form of Voucher Redemption Codes which can be input into user SPayGlobal app to redeem vouchers':
				'The vouchers will be delivered to the Company in the form of Voucher Redemption Codes which can be input into user SPayGlobal app to redeem vouchers',
			'Voucher received by user will be displayed inside SPayGlobal’s Loyalty Rewards.':
				'Voucher received by user will be displayed inside SPayGlobal’s Loyalty Rewards.',
			'To use the voucher, the minimum spend must be above the value of the voucher.':
				'To use the voucher, the minimum spend must be above the value of the voucher.',
			'There may be specific restrictions on the redemption and the use of the voucher which shall be displayed on the voucher, e.g., redemption rules such as limit to one voucher per user and usage rules such as maximum of two vouchers used per day.':
				'There may be specific restrictions on the redemption and the use of the voucher which shall be displayed on the voucher, e.g., redemption rules such as limit to one voucher per user and usage rules such as maximum of two vouchers used per day.',
			'SNT reserves the right and is at its absolute discretion to modify, withdraw, suspend, extend, or terminate this service wholly or in part, at any time.':
				'SNT reserves the right and is at its absolute discretion to modify, withdraw, suspend, extend, or terminate this service wholly or in part, at any time.',
			'Gaming, manipulating the original intention of this service in any form is prohibited. SNT reserves the right to suspend/terminate any user if found to participate in such activities.':
				'Gaming, manipulating the original intention of this service in any form is prohibited. SNT reserves the right to suspend/terminate any user if found to participate in such activities.',
			'SNT’s decision on this service shall be final and conclusive. SNT shall not be obliged to give any reason or enter into any correspondence with any person on any matter concerning this service. No appeal and/or correspondence from any user or any third party will be entertained.':
				'SNT’s decision on this service shall be final and conclusive. SNT shall not be obliged to give any reason or enter into any correspondence with any person on any matter concerning this service. No appeal and/or correspondence from any user or any third party will be entertained.',
			'Your receipt has been successfully uploaded and will be validated within 3 working days.':
				'Your receipt has been successfully uploaded and will be validated within 3 working days.',
			//Profile page
			'Personal Details': 'Personal Details',
			'Delivery Address': 'Delivery Address',
			Language: 'Language',
			Tiering: 'Tiering',
			Badges: 'Badges',
			Help: 'Help',
			'Log Out': 'Log Out',
			'Refer A Friend': 'Refer A friend',
			'Contact Preference': 'Contact Preference',

			// Personal Details
			'Full Name': 'Full Name',
			'Email Address': 'Email Address',
			'Mobile Number': 'Mobile Number',
			'Month of Birth': 'Month of Birth',
			Success: 'Success',
			Save: 'Save',
			'Your details have been successfully updated':
				'Your details have been successfully updated',
			Done: 'Done',

			// Refer a friend page
			'Refer a friend': 'Refer a friend',
			'And you can both earn 10 points': 'And you can both earn 10 points',
			'How does it work': 'How does it work',
			'Invite Your Friends': 'Invite Your Friends',
			'Share your referral link': 'Share your referral link',
			'They earn 10 points': 'They earn 10 points',
			'You earn too': 'You earn too',
			'For each referral you get 10 points':
				'For each referral you get 10 points',
			Copy: 'Copy',

			//Tiering page
			'Tiering Benefits': 'Tiering Benefits',
			'View History': 'View History',
			'Tiering History': 'Tiering History',
			'View Benefits': 'View Benefits',
			Bronze: 'Bronze',
			Silver: 'Silver',
			Gold: 'Gold',
			Platinum: 'Platinum',
			'Tiering Points Required': 'Tiering Points Required',
			Privileges: 'Privileges',
			'Welcome Bonus': 'Welcome Bonus',

			// Contact preference page
			'Contact Preferences': 'Contact Preferences',
			'Email Newsletter': 'Email Newsletter',
			'Phone Call': 'Phone Call',
			'SMS / WhatsApp': 'SMS / WhatsApp',
			"rewards.modal.guardian.how_to":[
				"Copy the link and paste it on your browser search bar",
				"Please present the voucher page on your mobile device to the cashier at the time of redemption",
				"No printouts or screenshots will be accepted. The dark mode on the phone has to be switched off"
			  ],
			"rewards.modal.guardian.close":"close",
			"rewards.modal.guardian.tnc_modal.list":[
				"This voucher is valid only within Singapore (excluding airport stores) and its value is equivalent to the Singapore currency.",
				"This voucher will expire at the <1>12 Aug 2024</1> as indicated.",
				"No extension upon expiry. The expiry date is final.",
				"Not refundable and cannot be replaced if lost, stolen or damaged.",
				"Not refundable or exchangeable for cash, and any unused balance will not be refunded.",
				"Not valid for purchase of all lottery products, bill payments, top-up services, Dairy Farm gift vouchers and Qoo10 online shopping payment.",
				"This voucher must be fully utilised in a single transaction and any unconsumed value will be forfeited and is not refundable.",
				"Cold Storage Singapore (1983) Pte Ltd reserves the right to refuse or suspend the redemption of vouchers upon unsuccessful validation at the Points of Sales.",
				"Cold Storage Singapore (1983) Pte Ltd reserves the right to amend the terms and conditions from time to time without prior notice. Consumer advisory – Cold Storage Singapore (1983) Pte Ltd, the holder of the gift Voucher does not require the approval of the Monetary Authority of Singapore. Consumers (users) are advised to read the terms and conditions carefully.",
			],
			"rewards.modal.watsons.how_to":"The e-Voucher code provided will be applicable for instore use only (except Jewel). Please provide the e-voucher to the cashier counter only (not applicable at Self-Checkout Kiosk and at Scan & Go). Not applicable at Atriums, Pop-up stores and Watsons Online.",
			"rewards.modal.watsons.close":"close",
			"rewards.modal.watsons.tnc_modal.list":[
				"The e-Voucher is redeemable only at Watsons physical stores except at Jewel.",
				"The e-Voucher entitles the bearer to redeem products of equivalent value in Singapore only.",
				"The e-Voucher is not redeemable for purchases made via Self-Checkout Kiosk / Scan & Go.",
				"The e-Voucher cannot be used at Pop-up stores, Watsons atrium events & Watsons Online.",
				"Any returned product purchased using this e-voucher can only be exchanged for another product of a lower or equivalent value.",
				"The e-Voucher is not exchangeable for cash and not replaceable if lost under any circumstances.",
				"Extension of e-voucher validity is strictly not allowed.",
				"Watsons reserves the right of final decision to refuse the use of any e-voucher in any cases of dispute.",
				"Watsons reserves the right to amend the terms and conditions without prior notice.",
			],
			"rewards.modal.fairprice.how_to":[
				"Online:",
				'Click "click to redeem".',
				"Shop online at <1>http://fairprice.com.sg</1>",
				"STEP 1 Add vouchers at Checkout Click 'Add' next to the Voucher code",
				"STEP 2 Use your stored e-vouchers Select one or more e-vouchers you would like to use Or Manually enter the e-voucher code by copy and paste or enter your e-voucher code into the 'Enter voucher code' field and proceed to checkout"
			  ],
			"rewards.modal.fairprice.close":"close",
			"rewards.modal.fairprice.tnc_modal.list":[
				"Expressed in Singapore dollars.",
				"Not valid for purchase of lottery products.",
				"Not valid for redemption at Unity Changi Airport.",
				"Not valid for redemption at Cheers and FairPrice Xpress outlets.",
				"Non-exchangeable for cash and/or gift vouchers.",
				"Non-refundable and/or non-exchangeable in event of loss, theft or damage.",
				"Non-refundable for any unused amount/value.",
				"Non-renewable upon expiry. The expiry date is final."
			],
		},
	},
	bm: {
		translation: {
			'Welcome To': 'Selamat Datang ke',
			'Earn points by uploading your Haleon purchase receipts and unlock a world of exclusive benefits and exciting rewards.':
				'Perolehi mata ganjaran dengan memuat naik resit pembelian Haleon anda dan dapatkan manfaat eksklusif serta ganjaran yang menarik.',
			'Get Started': 'Mula Sekarang',
			'How It Works': 'Cara Berfungsi',
			'Purchase your favorite Haleon participating products from participating retail outlets, pharmacies, or e-stores':
				'Beli produk kegemaran Haleon anda dari kedai runcit, farmasi atau e-store yang mengambil bahagian',
			'Snap and upload a photo of your physical receipt or take a screenshot of the e-receipt from your online purchase':
				'Tangkap gambardan muat naik foto resit fizikal anda atau tangkap skrin pembelian dalam talian anda',
			'Brace yourself as you unlock a world of exciting rewards using your accumulated points! Start indulging in a plethora of enticing rewards today':
				'Bersiap sedia untuk menerokai dunia ganjaran yang menggunakan mata ganjaran yang terkumpul! Mula menikmati pelbagai ganjaran yang menarik hari ini',
			'Step into the world of exciting rewards now':
				'Melangkah ke dunia ganjaran yang menarik sekarang',
			'Send OTP Code': 'Hantar Kod OTP',
			'OTP Verification': 'Pengesahan OTP',
			Verify: 'Sahkan',
			'Resend Code': 'Hantar Semula Kod',
			'Your phone number has been successfully verified.':
				'Pendaftaran Berjaya.',
			Registration: 'Pendaftaran',
			'Please complete the form to proceed':
				'Sila lengkapkan borang untuk meneruskan',
			'Referral Code/Store Code/Promoter Code':
				'Kod Rujukan/ Kod Kedai/ Kod Promoter',
			Submit: 'Hantar',
			Email: 'Emel',
			Points: 'Mata ganjaran',
			Rejected: 'Ditolak',
			Approved: 'Diluluskan',
			Pending: 'Dalam Proses',
			'Visit Haleon Online Store': 'Semak Kedai yang Mengambil Bahagian',
			"What's New": 'Berita baru',
			'Our Star Brands': 'Jenama popular kami',
			'Popular Rewards': 'Ganjaran popular',
			'View All': 'Lihat Semua',
			'Welcome Reward': 'Ganjaran Aluan',
			Claim: 'Tebus',
			userBronze: 'Tahap Gangsa',
			userSilver: 'Tahap Perak',
			userGold: 'Tahap Emas',
			userPlatinum: 'Tahap Platinum',
			Upload: 'Muat Naik',
			Purchase: 'Pembelian',
			Earn: 'Peroleh',
			'Redeem Rewards': 'Tebus Ganjaran',
			'Convert your points into rewards':
				'Tukarkan mata ganjaran anda kepada ganjaran yang menarik',
			'Sounds Good': 'Bagus',
			'Purchase description':
				'Beli produk Haleon dari kedai runcit, farmasi atau e-store yang mengambil bahagian.',
			'Upload description':
				'Tangkap gambar dan muat naik foto resit fizikal anda atau tangkap skrin daripada pembelian dalam talian anda.',
			'Earn description':
				'Untuk setiap RM 1 yang dibelanjakan, anda akan menerima 1 mata ganjaran!',
			Home: 'Laman Utama',
			Rewards: 'Ganjaran',
			'Upload Receipt': 'Muat Naik Resit',
			History: 'Rekod',
			Profile: 'Profil',
			Redeem: 'Tebus',
			Claimed: 'Telah dituntut',
			Back: 'Kembali',
			Submissions: 'Penghantaran',
			Amount: 'Jumlah',
			'Filter By': 'Tapis Mengikut',
			Overview: 'Keseluruhan',
			'Last Month': 'Bulan Lalu',
			'Last 3 Months': '3 Bulan Lalu',
			'Last 6 Months': '6 Bulan Lalu',
			'Sample Receipt': 'Contoh resit',
			'Upload your receipt': 'Muat naik gambar resit anda',
			'Receipt Sample': 'Contoh resit',
			'Sample of physical receipt': 'Contoh Resit Kedai Fizikal',
			'Sample of e-commerce receipt': 'Contoh Resit Kedai E-commerce',
			or: 'atau',
			'Delivery Details': 'Maklumat Penghantaran',
			'Edit Delivery Address': 'Edit Alamat Penghantaran',
			'Add New Address': 'Tambah Alamat Baharu',
			'Enter your address for delivery':
				'Masukkan alamat anda untuk penghantaran',
			// here onwards
			'Point Shop': 'Kedai Ganjaran',
			'My Rewards': 'Ganjaran Saya',
			'How to Redeem?': 'Cara menebus?',
			'Terms & Conditions': 'Terma & Syarat',
			'Digital Rewards': 'E-Baucar',
			'Only ONE (1) Reload PIN Reward can be used per reload.':
				'Hanya SATU (1) Ganjaran PIN Tambah Nilai boleh digunakan untuk setiap tambah nilai.',
			"To utilize the Reload Pin, log in on the microsite. At 'Rewards', tap on the 'View Now' button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.":
				"Untuk menggunakan PIN Tambah Nilai, log masuk ke laman web mikro. Pada bahagian 'Ganjaran', klik pada butang 'Lihat Sekarang' untuk ganjaran anda. Kemudian, klik pada ikon salin di sebelah Kod Baucar ganjaran masing-masing dan ikuti langkah yang disediakan.",
			'Used Reload PIN Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.':
				'Sebaik sahaja ganjaran PIN Tambah Nilai digunakan, ia tidak boleh dikembalikan. Jumlah yang ditebus tidak boleh ditukar dan tidak boleh dikembalikan untuk kredit, tunai atau sebarang bentuk penggantian, sama ada sebahagian atau sepenuhnya.',
			'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.':
				'TNG Digital tidak akan menghubungi pengguna untuk mendapatkan maklumat peribadi atau kelayakan akaun berkenaan Kempen ini.',
			'Reload Pin will be expired on 31st December 2024.':
				'PIN Tambah Nilai akan tamat tempoh pada 31 Disember 2024.',
			DONE: 'Selesai',
			'Open the Touch ‘n Go eWallet Reload PIN app.':
				'Buka aplikasi PIN Tambah Nilai Touch ‘n Go eWallet',
			'Click ‘Reload’.': "Klik 'Tambah Nilai'.",
			'Click ‘eWallet Balance’.': "Pilih 'Baki eWallet'.",
			'Click on ‘Touch ‘n Go eWallet Reload PIN at the bottom of the page’.':
				"Klik 'PIN Tambah Nilai Touch ‘n Go eWallet'.",
			'Key in the Touch ‘n Go eWallet Reload PIN and click ‘Reload Now’.':
				"Masukkan PIN Tambah Nilai Touch ‘n Go eWallet dan klik 'Tambah Nilai Sekarang'.",
			'Successfully redeem Touch ‘n Go eWallet Reload PIN.':
				"Tahniah! Anda telah berjaya menebus PIN Tambah Nilai Touch ‘n Go eWallet' anda.",
			'How to Redeem Your GrabGift?': 'Cara Menebus GrabGift Anda?',
			'Open the Grab app and choose the “Gifts” Tab.':
				"Buka aplikasi Grab dan pilih Tab 'Hadiah'.",
			'Click the Gifts icon at the upper right corner':
				'Klik pada ikon Hadiah di penjuru kanan sebelah atas',
			'Key-in your Gift Code': 'Masukkan Kod Hadiah anda',
			'Click Pick Your Gift, then proceed with saving the voucher':
				"Klik 'Pilih Hadiah Anda' dan teruskan untuk menyimpan baucar",
			'Choose the gift': 'Pilih hadiah yang anda mahukan',
			'Your saved voucher will be stored in “My Rewards”':
				"Baucar anda akan disimpan dalam 'Ganjaran Saya’.",
			'Users must enter promo code at checkout to be entitled to the discount.':
				'Pengguna mesti memasukkan kod promosi semasa pembayaran untuk layak mendapat diskaun.',
			'This promo code is valid for (1) ONE order from services on GrabFood, GrabRide, GrabExpress or GrabMart.':
				'Kod promo ini sah untuk (1) SATU pesanan perkhidmatan dari GrabFood, GrabRide, GrabExpress atau GrabMart.',
			'This promo code will be expired on 31 December 2024':
				'Kod promo ini akan tamat tempoh pada 31 Disember 2024',
			'Promotion is not valid with any other vouchers or promotions.':
				'Promosi tidak sah dengan baucar atau promosi lain.',
			'Grab reserves the right to alter, extend or terminate the promotion, or amend the terms and conditions at its sole discretion at any time without prior notice. In case of any disputes directly or indirectly arising from the promotion, the decision of Grab shall be final.':
				'Grab berhak untuk mengubah, melanjutkan atau menamatkan promosi, atau meminda terma dan syarat mengikut budi bicara mutlaknya pada bila-bila masa tanpa notis terlebih dahulu. Sekiranya terdapat sebarang pertikaian secara langsung atau tidak langsung yang timbul daripada promosi, keputusan Grab adalah muktamad.',
			'These terms and conditions shall be governed by the laws of Malaysia and any dispute arising out of or in connection with promotion shall be referred to the exclusive jurisdiction of courts of Malaysia.':
				'Terma dan syarat ini akan ditadbir oleh undang-undang Malaysia dan sebarang pertikaian yang timbul daripada atau berkaitan dengan promosi akan dirujuk kepada bidang kuasa eksklusif mahkamah Malaysia.',
			'How to Redeem Your SPAY Voucher?': 'Cara menebus Baucar SPAY?',
			'Click on Loyalty Rewards Icon': 'Klik pada Ikon Ganjaran Loyalty',
			'Click on Redeem': 'Klik pada Tebus',
			'Key in the redemption code': 'Masukkan kod tebusan',
			'Click on Get Voucher Now': 'Klik Dapatkan Baucar Sekarang',
			'Upon successful redemption, the voucher with its value will appear':
				'Setelah tebusan berjaya, baucar beserta nilai akan muncul',
			'The vouchers will be delivered to the Company in the form of Voucher Redemption Codes which can be input into user SPayGlobal app to redeem vouchers':
				'Baucar-baucar akan dihantar kepada Syarikat dalam bentuk Kod Penebusan Baucar yang boleh dimasukkan ke dalam aplikasi SPayGlobal pengguna untuk menebus baucar',
			'Voucher received by user will be displayed inside SPayGlobal’s Loyalty Rewards.':
				'Baucar yang diterima oleh pengguna akan dipaparkan di dalam Ganjaran Loyalty SPayGlobal.',
			'To use the voucher, the minimum spend must be above the value of the voucher.':
				'Untuk menggunakan baucar, perbelanjaan minima mesti melebihi nilai baucar tersebut.',
			'There may be specific restrictions on the redemption and the use of the voucher which shall be displayed on the voucher, e.g., redemption rules such as limit to one voucher per user and usage rules such as maximum of two vouchers used per day.':
				'TMungkin terdapat sekatan tertentu pada penebusan dan penggunaan baucar yang akan dipaparkan pada baucar, contohnya, peraturan penebusan seperti had kepada satu baucar setiap pengguna dan peraturan penggunaan seperti maksimum dua baucar yang boleh digunakan setiap hari.',
			'SNT reserves the right and is at its absolute discretion to modify, withdraw, suspend, extend, or terminate this service wholly or in part, at any time.':
				'SNT berhak dan pada budi bicaranya sepenuhnya untuk mengubahsuai, menarik balik, menangguhkan, memanjangkan, atau menghentikan perkhidmatan ini secara keseluruhan atau separuh pada bila-bila masa.',
			'Gaming, manipulating the original intention of this service in any form is prohibited. SNT reserves the right to suspend/terminate any user if found to participate in such activities.':
				'Permainan, memanipulasi niat asal perkhidmatan ini dalam apa-apa bentuk adalah dilarang. SNT berhak untuk menangguhkan/menghentikan mana-mana pengguna jika didapati terlibat dalam aktiviti-aktiviti sedemikian.',
			'SNT’s decision on this service shall be final and conclusive. SNT shall not be obliged to give any reason or enter into any correspondence with any person on any matter concerning this service. No appeal and/or correspondence from any user or any third party will be entertained.':
				'Keputusan SNT mengenai perkhidmatan ini adalah muktamad. SNT tidak diwajibkan untuk memberi sebarang alasan atau terlibat dalam sebarang koresponden dengan mana-mana individu mengenai apa-apa perkara yang berkaitan dengan perkhidmatan ini. Tiada rayuan dan/atau koresponden dari mana-mana pengguna atau mana-mana pihak ketiga akan dilayan.',
			'Your receipt has been successfully uploaded and will be validated within 3 working days.':
				'Resit anda telah berjaya dimuat naik dan akan disahkan dalam masa 3 hari bekerja.',
			Redeemed: 'Redeemed',
			exp: 'exp',
			Name: 'Nama',
			'Enter your name': 'Masukkan nama anda',
			'Phone number format (e.g. 60128888888)': 'Masukkan nombor telefon anda',
			Address: 'Alamat',
			'Enter your address (line 1)': 'Masukkan alamat anda (baris 1)',
			'Enter your address (line 2)': 'Masukkan alamat anda (baris 2)',
			City: 'Bandar',
			'ZIP/Postcode': 'ZIP / Poskod',
			'Address has been successfully saved': 'Alamat telah berjaya disimpan',
			'Address has been successfully updated': 'Alamat telah berjaya disimpan',

			//Profile page
			'Personal Details': 'Maklumat Peribadi',
			'Delivery Address': 'Alamat menerima',
			'Refer A Friend': 'Rujuk kawan',
			Language: 'Bahasa',
			Tiering: 'Peringkat Keahlian',
			Badges: 'Lencana',
			Help: 'Bantuan',
			'Contact Preference': 'Pilihan cara berhubung',
			'Log Out': 'Log Keluar',

			// Personal Details
			'Full Name': 'Nama Penuh',
			'Email Address': 'Alamat Emel',
			'Mobile Number': 'Nombor Telefon',
			'Month of Birth': 'Bulan Hari Jadi',
			Success: 'Berjaya',
			Save: 'Simpan',
			'Your details have been successfully updated':
				'Butiran anda telah berjaya dikemas kini',
			Done: 'Selesai',

			// Refer a friend page
			'Refer a friend': 'Rujuk kawan',
			'And you can both earn 10 points':
				'Anda dan kawan anda boleh mendapatkan 10 mata ganjaran',
			'How does it work': 'Cara berfungsi',
			'Invite Your Friends': 'Jemput Kawan Anda',
			'Share your referral link': 'Kongsi pautan rujukan anda',
			'They earn 10 points': 'Mereka akan memperolehi 10 mata ganjaran',
			'You earn too': 'Anda juga mendapat ganjaran',
			'For each referral you get 10 points':
				'Untuk setiap rujukan, anda akan mendapat 10 mata ganjaran',
			Copy: 'Salin',

			//Tiering page
			'Tiering Benefits': 'Faedah Peringkat Keahlian',
			'View History': 'Lihat Rekod',
			'Tiering History': 'Tiering History',
			'View Benefits': 'View Benefits',
			Bronze: 'Gangsa',
			Silver: 'Perak',
			Gold: 'Emas',
			Platinum: 'Platinum',
			'Tiering Points Required': 'Mata Ganjaran Peringkat Keahlian yang Diperlukan',
			Privileges: 'Faedah',
			'Language':'Bahasa',

			// Contact preference page
			'Contact Preferences': 'Hubungan Pilihan Anda',
			'Email Newsletter': 'Emel',
			'Phone Call': 'Panggilan Telefon',
			'SMS / WhatsApp': 'SMS / WhatsApp',
		},
	},
	cn: {
		translation: {
			'Welcome To': '欢迎来到',
			'Earn points by uploading your Haleon purchase receipts and unlock a world of exclusive benefits and exciting rewards.':
				'上传您的购物收据即可赚取积分并领取专属福利以及丰富的奖励',
			'Get Started': '开始',
			'How It Works': '运作方式',
			'Purchase your favorite Haleon participating products from participating retail outlets, pharmacies, or e-stores':
				'在参与活动的商店或网店购买Haleon产品',
			'Snap and upload a photo of your physical receipt or take a screenshot of the e-receipt from your online purchase':
				'上传收据照片或在线购买的电子收据截图',
			'Brace yourself as you unlock a world of exciting rewards using your accumulated points! Start indulging in a plethora of enticing rewards today':
				'使用积分以换取专属福利以及丰富的奖励',
			'Step into the world of exciting rewards now':
				'立即进入激动人心的奖励世界',
			'Send OTP Code': '发送OTP验证码',
			'OTP Verification': 'OTP验证',
			Verify: '验证',
			'Resend Code': '重新发送验证码',
			'Your phone number has been successfully verified.': '您的号码已成功验证',
			'Referral Code/Store Code/Promoter Code': '推荐码',
			Submit: '提交',
			Registration: '注册',
			'Please complete the form to proceed': '请填写以下资料',
			Email: '电子邮件',
			Rejected: '已拒绝​',
			Approved: '已批准',
			Pending: '待处理​',
			Points: '积分',
			'Visit Haleon Online Store': '浏览Haleon网店',
			"What's New": '新消息',
			'Our Star Brands': '我们的热门品牌',
			'Popular Rewards': '热门奖励',
			'View All': '查看全部',
			'Welcome Reward': '迎新奖励',
			Claim: '领取',
			Upload: '上传',
			Purchase: '购买',
			Earn: '赚取',
			'Redeem Rewards': '解锁福利',
			'Convert your points into rewards': '将你的积分转换成奖励',
			'Sounds Good': '听起来不错',
			'Purchase description': '在参与活动的商店或网店购买Haleon产品.',
			'Upload description': '上传收据照片或在线购买的电子收据截图',
			'Earn description': '每消费1令吉, 您将获得1积分!',
			Back: '返回',
			Submissions: '提交',
			Amount: '总额',
			'Filter By': '按筛选',
			'Sample Receipt': '收据样本',
			'Upload your receipt': '上传收据照片',
			'Receipt Sample': '收据样本',
			'Sample of physical receipt': '实体收据样本',
			'Sample of e-commerce receipt': '电子收据样本',
			or: '或',
			'Delivery Details': '收货详情',
			'Edit Delivery Address': '编辑收货地址',
			'Add New Address': '添加新地址',
			'Enter your address for delivery': '输入您的收货地址',
			'Point Shop': '积分商店',
			'My Rewards': '我的奖励',
			'How to Redeem?': '如何兑换?',
			'Terms & Conditions': '活动规则与条款',
			'Digital Rewards': '电子优惠券',
			'Only ONE (1) Reload PIN Reward can be used per reload.':
				'每次充值只能使用一 (1) 个充值码',
			"To utilize the Reload Pin, log in on the microsite. At 'Rewards', tap on the 'View Now' button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.":
				'在“奖励”下，点击“立即查看”按键查看您的奖励。然后，单击相应奖励的优惠券代码旁边的复制图标，并按照步骤进操作',
			'Used Reload PIN Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.':
				'充值码奖励一旦使用，就无法撤回。兑换金额不可兑换，也不可退还为积分、现金或任何形式的部分或全部替代品.',
			'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.':
				'TNG Digital 不会联系用户以获取有关此活动的个人信息或帐户凭',
			'Reload Pin will be expired on 31st December 2024.':
				'充值码将于2024年12月31日到期',
			DONE: '关闭',
			'Open the Touch ‘n Go eWallet Reload PIN app.':
				"打开 Touch 'n Go电子钱包应用程序.",
			'Click ‘Reload’.': '点击‘充值’.',
			'Click ‘eWallet Balance’.': '选择’电子钱包余额’.',
			'Click on ‘Touch ‘n Go eWallet Reload PIN at the bottom of the page’.':
				"点击 Touch 'n Go 电子钱包充值码.",
			'Key in the Touch ‘n Go eWallet Reload PIN and click ‘Reload Now’.':
				"输入 Touch 'n Go 电子钱包充值码，然后点击'立即充值'.",
			'Successfully redeem Touch ‘n Go eWallet Reload PIN.':
				"您已成功兑换 Touch 'n Go 电子钱包充值码.",
			'How to Redeem Your GrabGift?': '如何兑换 GrabGift?',
			'Open the Grab app and choose the “Gifts” Tab.':
				'打开 Grab 应用程序并选择“礼品”选项',
			'Click the Gifts icon at the upper right corner': '点击右上角的礼物图标',
			'Key-in your Gift Code': '输入您的礼品代码',
			'Click Pick Your Gift, then proceed with saving the voucher':
				'点击“选择您的礼品并继续保存” 优惠券',
			'Choose the gift': '选择想要的礼品',
			'Your saved voucher will be stored in “My Rewards”':
				'您的优惠券将保存在“我的奖励“',
			'Terms & Conditions': '条款和条件',
			'Users must enter promo code at checkout to be entitled to the discount.':
				'用户必须在结账时输入促销代码才能享受折扣',
			'This promo code is valid for (1) ONE order from services on GrabFood, GrabRide, GrabExpress or GrabMart.':
				'此促销代码适用于 (1)一个订单从GrabFood、GrabRide、 GrabExpress 或 GrabMart.',
			'This promo code will be expired on 31 December 2024':
				'此促销代码将于 2024 年 12 月 31 日到期',
			'Promotion is not valid with any other vouchers or promotions.':
				'促销活动不适用于任何其他促销活动。优惠券或促销活动',
			'Grab reserves the right to alter, extend or terminate the promotion, or amend the terms and conditions at its sole discretion at any time without prior notice. In case of any disputes directly or indirectly arising from the promotion, the decision of Grab shall be final.':
				'Grab 保留随时自行更改、延长或终止促销活动，或修改活动规则与条款的决定权利，恕不另行通知。如有任何因促销直接或间接产生的争议，Grab 保留最终决定权.',
			'These terms and conditions shall be governed by the laws of Malaysia and any dispute arising out of or in connection with promotion shall be referred to the exclusive jurisdiction of courts of Malaysia.':
				'款和条件应受马来西亚法律管辖，任何因促销引起的或与之相关的争议均应提交马来西亚法院的专属管辖.',
			'How to Redeem Your SPAY Voucher?': '如何兑换SPAY优惠券?',
			'Click on Loyalty Rewards Icon': '点击忠诚奖励图标',
			'Click on Redeem': '点击兑换',
			'Key in the redemption code': '输入兑换码',
			'Key in the verification code': '输入验证码',
			'Click on Get Voucher Now': '点击立即获取优惠券',
			'Upon successful redemption, the voucher with its value will appear':
				'成功兑换后，将显示优惠券及其价值',
			'The vouchers will be delivered to the Company in the form of Voucher Redemption Codes which can be input into user SPayGlobal app to redeem vouchers':
				'优惠券将以优惠券兑换码的形式交付给公司，用户可以将其输入到SPayGlobal应用中以兑换优惠券',
			'Voucher received by user will be displayed inside SPayGlobal’s Loyalty Rewards.':
				'用户收到的优惠券将显示在SPayGlobal的忠诚奖励中',
			'To use the voucher, the minimum spend must be above the value of the voucher.':
				'要使用优惠券，最低消费额必须高于优惠券面值',
			'There may be specific restrictions on the redemption and the use of the voucher which shall be displayed on the voucher, e.g., redemption rules such as limit to one voucher per user and usage rules such as maximum of two vouchers used per day.':
				'兑换和使用优惠券可能存在特定限制，这些限制将显示在优惠券上。例如，兑换规则，如每个用户限制使用一张优惠券，以及使用规则，如每天最多使用两张优惠券',
			'SNT reserves the right and is at its absolute discretion to modify, withdraw, suspend, extend, or terminate this service wholly or in part, at any time.':
				'SNT保留随时修改、撤回、暂停、延长或终止本服务全部或部分内容的权利',
			'Gaming, manipulating the original intention of this service in any form is prohibited. SNT reserves the right to suspend/terminate any user if found to participate in such activities.':
				'禁止以任何形式进行游戏、操纵本服务的原始意图。 SNT保留：如果发现用户参与此类活动，暂停/终该用户账户的权利。',
			'SNT’s decision on this service shall be final and conclusive. SNT shall not be obliged to give any reason or enter into any correspondence with any person on any matter concerning this service. No appeal and/or correspondence from any user or any third party will be entertained.':
				'SNT对本服务的决定为最终决定。SNT没有义务与本服务有关的任何事项与任何人提供理由或进行任何通讯。不接受任何用户或第三方的上诉和/或通讯',
			'Your receipt has been successfully uploaded and will be validated within 3 working days.':
				'您的收据已成功上传，将在3个工作日内进行验证.',
			Redeemed: '已兑换',
			exp: '到期',
			Name: '姓名',
			'Enter your name': 'Masukkan nama anda',
			'Phone number format (e.g. 60128888888)':
				'Phone number format (e.g. 60128888888)',
			Address: '地址',
			'Enter your address (line 1)': 'Masukkan alamat anda (baris 1)',
			'Enter your address (line 2)': 'Masukkan alamat anda (baris 2)',
			City: '城市',
			'ZIP/Postcode': '邮政编码',
			'Address has been successfully saved': '地址已成功保存',
			'Address has been successfully updated': '地址已成功保存',
			'Language':'语言',

			//Profile page
			'Personal Details': '个人资料',
			'Delivery Address': '收货详情',
			'Refer A Friend': '推荐好友',
			'Contact Preference': '联系方式',
			Language: '语言',
			Tiering: '会员分级制度',
			Badges: '徽章',
			Help: '帮助',
			'Log Out': '退出',

			// Personal Details
			'Full Name': '姓名',
			'Email Address': '电子邮件地址',
			'Mobile Number': '电话号码',
			'Month of Birth': '出生月份',
			Success: '成功',
			Save: '保存',
			'Your details have been successfully updated': '您的详细信息已成功更新',
			Done: '完成',

			// Refer a friend page
			'Refer a friend': '推荐好友',
			'And you can both earn 10 points': '你们都可以获得10积分',
			'How does it work': '它是如何运作的',
			'Invite Your Friends': '邀请您的朋友',
			'Share your referral link': '分享您的推荐链接',
			'They earn 10 points': '他们将获得10积分',
			'You earn too': '您也将获得奖励',
			'For each referral you get 10 points': '每次推荐您都会获得10积分',
			Copy: '复制',

			//Tiering page
			'Tiering Benefits': '会员分级福利',
			'View History': '查看历史记录',
			'Tiering History': 'Tiering History',
			'View Benefits': 'View Benefits',
			Bronze: '铜',
			Silver: '铜',
			Gold: '金',
			Platinum: '白金',
			'Tiering Points Required': '分级所需积分',
			Privileges: '优惠',
			'Welcome Bonus': '迎新奖励',

			// Contact preference page
			'Contact Preferences': '联系方式',
			'Email Newsletter': '电子邮件',
			'Phone Call': '电话呼叫',
			'SMS / WhatsApp': '短信/WhatsApp',
			userBronze: '铜级',
			userSilver: '银级',
			userGold: '金级',
			userPlatinum: '白金级',
			Home: '首页',
			Rewards: '奖励',
			'Upload Receipt': '上传收据',
			History: '记录',
			Profile: '个人资料',
			Redeem: '兑换',
			Claimed: '已领取',
		},
	},
};

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//     // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//     // if you're using a language detector, do not define the lng option

//     interpolation: {
//       escapeValue: false // react already safes from xss
//     }
i18n.use(initReactI18next).init({
	resources,
	lng: language && language === 'en' ? 'en' : language === 'bm' ? 'bm' : 'cn',
	fallbackLng: 'en',
	keySeparator: '.',
	returnObjects: true,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
