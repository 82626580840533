import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import termsIcon from "../../../../images/Haleon/Paper.png"
const TermsFairprice = ({
	open,
	onClose,
}) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'rewards.modal.fairprice.tnc_modal',
	  }); 
	return (
		<Modal open={open} centered footer={null} closable={false}>
		<div className="text-center">
		  <img
			style={{ width: "50px", height: "65px" }}
			src={termsIcon}
			alt="tnc_icon"
		  />
		  <div className="mt-1" style={{ lineHeight: "40px" }}>
			<h3 className="mt-2 font-weight-700 font-20">
			  Terms & Conditions
			</h3>
		  </div>
		  <ol
			style={{
			  padding: "0 15px",
			  textAlign: "justify",
			  textJustify: "auto",
			}}
		  >
			<li className="my-1">
			{t('list.0')}

			</li>
			<li className="my-1">
			{t('list.1')}

			</li>
			<li className="my-1">
			 {t('list.2')}
			</li>
			<li className="my-1">
			 {t('list.3')}
			</li>
			<li className="my-1">
			 {t('list.4')}
			</li>
			<li className="my-1">
			 {t('list.5')}
			</li>
			<li className="my-1">
			 {t('list.6')}
			</li>
			<li className="my-1">
			 {t('list.7')}
			</li>
		  </ol>
		  <Button
			type="primary"
			className="mt-1"
			onClick={() => onClose()}
			style={{
				fontSize:'12px', 
				borderRadius:'8px', 
				background: '#CB0E07',
				color: 'white',
				border:'none',
				fontWeight:'500',
				height:'40px',
				width:'90%',
			}}
		  >
			Close
		  </Button>
		</div>
	  </Modal>
	);
};

export default TermsFairprice;
