import axios from 'axios';

const isSGRegion =
	window.location.hostname.replace('www.', '') ===
	process.env.REACT_APP_SG_DOMAIN;

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_API_DOMAIN_URL,
	headers: {
		'x-company-id': isSGRegion
			? process.env.REACT_APP_COMPANY_ID_SG
			: process.env.REACT_APP_COMPANY_ID_MY,
	},
});

axiosClient.interceptors.request.use(
	(config) => {
		let token = localStorage.getItem('HALEON-REWARDS_USER_TOKEN');

		// RETRIVE RESPECTIVE TOKENS FOR EACH USER TYPE
		if (config.url?.split('/')[1] === 'dsr') {
			token = localStorage.getItem('dsr-token');
		} else if (config.url?.split('/')[1] === 'retailer') {
			token = localStorage.getItem('retailer-token');
		}

		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}

		return config;
	},
	(error) => {
		console.log('error', error);
		return Promise.reject(error);
	}
);

export default axiosClient;
