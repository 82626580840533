import { Modal, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import watsons_logo from '../../../../images/Haleon/fairprice-logo.png';

const HowToRedeemFairprice = ({ open, onClose }) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'rewards.modal.fairprice',
	});
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');
	return (
		<Modal open={open} centered footer={null} closable={false} width={350}>
			<div className="text-center">
				<img
					className="mt-1"
					style={{ width: '90%' }}
					src={watsons_logo}
					alt=""
				/>
				<div className="mt-1" style={{ lineHeight: '25px' }}>
					<h3
						style={{
							fontSize: '20px',
							width: 'auto',
						}}
					>
						{getLanguage === 'bm' ? (
							<>Cara Menebus PIN Tambah Nilai Touch ‘n Go eWallet Anda?</>
						) : getLanguage === 'cn' ? (
							<>如何兑换您的 Touch 'n Go eWallet 充值码？</>
						) : (
							<>
								How to Redeem Your <br />
								Fairprice Voucher?
							</>
						)}
					</h3>
				</div>
				<div
					className="mt-2"
					style={{
						textAlign: 'justify',
					}}
				>
					<div>{t('how_to.0')}</div>
					<ol style={{
						margin: "1em", 
					}}>
						<li>{t('how_to.1')}</li>
						
						<li><Trans i18nKey={'rewards.modal.fairprice.how_to.2'} components={{ 1: <a href="http://fairprice.com.sg" style={{
							color:"black",
							textDecoration:"underline"
						}}/> }} /></li>
					</ol>
					<div>{t('how_to.3')}</div>
					<div>{t('how_to.4')}</div>
				</div>
				<Button
					type="primary"
					className="mt-1"
					onClick={() => onClose()}
					style={{
						textTransform: 'uppercase',
						fontSize: '12px',
						borderRadius: '8px',
						background: '#CB0E07',
						color: 'white',
						border: 'none',
						fontWeight: '500',
						height: '40px',
						width: '90%',
					}}
				>
					{t('close')}
				</Button>
			</div>
		</Modal>
	);
};

export default HowToRedeemFairprice;
