import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Carousel, Modal } from 'antd';
import '../../css/GetStarted.css';
import img1 from '../../images/Haleon/happyFamily.png';
import cart from '../../images/Haleon/cart.png';
import upload from '../../images/Haleon/upload.png';
import gift from '../../images/Haleon/gift.png';
import haleonLogo from '../../images/Haleon/HaleonLogo.svg';
import rightarrow from '../../images/Haleon/rightarrow.png';
import rejected from '../../images/Haleon/rejected.png';

const GetStarted = ({t}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [errorModal, setErrorModal] = useState(false);

	const contentStyle = {
		margin: '0',
		height: '200px',
		color: '#FFFFFF',
		background: '#000000',
	};

	const handleNavigate = () => {
		navigate('/login' + window.location.search);
	};
	let language = localStorage.getItem('HALEON-REWARDS_LANG');

	return (
		<div>
			<div className="logo-position">
				<img src={haleonLogo} alt="haleon" style={{ width: '30%' }} />
			</div>
			<img src={img1} alt="pic" className="w-100" />
			<div className="layout-position">
				<div className="how-it-works-card">
					<div className="ml-3 mr-3">
						<br />
						<h2>{t('How It Works')}.</h2>
						<hr
							style={{
								backgroundColor: '#30EA03',
								height: '3px',
								width: language === 'bm' ? '50%' : language === 'cn' ? '100px' : '45%' ,
								marginTop: '5px',
								border: 'none',
							}}
						/>
						<div>
							<Carousel className="carousel-slider">
								<div>
									<div style={contentStyle}>
										<div className="carousel-img-position mt-3">
											<img src={cart} alt="" style={{ width: '50%' }} />
										</div>
										<p
											className="fontSize-16"
											style={{
												maxWidth: '100%',
												lineHeight: '20px',
												marginTop: '20px',
											}}
										>
											{t('Purchase your favorite Haleon participating products from participating retail outlets, pharmacies, or e-stores')}.
										</p>
									</div>
								</div>

								<div>
									<div style={contentStyle}>
										<div className="carousel-img-position mt-3">
											<img src={upload} alt="" className="w-50" />
										</div>
										<p
											className="fontSize-16"
											style={{
												maxWidth: '100%',
												lineHeight: '20px',
												marginTop: '20px',
											}}
										>
											{t('Snap and upload a photo of your physical receipt or take a screenshot of the e-receipt from your online purchase')}.
										</p>
									</div>
								</div>

								<div>
									<div style={contentStyle}>
										<div className="carousel-img-position mt-3">
											<img src={gift} alt="" className="w-50" />
										</div>
										<p
											className="fontSize-16"
											style={{
												maxWidth: '100%',
												lineHeight: '20px',
												marginTop: '20px',
											}}
										>
											{t('Brace yourself as you unlock a world of exciting rewards using your accumulated points! Start indulging in a plethora of enticing rewards today')}.
										</p>
									</div>
								</div>
							</Carousel>

							<div onClick={handleNavigate}>
								<img
									src={rightarrow}
									alt=""
									className="w-10"
									style={{
										float: 'right',
										position: 'absolute',
										right: '50px',
										bottom: '-220px',
									}}
								/>
							</div>
						</div>
					</div>
					<Modal
						open={errorModal}
						centered
						footer={null}
						closable={false}
						width={300}
					>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<img src={rejected} alt="" style={{ width: '20%' }}></img>
						</div>
						<br />
						<div
							style={{
								fontWeight: 'bold',
								fontSize: '20px',
								textAlign: 'center',
							}}
						>
							Invalid QR Code, please rescan a validate QR again
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
};

export default GetStarted;
