import { Modal, Button } from 'antd';
import termsIcon from '../../images/Haleon/Paper.png';
import { useTranslation } from 'react-i18next';

const TermsSpay = ({ termsSpay, setTermsSpay }) => {
	const { t } = useTranslation();
	return (
		<Modal open={termsSpay} centered footer={null} closable={false}>
			<div className="text-center">
				<img style={{ width: '18%' }} src={termsIcon} alt="T&C_img" />
				<div className="mt-1" style={{ lineHeight: '40px' }}>
					<h3 className="mt-2" style={{ fontSize: '21px' }}>
						{t('Terms & Conditions')}
					</h3>
				</div>
				<ul
					style={{
						padding: '0 15px',
						fontSize: '14px',
						textAlign: 'justify',
						textJustify: 'auto',
					}}
				>
					<li className="my-1">
						{' '}
						{t(
							'The vouchers will be delivered to the Company in the form of Voucher Redemption Codes which can be input into user SPayGlobal app to redeem vouchers'
						)}
					</li>
					<li className="my-1">
						{t(
							'Voucher received by user will be displayed inside SPayGlobal’s Loyalty Rewards.'
						)}
					</li>
					<li className="my-1">
						{t(
							'To use the voucher, the minimum spend must be above the value of the voucher.'
						)}
					</li>
					<li className="my-1">
						{t(
							'There may be specific restrictions on the redemption and the use of the voucher which shall be displayed on the voucher, e.g., redemption rules such as limit to one voucher per user and usage rules such as maximum of two vouchers used per day.'
						)}
					</li>
					<li className="my-1">
						{t(
							'SNT reserves the right and is at its absolute discretion to modify, withdraw, suspend, extend, or terminate this service wholly or in part, at any time.'
						)}
					</li>
					<li className="my-1">
						{t(
							'Gaming, manipulating the original intention of this service in any form is prohibited. SNT reserves the right to suspend/terminate any user if found to participate in such activities.'
						)}
					</li>
					<li className="my-1">
						{t(
							'SNT’s decision on this service shall be final and conclusive. SNT shall not be obliged to give any reason or enter into any correspondence with any person on any matter concerning this service. No appeal and/or correspondence from any user or any third party will be entertained.'
						)}
					</li>
				</ul>

				<Button
					type="primary"
					className="mt-1"
					onClick={() => setTermsSpay(false)}
					style={{
						fontSize: '12px',
						borderRadius: '8px',
						background: '#CB0E07',
						color: 'white',
						border: 'none',
						fontWeight: '500',
						height: '40px',
						width: '90%',
					}}
				>
					{t('DONE')}
				</Button>
			</div>
		</Modal>
	);
};

export default TermsSpay;
