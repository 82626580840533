import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
	ADMIN,
	PROMOTER,
	RETAILER,
	SALE_REP,
	USER,
	INTERNAL_USER,
} from '../enums';

const AuthRedirect = ({ children, role }) => {
	const navigate = useNavigate();

	const [redirectTo, setRedirectTo] = useState();

	const checkTokenAndRedirectToRelativeLogin = () => {
		const token = localStorage.getItem('HALEON-REWARDS_USER_TOKEN');

		if (!token) {
			// Redirect to the corresponding login page
			switch (role) {
				// case ADMIN:
				// 	setRedirectTo('/admin/login');
				// 	break;
				// case USER:
				// 	// setRedirectTo('/login');
				// 	break;
				// case SALE_REP:
				// case RETAILER:
				// case PROMOTER:
				// 	setRedirectTo('/operator/login');
				// 	break;
				default:
					setRedirectTo('/'+window.location.search);
					break;
			}
		}
	};

	useEffect(() => {
		if (redirectTo) {
			navigate(redirectTo);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectTo]);

	useEffect(() => {
		checkTokenAndRedirectToRelativeLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return children;
};

export default AuthRedirect;
