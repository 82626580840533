import React, { useEffect, useState } from 'react';
import { Button, Collapse, Space } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import '../../css/HelpCenter.css';
import SubHeader from '../../components/SubHeader';

import RewardsTNC from "../../assets/2024-08-27 Haleon Rewards TCs.pdf";
const { Panel } = Collapse;

const HelpCenter = ({ t }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [type, setType] = useState(location.state || 'FAQ');
	// const [isSoon, setIsSoon] = useState(false);
	const userToken = localStorage.getItem('HALEON-REWARDS_USER_TOKEN');
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;

	useEffect(() => {
		if (!userToken) {
			navigate('/welcome' + window.location.search);
		}
	}, [userToken]);

	return (
		<div>
			<SubHeader setPreviousPage={'/profile'} />

			<div className="mt-1">
				<hr />
			</div>

			<div className="ml-2 mr-2">
				<div className="mt-2">
					<p style={{ fontWeight: '500', fontSize: '25px' }}>Help Center</p>
				</div>

				<div
					className="mt-1 d-flex"
					style={{ justifyContent: 'left', gap: '10px' }}
				>
					<Button
						style={{
							backgroundColor: type === 'FAQ' ? '#313131' : '#AAAAAA',
							borderRadius: '51px',
							color: 'white',
						}}
						onClick={() => setType('FAQ')}
					>
						FAQ
					</Button>

					<Button
						style={{
							backgroundColor: type === 'TNC' ? '#313131' : '#AAAAAA',
							borderRadius: '51px',
							color: 'white',
						}}
						// onClick={() => {
						// 	isSGRegion ? (setType('TNC')) : (window.open(RewardsTNC, "_blank"))
						// }}
						onClick={() =>  (setType('TNC'))}
					>
						Terms & Conditions
					</Button>
				</div>
				{type === 'FAQ' ? (
					<div>
						<div className="mt-2">
							{isSGRegion ? (
								<>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '18px',
											fontWeight: 'bold',
											paddingLeft: '3%',
										}}
									>
										[Campaign Related]
									</p>
									<Collapse
										bordered={false}
										defaultActiveKey={['']}
										expandIcon={({ isActive }) =>
											isActive ? <UpOutlined /> : <DownOutlined />
										}
										expandIconPosition="end"
									>
										<Panel
											header="What is Haleon Rewards Program?"
											key="1"
											className="subTitle"
										>
											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
												}}
											>
												Haleon Rewards Program is a digital platform that allows
												you to redeem eCapitaVoucher worth $5. You Scan redeem
												these rewards by meeting the following criteria:
											</p>
											<ul className="use-tick subContent">
												<li>
													{' '}
													Purchase a minimum worth of $28 and above for any
													Aquafresh and/or Sensodyne and/or Polident and/or
													Parodontax products at any Watsons stores in Singapore
													in a single Proof of Purchase [“POP”] <br />
												</li>
												<b>OR</b>
												<li>
													{' '}
													Purchase a minimum worth of $68 and above for any
													Caltrate and/or Centrum and/or Scott’s products at any
													Watsons or Guardian or FairPrice in Singapore in a
													single Proof of Purchase [“POP”]
												</li>
											</ul>

											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
													paddingTop: '5%',
												}}
											>
												In addition to immediate rewards, the Haleon Rewards
												Program also allows you to accumulate points for future
												redemption. For every $1 spent, you will be rewarded
												with 1 point. If the amount spent involves cents, the
												spend amount will be rounded down. For example:
												<br />
												$12.40 = 12 Points
												<br /> $12.60 = 12 Points
											</p>
										</Panel>
										<Panel
											header="Which brands and products are participating in the program?"
											key="2"
											className="subTitle"
										>
											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
												}}
											>
												The following brands and products are participating in
												the Haleon Rewards Program:
											</p>
											<ul style={{ marginLeft: '7%' }} className="subContent">
												<li>Aquafresh</li>
												<li> Sensodyne</li>
												<li> Polident</li>
												<li> Parodontax </li>
												<li> Scott's</li>
												<li> Centrum</li>
												<li> Caltrate</li>
											</ul>
										</Panel>
										<Panel
											header="What is the duration of the campaign?"
											key="3"
											className="subTitle"
										>
											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
												}}
											>
												This Program will run from 00:00 on 11th March 2024 to
												23:59 on 31st December 2024 based on the Singapore Time
												Zone (referred to as the“Redemption Period”).
												Alternatively, the program will end when a total of
												2,000 rewards eCapitaVoucher, have been fully redeemed,
												whichever occurs earlier.
											</p>
										</Panel>
										<Panel
											header="How can I participate and be eligible for prize redemption?"
											key="4"
											className="subTitle"
										>
											<ul style={{ marginLeft: '7%' }} className="subContent">
												<li> Step 1: Scan the QR Code</li>
												<li>
													Step 2: Provide your Full Name, Mobile Number, and
													Email
												</li>
												<li>
													Step 3: Verify your mobile number with a One-Time
													Password (OTP)
												</li>
												<li>Step 4: Upload purchase receipt</li>
												<li>
													{' '}
													Step 5: Upon successful validation, redeem the $5
													eCapitaVoucher in rewards page within 5 working days
													of submission.{' '}
												</li>
											</ul>
										</Panel>
										<Panel
											header="How many times can I redeem the $5 eCapitaVoucher ?"
											key="5"
											className="subTitle"
										>
											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
												}}
											>
												The maximum redemption is five (5) per user per month.
											</p>
										</Panel>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												fontSize: '18px',
												fontWeight: 'bold',
												background: 'white',
												paddingTop: '5%',
												paddingLeft: '3%',
											}}
										>
											[General]
										</p>

										<Panel
											header="What can I do with Haleon Rewards?"
											key="6"
											className="subTitle"
										>
											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
												}}
											>
												With Haleon Rewards, you can:
											</p>
											<ul style={{ marginLeft: '7%' }} className="subContent">
												<li> Participate in shopper programs</li>
												<li>Earn Haleon Rewards points</li>
												<li>Redeem rewards with Haleon Rewards points</li>
												<li>Level up to earn more Haleon Rewards points</li>
												<li>Refer friends to earn haleon rewards points</li>
												<li>More features to come!</li>
											</ul>
										</Panel>

										<Panel
											header="Where can I send my inquiries or provide feedback?"
											key="7"
											className="subTitle"
										>
											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
												}}
											>
												You can contact us through the site Hotline Support at{' '}
												<a
													style={{ textDecoration: 'none', color: 'black' }}
													href="https://wa.me/+601154125382"
													target="_blank"
													rel="noopener noreferrer"
												>
													(+6011-54125382)
												</a>{' '}
												from Monday to Friday, 9am to 5pm. We will respond to
												your inquiries within 1 to 3 business days.
											</p>
										</Panel>
										<Panel
											header="Where can I find more information about Haleon Rewards?"
											key="8"
											className="subTitle"
										>
											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
												}}
											>
												For more information about the Haleon Rewards loyalty
												program, please refer to the{' '}
												<span
													onClick={() => {
														window.scrollTo({ top: 0, behavior: 'smooth' });
														setType('TNC');
													}}
												>
													[Terms and Conditions]
												</span>
												.
											</p>
										</Panel>
										<Panel
											header="Where can I find more information about Haleon?"
											key="9"
											className="subTitle"
										>
											<p
												style={{
													textAlign: 'justify',
													textJustify: 'inter-word',
													lineHeight: '1.5em',
													fontSize: '14px',
												}}
											>
												Click{' '}
												<a
													style={{ textDecoration: 'none', color: 'black' }}
													href="https://www.haleon.com/"
													target="_blank"
													rel="noopener noreferrer"
												>
													[here]
												</a>{' '}
												to visit Haleon’s website.
											</p>
										</Panel>
									</Collapse>
								</>
							) : (
								<Collapse
									bordered={false}
									defaultActiveKey={['']}
									expandIcon={({ isActive }) =>
										isActive ? <UpOutlined /> : <DownOutlined />
									}
									expandIconPosition="end"
								>
									<Panel
										header="What is Haleon Rewards Program?"
										key="1"
										className="subTitle"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}
										>
											Haleon Rewards Program is to reward you (our loyal
											customer) everytime you purchase your favorite Haleon
											participating products. You can redeem a variety of
											rewards once you have collected points. Know more about
											Haleon products in the brand section. Register now and
											start your rewarding journey as a Haleon Rewards member!
											Also you can refer your friends to earn extra points.
										</p>
										<p style={{ fontSize: '13px', paddingTop: '2%' }}>
											*Terms & Conditions Apply
										</p>
									</Panel>
									<Panel
										header="What can I earn and redeem? What is the point system for Haleon Rewards and how can I redeem my points?"
										key="2"
										className="subTitle"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}
										>
											You can earn points from every Ringgit spent on Haleon
											participating product
											<br /> RM 1 = 1 Haleon Rewards Point. For example:
											<br /> • RM12.40 = 12 Points <br />• RM12.60 = 12 Points
											<br /> *cents are rounded down to the nearest Ringgit.
											<br /> <br />A new registration is entitled to additional
											rewards up to RM25 with purchase of RM30 and above. <br />
											<br />
											Reward badges available
											<br />{' '}
											<ul className="use-tick subContent">
												<li>
													{' '}
													Sensodyne, Polident, Scott’s, Centrum and Caltrate =
													RM5 e-wallet rebate (each) <br />
												</li>
												<li>
													{' '}
													Panaflex = 10 Haleon Rewards Points <br />
												</li>
											</ul>
											<br />
											Criteria: <br />
											<ul className="use-tick subContent">
												<li>
													{' '}
													The purchase must be made in a single receipt at any
													participating General Trade, Modern Trade, Chinese
													Medical Hall, Pharmacy stores, or online stores in
													Malaysia.
												</li>
												<li> You can redeem ONCE for each brand.</li>
												<li>
													{' '}
													This reward is only valid within the FIRST 30 days
													upon registration.
												</li>
												<li>
													{' '}
													You can choose your preferred e-wallet rebates
													platform : Touch ‘n Go eWallet Reload PINs /GrabGifts
													Voucher/ SarawakPay.
												</li>
											</ul>
										</p>
									</Panel>
									<Panel
										header="Which Haleon brands and products are participating in the program?"
										key="3"
										className="subTitle"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}
										>
											The following brands and products are participating in the
											Haleon Rewards Program:
										</p>
										<ul style={{ marginLeft: '7%' }} className="subContent">
											<li>Panaflex</li>
											<li>Sensodyne</li>
											<li>Polident</li>
											<li>Scott's</li>
											<li>Centrum</li>
											<li>Caltrate</li>
										</ul>
									</Panel>
									<Panel
										header="What is the duration of the campaign?"
										key="4"
										className="subTitle"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}
										>
											The Haleon Rewards is a new and on going long term rewards
											program which has started on 29 Feb 2024 in Malaysia.{' '}
										</p>
									</Panel>
									<Panel
										header="What are the steps to redeem my reward?"
										key="5"
										className="subTitle"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}
										>
											You can redeem the reward in 5 simple steps:
										</p>
										<ul style={{ marginLeft: '7%' }} className="subContent">
											<li>Step 1: Scan the QR Code in participating stores.</li>
											<li>
												Step 2: Provide your Full Name, Mobile Number, and
												Email, Referral Code (If any)
											</li>
											<li>
												Step 3: Verify your mobile number with a One-Time
												Password (OTP)
											</li>
											<li>
												Step 4: Upload your purchase receipt for validation (in
												3 working days)
											</li>
											<li>Step 5: Collect reward points</li>
										</ul>
									</Panel>
									<Panel
										header="Do you have a helpdesk?"
										key="6"
										className="subTitle"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}
										>
											You can contact us through the site Hotline Support at{' '}
											<a
												style={{ textDecoration: 'none', color: 'black' }}
												href="https://wa.me/+601154125382"
												target="_blank"
												rel="noopener noreferrer"
											>
												(+6011-54125382)
											</a>{' '}
											from Monday to Friday, 9am to 5pm. We will respond to your
											enquiries within 1 to 3 business days.
										</p>
									</Panel>
									<Panel
										header="Where can I find more information about Haleon Rewards?"
										key="7"
										className="subTitle"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}
										>
											For more information about the Haleon Rewards loyalty
											program, please refer to the{' '}
											<span
												onClick={() => {
													window.scrollTo({ top: 0, behavior: 'smooth' });
													setType('TNC');
												}}
											>
												[Terms and Conditions]
											</span>
											.
										</p>
									</Panel>

									<Panel
										header="Where can I find more information about Haleon?"
										key="8"
										className="subTitle"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												lineHeight: '1.5em',
												fontSize: '14px',
											}}
										>
											Click{' '}
											<a
												style={{ textDecoration: 'none', color: 'black' }}
												href="https://www.haleon.com/"
												target="_blank"
												rel="noopener noreferrer"
											>
												[here]
											</a>{' '}
											to visit Haleon’s website.
										</p>
									</Panel>
								</Collapse>
							)}
						</div>
					</div>
				) : null}

				{type === 'TNC' ? (
					<>
						{isSGRegion?(
							<div style={{ paddingBottom: '50px' }}>
								<p
									className="fontSize-16 text-left mt-2"
									style={{ fontWeight: '600', lineHeight: '15px' }}
								>
									Eligibility
								</p>
								<ol>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										The Haleon Rewards ["Program"] is organized by GSK Consumer
										Healthcare Singapore Pte Ltd ["Organizer"]. Participation in
										the Program is subject to these Terms and Conditions
										(“T&Cs”). The Organizer may at any time at its sole and
										absolute discretion, without notice or assigning any reason
										therefore: (a) substitute, withdraw, add to or alter any of
										the redemption; (b) suspend, withdraw or terminate the
										Program; or (c) delete, vary, supplement, amend or modify
										these T&Cs in such manner as the Organizer deems fit,
										including without limitation, the eligibility of
										participants, the bases and methods of identification of any
										successful participant (s) and any dates in connection with
										the Program and the Redemption Period.
									</li>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										This Program is open to all residents of Singapore above 18
										years of age (as at 1 January 2024) except the employees of
										the Organizer, its Agency and their immediate families,
										affiliates and/or related companies, distributors and
										advertising agencies. By participating in this Program, you
										confirm that you meet the eligibility requirements.
									</li>
									<p
										className="fontSize-18 text-left mt-2"
										style={{ fontWeight: '600' }}
									>
										{' '}
										Redemption Period{' '}
									</p>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										This Program will run from 00:00 on 11th March 2024 to 23:59 on 31 December 2024 (Singapore Time) [“<b>Redemption Period</b>”].
									</li>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										Entries received outside the Redemption Period are
										automatically disqualified.
									</li>
									<p
										className="fontSize-18 text-left mt-2"
										style={{ fontWeight: '600' }}
									>
										{' '}
										How to Enter{' '}
									</p>
									<li className="mt-2 ml-3">
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												fontWeight: '600',
											}}
											className="subTitle"
										>
											Mechanics of Program:
										</p>
									</li>
									<p
										className=" ml-2"
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											lineHeight: '20px',
										}}
									>
										<p className='mt-1'>
											a. Purchase a minimum worth of $28 and above for any
											Aquafresh and/or Sensodyne and/or Polident and/or Parodontax
											products at any Watsons stores in Singapore in a single
											Proof of Purchase [“<b>POP”</b>] during the Redemption
											Period and will be entitled to redeem eCapitaVoucher worth
											SGD 5. 
										</p>
										
										<br />
										<p>
											Purchase a minimum worth of $68 and above for any Caltrate,
											Centrum, Scott’s products at any Watsons/Guardian/FairPrice
											in Singapore in a single Proof of Purchase [<b>POP”</b>]
											during the Redemption Period and will be entitled to redeem
											eCapitaVoucher worth SGD 5. 
										</p>
										<br />
										<p>
											One (1) POP entitles the participants to one (1) entry during the Redemption Period. Limited to <b>maximum five (5) submission per month</b> and the receipt or e-receipt must be uploaded <b>within seven (7) days</b> from the date of purchase.
										</p>
										<p
											className="mt-2"
										>
											{' '}
											Illustrations:{' '}
										</p>
										<ul className="ml-3">
											<li>
												Participant A purchases Haleon Participating Products on 15 April
												2024. Participant A can upload the receipt or e-receipt anytime on/or
												before 22 April 2024.
											</li>
										</ul>
									</p>
									<p
										className="mt-1 ml-2"
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											lineHeight: '20px',
										}}
									>
										b. POP is a valid original computer-generated receipt
										clearly indicating the participating store name, date of
										purchase, prices, receipt number and details of the Products
										purchased.
										<br />
									</p>
									<p
										className="mt-1 ml-2"
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											lineHeight: '20px',
										}}
									>
										c. Notwithstanding the amount spent by the participant in any calendar month, each participant will only be entitled to a maximum of 500 base Haleon Rewards Points in each calendar month, subject to the Points Multiplier which the participant is entitled to (depending on the Membership Tier)
										<br />
										<p
											className="mt-2"
										>
											{' '}
											Illustrations:{' '}
										</p>
										<ul className="ml-3">
											<li>
												Participant A accumulates $750.00 cumulative spending on Participating Products in the month of April 2024. Participant A submits the receipt and/or e-receipt onto the Haleon Rewards Account. Upon validation:
												<ol className="ml-3 mt-2">
													<li>
														If Participant A is a Silver member, Participant A will be credited with a total of 750 Haleon Rewards Points (500 base Haleon Rewards Points x 1.5x Points Multiplier); or
													</li>
													<li>
														. (ii)If Participant A is a Platinum member, Participant A will be credited with a total of 1500 Haleon Rewards Points (500 base Haleon Rewards Points x 3x Points Multiplier).

													</li>
												</ol>
											</li>
										</ul>
									</p>
									<p
										className="mt-1 ml-2"
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											lineHeight: '20px',
										}}
									>
										d. Participants can redeem the eCapitaVoucher via QR scan
										with the following steps:
									</p>
									<p
										className="fontSize-18 text-left mt-2"
										style={{ fontWeight: '600' }}
									>
										Via QR scan:
									</p>
									<div
										style={{ justifyContent: 'left', display: 'flex' }}
										className="ml-2 mt-1"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												fontSize: '15px',
											}}
											className="font-arial"
										>
											i. Take a clear picture of POP and scan QR code from
											participating stores.
										</p>
									</div>
									<div
										style={{ justifyContent: 'left', display: 'flex' }}
										className="ml-2 mt-1"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												fontSize: '15px',
												lineHeight: '20px',
											}}
											className="font-arial"
										>
											ii. Register to log in by using valid handphone number and
											name. SMS OTP will be sent to the registered phone number
											for verification purpose.
										</p>
									</div>
									<div
										style={{ justifyContent: 'left', display: 'flex' }}
										className="ml-2 mt-1"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												fontSize: '15px',
												lineHeight: '20px',
											}}
											className="font-arial"
										>
											iii. Upload a clear computer-generated POP receipt with
											retailer store name, invoice number, date and time,
											purchase item description and purchase amount. A
											notification message will be prompted to the participant
											to inform on successful receipt upload status and the
											receipt will be validated within 3 working days. Upon
											completion of receipt validation, an SMS notification
											message will be sent to the participant to inform on
											eCapitaVoucher redemption.
										</p>
									</div>
									<div
										style={{ justifyContent: 'left', display: 'flex' }}
										className="ml-2 mt-1"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												fontSize: '15px',
												lineHeight: '20px',
											}}
											className="font-arial"
										>
											iv. All eCapitaVoucher received by participants must be
											used before the expiry date and in accordance with its
											terms and conditions. The Organizer or e-wallet reload PIN
											vendor will not honour any claim by participants with
											expired PINs.
										</p>
									</div>
									<div
										style={{ justifyContent: 'left', display: 'flex' }}
										className="ml-2 mt-1"
									>
										<p
											style={{
												textAlign: 'justify',
												textJustify: 'inter-word',
												fontSize: '15px',
												lineHeight: '20px',
											}}
											className="font-arial"
										>
											v. The Organizer is not responsible nor liable to the
											participants for any error, omission, interruption,
											deletion, defect, delay, misdirect, failure in operation
											or transmission, communications line failure, theft or
											destruction, unauthorized access to, or alternation of,
											user or member communications, or any problems or
											technical malfunction or glitches of any telephone network
											or lines, the website, computer online systems, servers or
											providers, computer equipment, system, apparatus,
											software, failure of receipt of entry on account of
											technical problems or traffic congestion on the internet
											and/or telecommunication services.
										</p>
									</div>
									<li
										className="mt-2 ml-2 subTitle fontSize-16 ml-3"
										style={{ fontWeight: '600' }}
									>
										{' '}
										Responsibilities & Rights of the Organizer{' '}
									</li>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1"
									>
										a. By participating in this Program, the participants shall
										be deemed as have accepted these T&Cs including any
										amendments, additions, replacements, variations and
										modifications thereto and agree to be bound by these T&Cs.
										Non-compliance with or breach of any of these T&Cs may
										disqualify a participant at any stage of the Program, and
										any redemption won may be forfeited, withheld, withdrawn or
										reclaimed at the Organizer’s sole option and absolute
										discretion. The decision of the Organizer is final and not
										subject to any appeal.
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial mt-1 ml-1"
									>
										b. By participating in this Program, the participants
										consent and give permission to the Organizer and/or its
										third-party agents ["<b>Agency</b>"] to process, disclose,
										reveal or divulge the participants' personal data for
										verification and redemption purposes, in any manner which
										the Organizer may deem fit for publicity purposes or in
										accordance with these T&Cs.{' '}
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial mt-1 ml-1"
									>
										c. Values of redemption are accurate at the time of
										printing. The Organizer reserves the right to substitute any
										of the redemption with that of similar value at any time at
										their absolute discretion without prior notice.
									</p>{' '}
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial mt-1 ml-1"
									>
										d. The Organizer reserves the right, in its sole discretion,
										to disqualify any participant that is found or suspected of
										tampering with the Program submission process, or the
										operation of the Program and the Organizer reserves the
										right to pursue legal action against any individual it
										believes has undertaken fraudulent activities or other
										activities harmful to the Program.{' '}
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial mt-1 ml-1"
									>
										e. Participants will assume full liability in case of any
										injury, loss (including, without limitation, indirect or
										consequential loss), claim, or accident including property
										damage and loss of life resulting from participation in the
										Program and/or use of their redemption.
									</p>
									{/* <li
										className="mt-2 ml-3 subTitle fontSize-16"
										style={{ fontWeight: '600' }}
									>
										{' '}
										Redemption{' '}
									</li>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1"
									>
										a. Redemption: eCapitaVoucher worth $5. Subject to terms and
										conditions.
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										b. This Program is limited to SGD5 eCapitaVoucher of a total
										2,000 redemptions only (for all redemption categories and
										while stocks last).
									</p> */}
									<li
										className="mt-2 ml-3 subTitle fontSize-16"
										style={{ fontWeight: '600' }}
									>
										Selection & Announcement of Successful Participants{' '}
									</li>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1"
									>
										a. Successful participants will be notified via the
										handphone number provided to redeem their eCapitaVoucher at
										the Rewards page.
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										b. The Organiser is not responsible for eCapitaVoucher which
										were not redeemed by successful participants prior to
										expiry.
									</p>
									<li
										className="mt-2 ml-3 subTitle fontSize-16"
										style={{ fontWeight: '600' }}
									>
										{' '}
										Disqualification{' '}
									</li>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										a. Redemption Program entries will be disqualified for
										multiple submissions using the same POP, handwritten receipt
										or unclear receipt without clear information on store name,
										product description, purchase amount, date of purchase and
										receipt number.
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										b. The Organizer reserves the right to disqualify and/or
										exclude participants and/or revoke the redemption if:
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										{' '}
										(i) the participant is ineligible or does not meet any of
										the eligibility criteria set out in the above; or{' '}
									</p>{' '}
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-2"
									>
										(ii) the participant breaches these T&Cs or other rules and
										regulations of the Program or violated any applicable laws
										or regulations; or{' '}
									</p>{' '}
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-2"
									>
										(iii) in the Organizer’s sole determination, it believes
										that the participant has attempted to undermine the
										operation of the Program by fraud, cheating or deception.
									</p>
									<li
										className="mt-2 ml-3 subTitle fontSize-16"
										style={{ fontWeight: '600' }}
									>
										Liabilities and Responsibilities
									</li>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										a. The Organizer , its subsidiaries and affiliates, and
										their respective directors, officers, employees, agents and
										its Agency as well as all others associated with the
										development and execution of the Program ("
										<b>Released Parties</b>") assume no responsibility and shall
										not be held liable for any accident, harm, damage, losses
										(including but not limited to indirect or consequential
										loss) or charges, injuries, death, unforeseen circumstance,
										and any inconvenience or any other liabilities of any nature
										whatsoever arising through participation of this Program or
										in connection with the usage or possession of any redemption
										by anyone, nor for any charges in dates or in times or
										cancellations which may prevent the successful
										participant(s) from taking the original redemption offered
										or any part of the redemption, or for lost or stolen
										redemption (together, “the Claims”).
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										b. Participants waive and release the Organizer and the
										Released Parties from and against any and all liability with
										respect to the Claims except where prohibited or limited by
										law (in which case that liability is limited to the minimum
										permitted by law).
									</p>
									<li
										className="mt-2 ml-3 subTitle fontSize-16"
										style={{ fontWeight: '600' }}
									>
										{' '}
										Privacy Notice{' '}
									</li>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										a. The Organizer or its Agency collect your personal
										information, specifically your name, handphone number, POP
										and/or email address, to enable you to enter this Program
										and for related administration purposes. If you do not give
										us these details, you are unable to enter this Program.
										Subject to some exceptions permitted by law, you may request
										access to your personal information while we retain it.
										Reasons why will be given if access is denied. Your personal
										information will be securely destroyed once any statutory or
										other records retention period expires. By entering this
										Program, you consent to the processing or your personal
										information as described in these T&Cs including disclosing
										and/or publishing your personal information in any manner
										which Organizer may deem fit for publicity purposes. Each
										participant agrees to co- operate with and participate in
										any advertising or publicity activities conducted by the
										Organiser relating to the Program.{' '}
									</p>
									<p
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											fontSize: '15px',
											lineHeight: '20px',
										}}
										className="font-arial ml-1 mt-1"
									>
										b. Without prejudice to these T&Cs, all participants in the
										Program expressly and irrevocably permit and authorise the
										Organizer to disclose, reveal and divulge information
										regarding their personal information to the parties involved
										in organising, promoting and conducting the Program. By
										participating in the Program, the participants acknowledge
										that they are deemed to have agreed and accepted the
										Organiser's data protection and privacy policy with regards
										to the collection, processing, use, storing, and disclosure
										of the personal data set out in these T&Cs. For further
										information, please refer to{' '}
										<a
											style={{
												textDecoration: 'none',
												color: 'black',
												pointerEvents: 'auto',
											}}
											href="https://www.privacy.haleon.com/en-my/general/"
											target="_blank"
											rel="noreferrer"
										>
											https://www.privacy.haleon.com/en-my/general/
										</a>{' '}
										to read more about how your personal data is being used and
										your rights in relation to that data.
									</p>
									<p
										className="mt-2 fontSize-18 text-left"
										style={{ fontWeight: '600', lineHeight: '15px' }}
									>
										Contact Us{' '}
									</p>
									<p
										className="mt-1"
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											lineHeight: '20px',
										}}
									>
										For any enquiries, please reach out to our WhatsApp hotline
										(MY) at +6011-54125382 (Monday to Friday 9am to 5pm).
									</p>
									<p
										className="mt-1"
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											lineHeight: '20px',
										}}
									>
										Trademarks are owned by or licensed to the GSK group of
										companies. Any other trademarks, graphic symbols, logos,
										copyright or intellectual property contained in any
										materials used in connection with this Program, in
										particular that relating to the redemption, are the property
										of their respective owners. The Organiser and its agents are
										not affiliated with, or endorsed or sponsored by, such
										owners and their relevant affiliates.
									</p>
									{/* <p
										className="mt-1"
										style={{
											textAlign: 'justify',
											textJustify: 'inter-word',
											lineHeight: '20px',
										}}
									>
										These T&Cs shall be governed by the laws of SINGAPORE and
										the participants irrevocably submit to the exclusive
										jurisdiction of the courts of SINGAPORE
									</p> */}
								</ol>
							</div>
							) : (
								<div style={{ paddingBottom: '50px' }}>
								<p
									className="fontSize-16 text-left mt-2"
									style={{ fontWeight: '600', lineHeight: '15px' }}
								>
									Eligibility
								</p>
								<ol>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										The “Haleon Rewards Malaysia Program” (“Program”) is organized by Haleon Malaysia Sdn.Bhd. (formerly knowns as GlaxoSmithKline Consumer Healthcare Sdn Bhd) (Company No:195901000115 (3467-X)) (“Company” or “Haleon”).
									</li>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										Membership to the Program is open to all residents of Malaysia above 18 years of age (as at 1 March 2024), except the employees of the Company, its third-party agents and their immediate families, affiliates and/or related companies, distributors and advertising agencies.
									</li>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										Membership to the Program is limited to one account per individual.
									</li>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										By participating in the Program, you confirm that you meet the eligibility requirements and you have read, understood, accepted and agree to the terms and conditions (“T&Cs”) set out herein. The T&Cs constitute a legally binding agreement between you and the Company.
									</li>
									<li
										className="ml-3 mt-2"
										style={{ textAlign: 'justify', textJustify: 'inter-word' }}
									>
										The Company may at any time at its sole and absolute discretion, without notice or assigning any reason (a) substitute, withdraw, add to or alter any of the redemption criteria or redemption items; (b) suspend, withdraw or terminate the Program; or (c) delete, vary, supplement, amend or modify these T&Cs in such manner as the Company deems fit, including without limitation, the eligibility of participants, the bases and methods of identification of any successful participants, list of Participating Products and Stores, and any dates in connection with the Program and/or any redemption period.
									</li>
									{/* Definitions */}
									<p
										className="fontSize-18 text-left mt-2"
										style={{ fontWeight: '600' }}
									>
										{' '}
										Definitions{' '}
									</p>
									<ul>
										<li
											className="ml-3 mt-2"
											style={{ textAlign: 'justify', textJustify: 'inter-word' }}
										>
											“Participating Store(s)” means all physical and online stores that the Company may in its sole discretion determine from time to time.
										</li>
										<li
											className="ml-3 mt-2"
											style={{ textAlign: 'justify', textJustify: 'inter-word' }}
										>
											“Participating Product(s)” means the product from the Participating Brands, save for products that may be excluded by the Company from time to time
										</li>
										<li
											className="ml-3 mt-2"
											style={{ textAlign: 'justify', textJustify: 'inter-word' }}
										>
											“Participating Brand(s)” means participating brands of the Company in Malaysia, comprising of Sensodyne, Polident, Scott’s, Centrum, Caltrate and Panaflex. 
										</li>
										<li
											className="ml-3 mt-2"
											style={{ textAlign: 'justify', textJustify: 'inter-word' }}
										>
											“Ringgit” or “RM” means Ringgit Malaysia.
										</li>
									</ul>
									{/* Mechanics of the Program */}
									<p className="fontSize-18 text-left mt-4" style={{ fontWeight: '600' }}>
										Mechanics of the Program
									</p>
									{/* Rewards Points */}
									<p className="fontSize-16 text-left mt-2" style={{ fontWeight: '600', textDecoration: 'underline'  }}>
										Haleon Rewards Points
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										Haleon Rewards Points are earned and accumulated strictly through the submission of receipts from your purchases of Participating Products and through promotions that may be organized by the Company from time to time.
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										Each Ringgit spent on a Participating Product entitles the participating member to one (1) Haleon Rewards Point:
									<ul className="ml-3">
										<li>RM 1 = 1 Haleon Rewards Point</li>
									</ul>
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										All Cents are rounded down to the nearest Ringgit. For example:
										<ul className="ml-3">
											<li>RM 12.40 = 12 Haleon Rewards Points</li>
											<li>RM 12.60 = 12 Haleon Rewards Points</li>
										</ul>
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										Haleon Rewards Points do not have an expiry date. The accumulated Haleon Rewards Points can be used for the redemption of e-wallet rebates (Touch ‘n Go eWallet Reload PINs / GrabGifts Voucher / SarawakPay).
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										Haleon Rewards Points and all related privileges have no monetary value and are not transferable or exchangeable for (i) cash (whether in full or in part), (ii) non-designated products; or (iii) non-designated rewards; and may only be used by the participating members in accordance with these T&Cs
									</li>
									{/* Membership Tiers Validity & Terms */}
									<p className="fontSize-16 text-left mt-2" style={{ fontWeight: '600', textDecoration: 'underline' }}>
										Membership Tiers Validity & Terms
									</p>
									{/* table : Membership tiers */}
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										Membership tiers:
									</li>
									<table>
										<thead>
											<tr>
											<th style={{border: '1px solid black', padding: '8px'}}>Membership Tier</th>
											<th style={{border: '1px solid black', padding: '8px'}}>Haleon Rewards Points</th>
											</tr>
										</thead>
										<tbody>
											<tr>
											<td style={{border: '1px solid black', padding: '8px'}}>Bronze</td>
											<td style={{border: '1px solid black', padding: '8px'}}>0 – 249 accumulative Haleon Rewards Points</td>
											</tr>
											<tr>
											<td style={{border: '1px solid black', padding: '8px'}}>Silver</td>
											<td style={{border: '1px solid black', padding: '8px'}}>250 – 599 accumulative Haleon Rewards Points</td>
											</tr>
											<tr>
											<td style={{border: '1px solid black', padding: '8px'}}>Gold</td>
											<td style={{border: '1px solid black', padding: '8px'}}>600 – 999 accumulative Haleon Rewards Points</td>
											</tr>
											<tr>
											<td style={{border: '1px solid black', padding: '8px'}}>Platinum</td>
											<td style={{border: '1px solid black', padding: '8px'}}>1,000 and above accumulative Haleon Rewards Points</td>
											</tr>
										</tbody>
									</table>
									{/* table : Membership benefits */}
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										Membership benefits:
									</li>
									<table>
										<thead>
											<tr>
											<th style={{border: '1px solid black', padding: '8px'}}>Membership Tier</th>
											<th style={{border: '1px solid black', padding: '8px'}}>Benefits</th>
											</tr>
										</thead>
										<tbody>
											<tr>
											<td style={{border: '1px solid black', padding: '8px'}}>Bronze</td>
											<td style={{border: '1px solid black', padding: '8px'}}>
												Welcome Bonus: 10 Haleon Rewards Points 
												<br></br>
												Points Multiplier: 1x points multiplier
											</td>
											</tr>
											<tr>
											<td style={{border: '1px solid black', padding: '8px'}}>Silver</td>
											<td style={{border: '1px solid black', padding: '8px'}}>
												Welcome Bonus: 15 Haleon Rewards Points
												<br></br>
												Points Multiplier: 1.5x points multiplier
											</td>
											</tr>
											<tr>
											<td style={{border: '1px solid black', padding: '8px'}}>Gold</td>
											<td style={{border: '1px solid black', padding: '8px'}}>
												Welcome Bonus: 20 Haleon Rewards Points
												<br></br>
												Points Multiplier: 2x points multiplier
											</td>
											</tr>
											<tr>
											<td style={{border: '1px solid black', padding: '8px'}}>Platinum</td>
											<td style={{border: '1px solid black', padding: '8px'}}>
												Welcome Bonus: 25 Haleon Rewards Points
												<br></br>
												Points Multiplier: 3x points multiplier
											</td>
											</tr>
										</tbody>
									</table>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										Qualification for each membership tier is based on the total Haleon Rewards Points accumulated within a 12-months period:
									</li>
									<ul className="ml-3">
										<li>
											Participating members will be upgraded to a higher membership tier if their accumulative Haleon Rewards Points meet the minimum qualification for the next membership tier.
										</li>
										<li>
											Participating members will only be downgraded to a lower membership tier if they do not accumulate at least one (1) Haleon Rewards Point within a 12-months period from when they were last upgraded to their current membership tier (“Tier Cycle”).
											{/* Illustrations */}
											<ul className="ml-3">
											<li>
												(a) Participant A joined as a Haleon Rewards Member on 1 April 2024. Participant A accumulated 250 Haleon Rewards Points on 1 July 2024. Participant A will be upgraded to a Silver membership upon verification.
											</li>
											<li>
												(b) Participant A joined as Haleon Rewards Member on 1 April 2024. Participant A accumulated 200 Haleon Rewards Points on 1 July 2024. Subsequently, Participant A accumulates a further 50 Haleon Rewards Points on 2 April 2025. Participant A will remain at Bronze membership because the accumulation of points did not occur within a 12-months period.
											</li>
											<li>
												(c) Participant A is a Silver member since 1 July 2024. Participant A accumulates a further 350 Haleon Rewards Points on 1 February 2025. Participant A will be upgraded to a Gold membership upon verification.
											</li>
											<li>
												(d) Participant is a Gold member since 1 February 2025. Participant A did not accumulate any Haleon Rewards Points between 1 February 2025 and 31 January 2026 (i.e., a Tier Cycle). Participant A will be downgraded to a Silver membership at the end of the Tier Cycle.
											</li>
											<li>
												(e) Participant was downgraded to a Silver membership since 31 January 2026. Participant A did not accumulate any Haleon Rewards Points between 1 February 2026 and 31 January 2027 (i.e., a Tier Cycle). Participant A will be downgraded to a Bronze membership at the end of the Tier Cycle.
											</li>
											<li>
												(f) Participant A subsequently accumulates 250 Haleon Rewards Points on 2 March 2027. Participant A will be upgraded to a Silver membership upon verification.
											</li>
											</ul>
										</li>
									</ul>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										The use of any Haleon Rewards Points for the redemption of e-wallet rebates will not affect the participating member’s membership tier.
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
										Membership tiers have no monetary value and are not transferable or exchangeable for (i) cash (whether in full or in part), (ii) non-designated products; or (iii) non-designated rewards; and may only be used by the participating members in accordance with these T&Cs.
									</li>

									{/* How to Register for a Haleon Rewards Account */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
										How to Register for a Haleon Rewards Account
									</p>
									
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>Scan the QR code at any of the Participating Stores. The QR code will bring the participant to Haleon Rewards Malaysia log in and registration page.</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>Fill in your registration details and click on the “Submit” button. An OTP will be sent via SMS to your registered mobile number for verification purposes. Fill in the OTP verification details and click on the “verify” button.</li>
									
									{/* How to Redeem Haleon Rewards Points */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
										How to Redeem Haleon Rewards Points
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>Log in to your Haleon Rewards Account by using your registered mobile number. An OTP will be sent via SMS to your registered mobile number for verification purposes. Fill in the OTP verification details and click on the “verify” button.</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>Take a clear picture of the receipt issued by the Participating Store. If the purchase is made online, download or take a snapshot of the electronic receipt (“e-receipt”) issued by the Participating Store.</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>Upload a clear picture of the receipt or e-receipt. The picture uploaded must be of a valid original receipt which clearly sets out the name of the Participating Store, date of purchase, purchase price and quantity, receipt number, and description of the Participating Product. To qualify for Haleon Rewards Points, the receipt or e-receipt must be uploaded within seven (7) days from the date of purchase.</li>
									<ul className="ml-3">
										<li>(a) Participant A purchases Haleon Participating Products on 15 April 2024. Participant A can upload the receipt or e-receipt anytime on/or before 22 April 2024.</li>
									</ul>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>A notification will pop up to inform the participant when the receipt or e-receipt has been uploaded successfully. The receipt will be validated by the Company’s third-party vendor within 3 working days.</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>The participant will be notified of a successful validation via SMS. The participant’s Haleon Rewards Account will be credited with the Haleon Rewards Points that has been successfully validated.</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Notwithstanding the amount spent by the participant in any calendar month, and subject always to paragraph 7 above, each participant will only be entitled to a maximum of 500 base Haleon Rewards Points in each calendar month, subject to the Points Multiplier which the participant is entitled to (depending on the Membership Tier).
									</li>
									<ul className="ml-3">
										<p className="fontSize-16 text-left mt-1" style={{ fontWeight: '600' }}>
											Illustration:
										</p>
										<li className='mt-1'>(a) Participant A accumulates RM750.00 cumulative spending on Participating Products in the month of April 2024. Participant A submits the receipt and/or e-receipt onto the Haleon Rewards Account. Upon validation:</li>
										<ul className="ml-3 mt-2">
											<li>(i) If Participant A is a Silver member, Participant A will be credited with a total of 750 Haleon Rewards Points (500 base Haleon Rewards Points x 1.5x Points Multiplier).</li>
											<li>(ii) If Participant A is a Platinum member, Participant A will be credited with a total of 1500 Haleon Rewards Points (500 base Haleon Rewards Points x 3x Points Multiplier).</li>
										</ul>
									</ul>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Further details on the registration of a Haleon Rewards Account and redemption of Haleon Rewards Points can be obtained through [<a href='https://my.haleonrewards.com/help'>https://my.haleonrewards.com/help</a>]
									</li>

									{/* E-Wallet Rebates */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									E-Wallet Rebates
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The accumulated Haleon Rewards Points can be used to redeem e-wallet rebates. The type of e-wallet rebates is subject to availability at the time of redemption and may be substituted by the Company at any time without notice to the participant.
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The use and redemption of an e-wallet rebate is subject to the additional terms and conditions of the e-wallet vendor that offers the e-wallet rebate. All e-wallet rebates redeemed and received by the participants must be used before its expiry date and in accordance with its terms and conditions. The Company and the e-wallet rebate vendor will not entertain any claims by participants with an expired e-wallet rebate
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Company shall not be responsible or liable to the participants for any error, omission, interruption, deletion, defect, delay, misdirect, failure in operation or transmission, communications line failure, theft or destruction, unauthorized access to, or alternation of, user or member communications, or any problems or technical malfunction of any network, the website, computer online systems, servers or providers, computer equipment, system, apparatus, software, failure of receipt of entry on account of technical problems or traffic congestion on the internet and/or telecommunication services.
									</li>

									{/* Sign-Up Benefits */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									New Sign-Up Benefits (Badges)
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The registration of a new Haleon Rewards Account would entitle the participating member to sign-up benefits worth up to RM25 (“Sign-Up Benefits”):
									</li>
									<ul className="ml-3">
										<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>(i) Sensodyne, Polident, Scott’s, Centrum, and Caltrate = A RM5 e-wallet rebate (each) will be given with a minimum purchase of RM30 from each Participating Brand.</li>
										<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>(ii) Panaflex = 10 Haleon Rewards Points will be credited into your Haleon Rewards Account with a purchase of any Panaflex products.</li>
									</ul>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Sign-Up Benefits are only available to the first 25,000 successful redemptions and are based on a first come, first served basis.
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Sign-Up Benefits are valid for a period of 30 days from the date of registration of the new Haleon Rewards Account (“Sign-Up Benefits Validity Period”). The purchase of Participating Products and redemption of the Sign-Up Benefits must be made within the Sign-Up Benefits Validity Period.
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Each participating member is entitled to ONE (1) redemption for each Participating Brand. Each redemption of Sign-Up Benefits under the Participating Brand must be made through a single receipt issued by a Participating Store in Malaysia.
									</li>

									{/* Rights and Responsibilities */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									Rights and Responsibilities
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									By participating in the Program, the participants have accepted these T&Cs including any amendments, additions, replacements, variations and modifications thereto, and agree to be bound by these T&Cs. Non-compliance with or breach of any of these T&Cs may disqualify a participant at any stage of the Program, and any successful redemption may be forfeited, withheld, withdrawn or reclaimed at the Company’s sole option and absolute discretion. The decision of the Company is final and not subject to any appeal. 
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									By participating in this Program, the participants consent and give permission to the Company and/or its third-party agents to process, disclose, reveal or divulge the participants' personal data for verification and redemption purposes, and in any manner which the Company may deem fit for marketing and publicity purposes. 
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Company reserves the right to substitute any of the redemption items with that of similar value at any time at their absolute discretion without prior notice. The Company may, at its sole and absolute discretion, alter the requirements, scope, eligibility, and membership benefits for each membership tier and redemption of e-wallet rebates, and revoke, adjust and/or recalculate any Haleon Rewards Points awarded. 
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Participants will assume full liability in case of any injury, loss (including, without limitation, indirect or consequential loss), claim, or accident including property damage and loss of life resulting from participation in the Program, use of any systems or application in connection with the Program and/or use of any redemptions.
									</li>
									{/* Disqualification */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									Disqualification
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Redemption Program entries will be disqualified for multiple submissions using the same receipt, handwritten receipts, or any receipt without legible information on the name of the Participating Store, date of purchase, purchase price and quantity, receipt number, and description of the Participating Product.
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Company reserves the right, at its sole discretion, to disqualify and/or exclude participants and/or revoke redemptions if:
									<ul className="ml-3">
										<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>The participant is ineligible or does not meet any of the eligibility criteria set out above; or</li>
										<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>The participant breaches these T&Cs or other rules and regulations of the Program or violates any applicable laws or regulations; or</li>
										<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>based on the Company’s sole determination, the participant is found or suspected to have undermined or attempted to undermine the operation of the Program by fraud, cheating or deception.</li>
									</ul>
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Company reserves the right to pursue legal action against any individual it believes has engaged in fraudulent activities or activities that may be harmful to the Program.
									</li>

									{/* Liabilities and Responsibilities */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									Liabilities and Responsibilities
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Company, its subsidiaries and affiliates, and their respective directors, officers, employees, agents and its third party agents, as well as all other parties associated with the development and execution of the Program (“Released Parties”) assume no responsibility and shall not be held liable for any accident, harm, damage, losses (including but not limited to indirect or consequential loss) or charges, injuries, death, unforeseen circumstance, and any inconvenience or any other liabilities of any nature whatsoever arising through participation of this Program or use of the application or software, or in connection with the usage or possession of any redemption by anyone, nor for any charges in dates or in times or cancellations which may prevent the successful participant(s) from taking the original redemption offered or any part of the redemption, or for lost or stolen redemption (together, “the Claims”).
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Participants waive and release the Company and the Released Parties from and against any and all liability with respect to the Claims except where prohibited or limited by law (in which case that liability shall be limited to the minimum prescribed by law).
									</li>

									{/* Privacy Notice */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									Privacy Notice
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									The Company or its Agency may collect your personal information, specifically your name, handphone number, receipt and/or email address, to enable you to enter this Program and for related administration purposes, including the service of any notice under these T&Cs. Subject to exceptions permitted by law, you may request access to your personal information while we retain it. Reasons why will be given if access is denied. Your personal information will be securely destroyed once any statutory or other records retention period expires. 
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									By participating in this Program, you consent to the processing of your personal information as described in these T&Cs including disclosing and/or publishing your personal information in any manner which Company may deem fit for publicity and marketing purposes. Each participant agrees to co-operate with and participate in any advertising or marketing activities conducted by the Company relating to the Program.
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Without prejudice to these T&Cs, all participants in the Program expressly and irrevocably permit and authorise the Company to disclose, reveal and divulge information regarding their personal information to the parties involved in organising, promoting and conducting the Program
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									By participating in the Program, the participants agree and accept the Company's data protection and privacy policy with regards to the collection, processing, use, storing, and disclosure of personal data in accordance these T&Cs. For further information, please refer to <a href="https://www.privacy.haleon.com/en-my/general/">https://www.privacy.haleon.com/en-my/general/</a> to read more about how your personal data is being used and your rights in relation to that data.
									</li>

									{/* Trademarks */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									Trademarks
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Trademarks are owned by or licensed to the Haleon group of companies. Any other trademarks, graphic symbols, logos, copyright or intellectual property contained in any materials used inm connection with this Program, in particular that relating to the redemption, are the property of their respective owners. The Company and its agents are not affiliated with, or endorsed or sponsored by, such owners and their relevant affiliates.
									</li>

									{/* Miscellaneous */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									Miscellaneous
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Governing Law. These T&Cs shall be governed by the laws of Malaysia and the participants irrevocably submit to the exclusive jurisdiction of the courts of Malaysia.
									</li>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									Waiver. The failure of the Company to enforce any right or provision in the T&Cs shall not constitute a waiver of such right or provision unless acknowledged and agreed to by the Company in writing.
									</li>

									{/* Contact Us */}
									<p className="fontSize-18 text-left mt-2" style={{ fontWeight: '600' }}>
									Contact Us
									</p>
									<li className="ml-3 mt-2" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
									For any enquiries, or for providing us with necessary updates to your personal details, please call our hotline at 1800-88-3225 (Monday to Friday 9.00am to 5.00pm) or email: mystory.my@haleon.com
									</li>
								</ol>
							</div>
							)
						}
					</>
				) : null}
			</div>
		</div>
	);
};

export default HelpCenter;
