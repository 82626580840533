import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import axiosClient from '../../utils/axiosClient';
import jubileeImg from '../../images/jubileeImg.png';
import { useNavigate } from 'react-router';

const JubileeQuestionnaire = ({
	displayQues,
	setDisplayQues,
	jubileeCampaignId,
}) => {
	// const { t } = useTranslation();
	const navigate = useNavigate();
	// const [selectedOption, setSelectedOption] = useState(null);
	// const [selectedProducts, setSelectedProducts] = useState([]);
	// const [success, setSuccess] = useState();
	// const [error, setError] = useState();
	const [questionOne, setQuestionOne] = useState(false);
	const [questionTwo, setQuestionTwo] = useState(false);
	const [questionComplete, setQuestionComplete] = useState(false);
	const [preview, setPreview] = useState(true);
	const [answers, setAnswers] = useState({});

	const handleCloseFirstModal = () => {
		setPreview(false);
		setQuestionOne(true);
	};

	const handleCloseSecondModal = () => {
		setQuestionOne(false);
		setQuestionTwo(true);
	};

	const handleCloseThirdModal = () => {
		setQuestionTwo(false);
		setQuestionComplete(true);
	};

	const handleAnswer = (question, answer) => {
		setAnswers({ ...answers, [question]: answer });
		if (question === 'Are you a Watsons member?' && answer !== null) {
			handleCloseSecondModal();
		} else if (question === 'Are you above 50 years old?' && answer !== null) {
			handleCloseThirdModal();
		}
	};

	const handleSubmit = async () => {
		try {
			const res = await axiosClient.post('/campaign/questionnaire', {
				answers,
				campaignId: jubileeCampaignId,
			});
			if (res) {
				console.log('Submission successful:', res.data);
				// Perform any actions after successful submission
			}
		} catch (error) {
			console.error('Error submitting questionnaire:', error);
			// Handle error
		}
	};

	// const handleSubmit = async () => {
	// 	if (selectedOption) {
	// 		const remark = {
	// 			Question_Haleon_Rewards: t(
	// 				'Have you purchase any Haleon products in the past 6 months?'
	// 			),
	// 			Answer: { selectedOption },
	// 			'Product(s)': selectedOption === t('Yes') ? selectedProducts : 'none',
	// 		};

	// 		try {
	// 			if (selectedOption === t('Yes') && !selectedProducts.length) {
	// 				setError(t('Please select at least one product!'));
	// 				setTimeout(() => {
	// 					setError(null);
	// 				}, 1000);
	// 				return;
	// 			}

	// 			const res = await axiosClient.post('/campaign/questionnaire', {
	// 				remark: remark,
	// 				campaignId: jubileeCampaignId,
	// 			});

	// 			if (res) {
	// 				setSuccess(res.data.message);
	// 				setTimeout(() => {
	// 					setDisplayQues(false);
	// 				}, 1000);
	// 			}
	// 		} catch (err) {
	// 			setError(err.response.data.message);
	// 			console.error('error', err);
	// 		}
	// 	} else {
	// 		setError('Please select an option first!');
	// 		setTimeout(() => {
	// 			setError(null);
	// 		}, 1000);
	// 	}
	// };

	return (
		<div>
			<Modal
				open={preview}
				centered
				footer={null}
				closable={true}
				maskClosable={false}
				onCancel={() => setDisplayQues(false)}
				width={350}
			>
				<div className="text-center mt-2">
					<img
						src={jubileeImg}
						style={{ width: '65%', objectFit: 'contain' }}
					/>
					<h2 style={{ fontWeight: 'bold' }}>Special Only For You</h2>

					<p className="text-center fontSize-12">
						Golden Years, Golden Deals. Don't miss out your chance to get
						Watsons exclusive reward
					</p>
					<h2>Extra $5 E-Voucher!</h2>

					<Button
						className="coming-soon-button mt-1"
						onClick={handleCloseFirstModal}
						style={{ fontWeight: 'bold' }}
					>
						Claim !
					</Button>
				</div>
			</Modal>
			<Modal
				open={questionOne}
				centered
				footer={null}
				closable={false}
				maskClosable={false}
				// onCancel={() => setQuestionOne(false)}
				width={350}
			>
				<div className="text-center mt-2">
					<h2 style={{ fontWeight: '500' }}>Are you a Watsons member?</h2>

					<Button
						className="coming-soon-button mt-2"
						style={{ fontWeight: '600' }}
						onClick={() => handleAnswer('Are you a Watsons member?', 'Yes')}
					>
						Yes
					</Button>
					<Button
						className="mt-1"
						style={{
							width: '90%',
							background: '#D0D3D4',
							height: '35px',
							color: 'black',
							borderRadius: '5px',
							fontWeight: '600',
						}}
						onClick={() => handleAnswer('Are you a Watsons member?', 'No')}
					>
						No
					</Button>
				</div>
			</Modal>
			<Modal
				open={questionTwo}
				centered
				footer={null}
				closable={false}
				maskClosable={false}
				// onCancel={() => setQuestionOne(false)}
				width={350}
			>
				<div className="text-center mt-2">
					<h2 style={{ fontWeight: '500' }}>Are you above 50 years old?</h2>

					<Button
						className="coming-soon-button mt-2"
						style={{ fontWeight: '600' }}
						onClick={() => handleAnswer('Are you above 50 years old?', 'Yes')}
					>
						Yes
					</Button>
					<Button
						className="mt-1"
						style={{
							width: '90%',
							background: '#D0D3D4',
							height: '35px',
							color: 'black',
							borderRadius: '5px',
							fontWeight: '600',
						}}
						onClick={() => handleAnswer('Are you above 50 years old?', 'No')}
					>
						No
					</Button>
				</div>
			</Modal>
			<Modal
				open={questionComplete}
				centered
				footer={null}
				closable={false}
				maskClosable={false}
				// onCancel={() => setDisplayQues(false)}
				width={350}
			>
				<div className="text-center" style={{ paddingTop: '5%' }}>
					<h1>Thank you</h1>
					<h3 className="mt-1">You are entitled to the reward.</h3>
					<p className="fontSize-14" style={{ paddingTop: '5%' }}>
						You are entitle for ONE(1) <b>$5 Capita Voucher</b> on top of your
						submission reward.
					</p>
					<p
						className="mt-1"
						style={{ fontSize: '15px', fontWeight: '600', paddingTop: '5%' }}
					>
						Terms & Conditions:
					</p>
					<ol className="ml-1 fontSize-14" style={{ textAlign: 'justify' }}>
						<li>
							User should spend a minimum of $68 on participating Haleon
							products (Sensodyne, Polident, Parodontax, Caltrate, Centrum,
							Scotts) in a single receipt at Watsons Toa Payoh Hub OR Heartbeat
							@ Bedok outlets only.
						</li>
						<li>Campaign period: 6th May - 29th May 2024.</li>
						<li>Each user is only entitled to ONE(1) redemption.</li>
					</ol>
					{/* </p> */}
					<Button
						className="coming-soon-button mt-1"
						style={{ fontWeight: '600', width: '100%' }}
						// onClick={handleSubmit}
						onClick={() =>
							navigate('/upload', { state: { answers, jubileeCampaignId } })
						}
					>
						Upload Receipt
					</Button>
					<Button
						className="mt-1"
						style={{
							width: '100%',
							background: '#D0D3D4',
							height: '35px',
							color: 'black',
							borderRadius: '5px',
							fontWeight: '600',
						}}
						onClick={() => setDisplayQues(false)}
					>
						Back to Homepage
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default JubileeQuestionnaire;
