import { Button, Carousel, Modal } from 'antd';
import React, { useState } from 'react';

import purchase from '../../images/Haleon/Home/Purchase.png';
import upload from '../../images/Haleon/Home/Upload.png';
import earn from '../../images/Haleon/Home/Earn.png';
import unlock from '../../images/Haleon/Home/UnlockBenefits1.png';

import './HowToEarnPoints.css';
import { useTranslation } from 'react-i18next';

const HowToEarnPoints = ({ howTo, setHowTo }) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const onChange = (current) => {
		setCurrentSlide(current);
	};
	const { t } = useTranslation();
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');

	return (
		<div>
			<Modal
				open={howTo}
				centered
				footer={null}
				header={null}
				closable={false}
				width={330}
				onCancel={() => setHowTo(false)}
			>
				<div>
					<Carousel
						dots={true}
						slidesToScroll={true}
						afterChange={onChange}
						className="how-to-earn-points"
						// autoplay = {true}
					>
						<div>
							<img
								src={purchase}
								alt="purchase"
								className="w-100"
								style={{ objectFit: 'contain' }}
							/>
							<p
								className="text-center fontSize-24 font-weight-600 text-center"
								style={{ padding: '5%' }}
							>
								{t('Purchase')}
							</p>
							<hr
								style={{
									backgroundColor: '#30EA03',
									height: '3px',
									border: 'none',
									margin: 'auto',
									width: '40%',
								}}
							/>
							{getLanguage !== 'en' ? (
								<>
									<p
										className="fontSize-14 font-weight-400"
										style={{ marginTop: '5%', textAlign: 'left' }}
									>
										{t('Purchase description')}
									</p>
								</>
							) : (
								<>
									<p
										className="fontSize-14 font-weight-400"
										style={{ marginTop: '5%', textAlign: 'left' }}
									>
										Purchase any Haleon participating products from
										participating retail outlets,
									</p>
									<p
										className="fontSize-14 font-weight-400"
										style={{ textAlign: 'left' }}
									>
										pharmacies or e-stores.
									</p>
								</>
							)}
						</div>

						<div className="text-center">
							<img src={upload} alt="upload" className="w-100" />
							<p
								className="text-center fontSize-24 font-weight-600 text-center"
								style={{ padding: '5%' }}
							>
								{t('Upload')}
							</p>
							<hr
								style={{
									backgroundColor: '#30EA03',
									height: '3px',
									border: 'none',
									margin: 'auto',
									width: '30%',
								}}
							/>
							{getLanguage !== 'en' ? (
								<>
									<p
										className="fontSize-14 font-weight-400 text-center"
										style={{ marginTop: '3%', textAlign: 'left' }}
									>
										{t('Upload description')}
									</p>
									<br />
								</>
							) : (
								<>
									<p
										className="fontSize-14 font-weight-400 text-center"
										style={{ marginTop: '5%', textAlign: 'left' }}
									>
										Snap and upload a photo of your physical
									</p>
									<p
										className="fontSize-14 font-weight-400 text-center"
										style={{ textAlign: 'left' }}
									>
										receipt or screenshot the e-receipt of your
									</p>
									<p
										className="fontSize-14 font-weight-400 text-center"
										style={{ textAlign: 'left' }}
									>
										online purchase.
									</p>
								</>
							)}
						</div>

						<div className="text-center">
							<img src={earn} alt="earn" className="w-100" />
							<p
								className="text-center fontSize-24 font-weight-600 text-center"
								style={{ padding: '5%' }}
							>
								{t('Earn')}
							</p>
							<hr
								style={{
									backgroundColor: '#30EA03',
									height: '3px',
									border: 'none',
									margin: 'auto',
									width: '20%',
								}}
							/>
							{getLanguage !== 'en' ? (
								<>
									<p
										className="fontSize-14 font-weight-400 text-center"
										style={{ marginTop: '3%' }}
									>
										{' '}
										{t('Earn description')}
									</p>
									<br />
								</>
							) : (
								<>
									<p
										className="fontSize-14 font-weight-400 text-center"
										style={{ marginTop: '5%' }}
									>
										For each {isSGRegion ? '$' : 'RM'}1 spent, you will be
										awarded
									</p>
									<p className="fontSize-14 font-weight-400 text-center">
										with 1 point!
									</p>
								</>
							)}
						</div>

						<div className="text-center">
							<img
								src={unlock}
								alt="unlock"
								className="w-100"
								style={{ paddingTop: '5%' }}
							/>
							<p
								className="text-center fontSize-24 font-weight-600 text-center"
								style={{ padding: '5%' }}
							>
								{t('Redeem Rewards')}
							</p>
							<hr
								style={{
									backgroundColor: '#30EA03',
									height: '3px',
									border: 'none',
									margin: 'auto',
									width: '65%',
								}}
							/>
							<p
								className="fontSize-14 font-weight-400 text-center"
								style={{ marginTop: '5%' }}
							>
								{t('Convert your points into rewards')}
							</p>
						</div>
					</Carousel>
				</div>
				<div style={{ marginTop: '10%' }}>
					{currentSlide === 3 && (
						<Button
							type="primary"
							onClick={() => setHowTo(false)}
							style={{
								fontSize: '16px',
								borderRadius: '8px',
								background: '#30E700',
								color: 'black',
								border: 'none',
								fontWeight: '500',
								height: '35px',
								width: '100%',
							}}
						>
							{t('Sounds Good')}!
						</Button>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default HowToEarnPoints;
