import { Modal, Button } from 'antd';
import guardian_large from '../../../../images/Haleon/guardian_large.png';
import { useTranslation } from 'react-i18next';

const HowToRedeemGuardian = ({ open, onClose }) => { 
	const { t } = useTranslation('translation', {
		keyPrefix: 'rewards.modal.guardian',
	  });
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');
	return (
		<Modal open={open} centered footer={null} closable={false} width={350}>
			<div className="text-center">
				<img style={{ width: '50%' }} src={guardian_large} alt="" />
				<div className="mt-1" style={{ lineHeight: '25px' }}>
					<h3
						style={{
							fontSize: '20px',
							width: 'auto',
						}}
					>
						{getLanguage === 'bm' ? (
							<>Cara Menebus PIN Tambah Nilai Touch ‘n Go eWallet Anda?</>
						) : getLanguage === 'cn' ? (
							<>如何兑换您的 Touch 'n Go eWallet 充值码？</>
						) : (
							<>
								How to Redeem Your <br />
								Guardian Voucher? 
							</>
						)}
					</h3>
				</div>
				<div className="mt-2">
					<ol
						style={{
							padding: '0 15px',
							fontSize: '14px',
							textAlign: 'justify',
							textJustify: 'auto',
						}}
					>
						<li className="my-1">
							{t('how_to.0')}
						</li>
						<li className="my-1">
							{t('how_to.1')}
						</li>
						<li className="my-1">
							{t('how_to.2')}
						</li>
					</ol>
				</div>
				<Button
					type="primary"
					className="mt-1"
					onClick={() => onClose()}
					style={{
						textTransform:"uppercase",
						fontSize: '12px',
						borderRadius: '8px',
						background: '#CB0E07',
						color: 'white',
						border: 'none',
						fontWeight: '500',
						height: '40px',
						width: '90%',
					}}
				>
					{t('close')}
				</Button>
			</div>
		</Modal>
	);
};

export default HowToRedeemGuardian;
