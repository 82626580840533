import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
// import article1 from "../../images/Haleon/article1.svg";
import article2 from '../../images/Haleon/article2.jpg';
import greyCircle from '../../images/Haleon/greyCircle.svg';
// import whatsappIcon from "../../images/Haleon/Home/whatsappIcon.png";
import ComingSoon from '../../components/Modal/ComingSoon';
import '../../css/Article.css';
import SubHeader from '../../components/SubHeader';
import axiosClient from '../../utils/axiosClient';

const Article = () => {
	const navigate = useNavigate();
	const [isSoon, setIsSoon] = useState(false);
	const [article, setArticle] = useState('All');
	const [info, setInfo] = useState();
	const filteredArticles = article === 'All' ? info : info.filter((item) => item.type === article);

	useEffect(() => {
		const check = async () => {
			try {
				const res = await axiosClient.get('/articles/get');
				// console.log(res.data.data);
				setInfo(res.data.data);
				// setUserData(res.data.data.personalInfo);
			} catch (error) {
				// navigate(`/login?store=${store}`);
			}
		};
		check();
	}, []);

	return (
		<div>
			<SubHeader setPreviousPage={-1} />

			<div className="mt-1 ml-2 mr-2">
				<div className="mt-2">
					<p className="article-font">Articles</p>
				</div>

				<div
					className="mt-2 scroll-img"
					style={{
						justifyContent: 'space-evenly',
						display: 'flex',
						gap: '8px',
					}}
				>
					<Button
						className={
							article === 'All'
								? 'redeemItem-active-btn'
								: 'redeemItem-inactive-btn'
						}
						style={{ flex: 1, minWidth: '100px' }}
						onClick={() => setArticle('All')}
					>
						All
					</Button>

					{info && info.length &&
						[...new Set(info.map((item) => item.type))].map((type, index) => (
							<Button
								key={index}
								className={
									article === type
										? 'redeemItem-active-btn'
										: 'redeemItem-inactive-btn'
								}
								style={{ flex: 1, minWidth: '100px' }}
								onClick={() => setArticle(type)}
							>
								{type}
							</Button>
						))}
				</div>

				<div
					className="mt-2"
					style={{
						overflowX: 'auto',
						overflowY: 'hidden',
						paddingBottom: '30px',
					}}
				>
					<div className="grid-container">
						{filteredArticles && filteredArticles.length > 0 ? (
							filteredArticles.map((ele) => (
								<div key={ele.id}>
									<div className="item1 article-container">
										<img
											src={article2}
											alt="yoga_pose"
											className="img-adjustment w-100"
										/>
										<div>
											<div style={{ width: '100%', height: 'auto' }}>
												<p
													className="ml-1"
													style={{
														color: '#30E700',
														fontSize: '12px',
														fontWeight: '500',
													}}
												>
													{ele.type}
												</p>
												<p
													className="article-title-font text-left ml-1"
													style={{ marginTop: '5px' }}
												>
													{ele.title.toUpperCase()}
												</p>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<p>No articles available, please check back later</p>
						)}
					</div>
				</div>
			</div>

			<div>
				<ComingSoon isSoon={isSoon} setIsSoon={setIsSoon} />
			</div>
		</div>
	);
};

export default Article;
