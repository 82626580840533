import '../../css/UploadReceipt.css';
import { Button, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SampleReceipt from '../../components/Modal/SampleReceipt';
import SubHeader from '../../components/SubHeader';
import successImg from '../../images/Haleon/checkmark.png';
import uploadreceipt from '../../images/Haleon/uploadReceipt.png';
import deleteBtn from '../../images/Haleon/deleteBtn.png';
import axiosClient from '../../utils/axiosClient';
// import VoucherUnavailable from '../../components/Modal/VoucherUnavailable';

const Upload = ({ t }) => {
	const uploadref = useRef();
	const location = useLocation();
	const navigate = useNavigate();
	const [imageFile, setImageFile] = useState();
	const [imageUrl, setImageUrl] = useState('');
	const [checkImage, setCheckImage] = useState(false);
	const [success, setSuccess] = useState(false);
	const [rewardTypeId, setRewardTypeId] = useState('');
	const [uploadSample, setUploadSample] = useState(false);
	const resubmitCamEntryData = location.state?.receiptData;
	const isResubmit = resubmitCamEntryData && resubmitCamEntryData?.id;
	const [isLoading, setIsLoading] = useState(true);
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const uploadReceiptForPointRewardCampaignId = isSGRegion
		? 'c898ecf5-4ddc-4702-813f-c8f557b5b74e'
		: '1a82cf7d-1e40-44a9-8687-815855835b9d';
	const uploadReceiptForVoucherRewardCampaignId =
		'af612ab0-4dfc-4e77-9ada-aca813ab4b21';
	const [rewardOptions, setRewardOptions] = useState([]);
	const [campaign, setCampaign] = useState(null);
	const [isEligible, setIsEligible] = useState(false);
	const [showOutOfStocksModal, setShowOutOfStocksModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [prevCamEntry, setPrevCamEntry] = useState();
	// const [searchParams] = useSearchParams();
	// const prevCamEntryId = searchParams.get("prevCamEntryId");
	// const isResubmit = prevCamEntry && prevCamEntryId;
	const userToken = localStorage.getItem(`HALEON-REWARDS_USER_TOKEN`);

	useEffect(() => {
		if (!prevCamEntry) return;

		// if (prevCamEntry?.selectedRewardIds?.length) {
		//   setUserSelectedRewardId(prevCamEntry?.selectedRewardIds[0]);
		// }

		// if (prevCamEntry?.campaignInput?.receiptAmount) {
		//   setReceiptAmount(prevCamEntry?.campaignInput?.receiptAmount);
		// }
	}, [prevCamEntry]);

	const fetchCampaigns = async () => {
		try {
			// const voucherRewardCampaignRes = await axiosClient.get(
			// 	`/campaigns/${uploadReceiptForVoucherRewardCampaignId}/validity`
			// );

			// const rewards = voucherRewardCampaignRes?.data?.data?.reward||[]
			// const isAllVoucherOutOfStocks = rewards
			// 	// filter points rewards
			// 	.filter((reward) => reward.type !== 'WALLET')
			// 	// check if all voucher rewards are out of stocks
			// 	.every((reward) => reward.qtyRemaining <= 0);

			// 	if (voucherRewardCampaignRes.data.data.remainingEntry > 0 &&isAllVoucherOutOfStocks) {
			// 		setShowOutOfStocksModal(true);
			// 	}

			// if (
			// 	voucherRewardCampaignRes.data.data.remainingEntry > 0 &&
			// 	!isAllVoucherOutOfStocks
			// ) {
			// 	setIsEligible(voucherRewardCampaignRes.data.data.isValid);
			// 	setCampaign(voucherRewardCampaignRes.data.data.campaign);

			// 	if (
			// 		voucherRewardCampaignRes.data.data.reward &&
			// 		Array.isArray(voucherRewardCampaignRes.data.data.reward)
			// 	) {
			// 		setRewardOptions(
			// 			// reward with wallet type, is default reward
			// 			voucherRewardCampaignRes.data.data.reward.filter(
			// 				(reward) => reward.type !== 'WALLET'
			// 			)
			// 		);
			// 	}
			// } else {
			const pointRewardCampaignRes = await axiosClient.get(
				`/campaigns/${uploadReceiptForPointRewardCampaignId}/validity`
			);
			if (pointRewardCampaignRes.data.data) {
				setIsEligible(pointRewardCampaignRes.data.data.isValid);
				pointRewardCampaignRes.data.data.campaign &&
					setCampaign(pointRewardCampaignRes.data.data.campaign);
			}

			if (location && location.state && location.state.jubileeCampaignId) {
				const voucherRewardCampaignRes = await axiosClient.get(
					`/campaigns/${uploadReceiptForVoucherRewardCampaignId}/validity`
				);

				const rewards = voucherRewardCampaignRes?.data?.data?.reward || [];
				const isAllVoucherOutOfStocks = rewards
					// filter points rewards
					.filter((reward) => reward.type !== 'WALLET')
					// check if all voucher rewards are out of stocks
					.every((reward) => reward.qtyRemaining <= 0);

				if (
					voucherRewardCampaignRes.data.data.remainingEntry > 0 &&
					isAllVoucherOutOfStocks
				) {
					setShowOutOfStocksModal(true);
				}

				if (
					voucherRewardCampaignRes.data.data.remainingEntry > 0 &&
					!isAllVoucherOutOfStocks
				) {
					setIsEligible(voucherRewardCampaignRes.data.data.isValid);
					setCampaign(voucherRewardCampaignRes.data.data.campaign);

					if (
						voucherRewardCampaignRes.data.data.reward &&
						Array.isArray(voucherRewardCampaignRes.data.data.reward)
					) {
						setRewardOptions(
							// reward with wallet type, is default reward
							voucherRewardCampaignRes.data.data.reward.filter(
								(reward) => reward.type !== 'WALLET'
							)
						);
					}
				}
			}
			// }
		} catch (err) {
			console.error('fetchCampaigns: ', err);
			message.error(err?.message || 'Error occur on fetching campaigns');
		} finally {
			setIsLoading(false);
		}
	};

	const fetchCampaignEnty = async (_camEntryId) => {
		axiosClient
			.get(`/campaign-entries/${_camEntryId}`)
			.then((res) => {
				if (res.data.data) {
					setCampaign(res.data.data.campaign);
				} else {
					throw new Error('Error on fetching campaign entry.');
				}

				if (!['CANCELLED', 'REJECTED'].includes(res.data.data.status)) {
					// if entry is not cancelled, user are not allow to resubmit
					setErrorMessage('Previous entry is still pending.');
					setIsEligible(false);
				} else {
					// if entry is cancelled, user are allow to resubmit
					setIsEligible(true);
				}
			})
			.catch((err) => {
				console.error('Error occurred:', err);
				if (err.response && err.response.data && err.response.data.message) {
					setErrorMessage(err.response.data.message);
				} else {
					setErrorMessage('An error occurred while fetching data.');
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (!userToken) {
			navigate('/welcome' + window.location.search);
		} else {
			isResubmit
				? fetchCampaignEnty(resubmitCamEntryData?.id)
				: fetchCampaigns();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userToken]);

	const handleDisplayImage = (e) => {
		let render = new FileReader();
		const supportedImgFormat = ['image/jpeg', 'image/jpg', 'image/png'];
		errorMessage && setErrorMessage(null);
		if (e.target.files[0]) {
			const selectedFile = e.target.files[0];
			if (supportedImgFormat.includes(selectedFile.type)) {
				setCheckImage(false);
				setImageFile(selectedFile);
				render.readAsDataURL(selectedFile);
				render.onload = (res) => {
					setImageUrl(res.target.result);
				};
			} else {
				setErrorMessage('Please upload a JPEG, JPG, or PNG image only!');
				setTimeout(() => {
					setErrorMessage('');
				}, 1000);
			}
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		errorMessage && setErrorMessage(null);
		if (!imageFile) {
			setErrorMessage('Please upload your receipt image!');
			return;
		}
		// if (
		// 	// on resubmit, user are not allow to change reward
		// 	!isResubmit
		// 	 &&
		// 	campaign.id === uploadReceiptForVoucherRewardCampaignId &&
		// 	!rewardTypeId
		// ) {
		// 	setErrorMessage('Please select a reward!');
		// 	return;
		// }
		const data = new FormData();
		data.append('image', imageFile);
		setIsLoading(true);

		let campaignId = campaign.id;
		try {
			if (location?.state?.jubileeCampaignId && location.state?.answers) {
				await axiosClient.post('/campaign/questionnaire', {
					remark: location.state.answers,
					campaignId: location.state.jubileeCampaignId,
				});

				campaignId = location.state.jubileeCampaignId;
			}

		const res = await axiosClient
			.post(`/campaigns/${campaignId}/upload-image`, data, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				if (res.data.data.url && res.data.data.key) {
					let entryData = {
						campaignId,
						type: 'RECEIPT',
						selectedRewardIds: rewardTypeId ? [rewardTypeId] : [],
						// selectedRewardIds: [],
						data: {
							imageData: {
								url: res.data.data.url,
								key: res.data.data.key,
							},
							// for resubmit entry
							...(isResubmit && {
								prevCampaignEntryId: resubmitCamEntryData.id,
							}),
						},
					};

					await axiosClient.post(`/campaigns/entries`, entryData);
				} else {
					throw new Error('Error on uploading image.');
				}
			
				setSuccess(true);
		} catch (err) {
				console.error('Error submitting questionnaire:', err);
				if (err.response && err.response.data && err.response.data.message) {
					setErrorMessage(err.response.data.message);
				} else {
					setErrorMessage('An error occurred while processing data.');
				}
			} finally {
				setIsLoading(false);
			}
	};

	const handleImageClick = (_id) => {
		errorMessage && setErrorMessage(null);
		setRewardTypeId(_id);
	};

	return (
		<div
			style={{
				opacity: isLoading ? '0.5' : 1,
				pointerEvents: isLoading ? 'none' : 'auto',
			}}
		>
			<SubHeader setPreviousPage={-1} />

			{/* <VoucherUnavailable
				setView={setShowOutOfStocksModal}
				view={showOutOfStocksModal}
			/> */}

			<div className="mt-1">
				<hr
					style={{
						height: '1px',
						backgroundColor: 'lightgray',
						border: 'none',
					}}
				/>
			</div>
			<div className="ml-2 mr-2">
				<div className="mt-2">
					<h2>{t('Upload Receipt')}</h2>
				</div>
				{imageUrl ? (
					<div style={{ position: 'relative' }} className="mt-3">
						<div
							className="receipt-container"
							style={{
								height: '410px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<img
								src={imageUrl}
								alt="receipt"
								style={{
									width: '70%',
									height: '70%',
									objectFit: 'contain',
								}}
							/>
							<img
								src={deleteBtn}
								alt="delete_btn"
								style={{
									width: '35px',
									cursor: 'pointer',
									objectFit: 'contain',
									marginTop: '5%',
								}}
								onClick={() => {
									setImageFile(null);
									setImageUrl('');
								}}
							/>
						</div>
					</div>
				) : (
					<div style={{ position: 'relative' }}>
						<div className="receipt-container mt-3" style={{ height: '310px' }}>
							<div>
								<div
									style={{ alignItems: 'center' }}
									onClick={() => uploadref.current.click()}
								>
									<img
										src={uploadreceipt}
										alt=""
										style={{
											width: '160px',
											height: '160px',
											objectFit: 'contain',
										}}
									/>
									<p
										className="text-center mt-2"
										style={{ fontSize: '24px', fontWeight: '600' }}
									>
										{t('Upload your receipt')}
									</p>
									<p
										className="text-center"
										style={{ fontSize: '16px', fontWeight: '400' }}
									>
										PNG, JPEG {t('or')} JPG
									</p>
								</div>
								<p
									style={{
										textDecoration: 'underline',
										fontSize: '16px',
										fontWeight: '400',
									}}
									className="text-center"
									onClick={() => setUploadSample(true)}
								>
									{t('Sample Receipt')}
								</p>
							</div>
						</div>
					</div>
				)}

				{/* {imageUrl ? (
					<div className="mt-3">
						<div
							style={{
								position: 'relative',
							}}
							className="after-upload-receipt-container"
						>
							<img
								src={deleteBtn}
								alt="delete_btn"
								style={{
									width: '35px',
									position: 'absolute',
									top: '20px',
									right: '15px',
									cursor: 'pointer',
								}}
								onClick={() => {
									setImageFile(null);
									setImageUrl('');
								}}
							/>
							<img
								src={imageUrl}
								alt="receipt"
								// onClick={() => uploadref.current.click()}
								style={{ width: '100%' }}
							/>
						</div>
					</div>
				) : (
					<div style={{ position: 'relative' }}>
						<div className="receipt-container mt-3">
							<div
								style={{ alignItems: 'center' }}
								onClick={() => uploadref.current.click()}
							>
								<img src={uploadreceipt} alt="" style={{ width: '22%' }} />
								<p>Upload a photo of your receipt</p>
							</div>
							<div>
								<img
									src={sample}
									alt=""
									className="sample-icon cursor-pointer"
									onClick={() => setUploadSample(true)}
								/>
							</div>
						</div>
					</div>
				)} */}

				<div>
					<input
						type="file"
						name="receiptImage"
						accept="image/jpeg, image/jpg, image/png"
						ref={uploadref}
						onChange={(e) => handleDisplayImage(e)}
						style={{ width: '0px', height: '0px', opacity: '0' }}
						required
					/>
				</div>

				{checkImage && (
					<span
						style={{
							color: '#ff4d4f',
							fontSize: '14px',
							textAlign: 'center',
							display: 'block',
							position: 'relative',
							bottom: '11px',
						}}
					>
						Please upload your receipt image!
					</span>
				)}

				{/* {rewardOptions.length > 0 &&
					// on resubmit, user are not allow to select reward
					!isResubmit && (
						<>
							<div className="mt-2">
								<h3>Select your reward</h3>
							</div>

							<div className="img-container">
								{rewardOptions.map((reward, index) => {
									const isOutOfStock = reward.qtyRemaining <= 0;

									return (
										<div
											key={index}
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignContent: 'end',
												padding: '20px',
											}}
										>
											<img
												src={reward.imageUrl}
												onClick={() =>
													!isOutOfStock ? handleImageClick(reward.id) : () => {}
												}
												style={{
													cursor: 'pointer',
													opacity:
														rewardTypeId !== reward.id || isOutOfStock
															? '0.5'
															: 1,
												}}
												alt=""
											/>
											{isOutOfStock && (
												<p
													style={{
														color: 'red',
														textAlign: 'center',
														fontSize: '14px',
														marginTop: '10px',
													}}
												>
													Out of stock.
												</p>
											)}
										</div>
									);
								})}
							</div>
						</>
					)} */}

				{/* {!isResubmit && (
					<div className="mt-1 text-left">
						<p className="fontSize-14" style={{ fontWeight: '400' }}>
							*The RM5 rewards are only eligible for every minimum spend of RM38
							on participating products, up to a maximum of 3 redemptions a
							month.
						</p>
					</div>
				)} */}

				{/* {!isResubmit && !isEligible && (
					<div className="mt-1">
						<p
							className="fontSize-14 text-center"
							style={{ fontWeight: '400' }}
						>
							You have selected maximum allocation of rewards this month.
						</p>
					</div>
				)} */}

				{errorMessage && (
					<p
						style={{
							color: 'red',
							textAlign: 'center',
							fontSize: '14px',
							marginTop: '10px',
						}}
					>
						{errorMessage}
					</p>
				)}
				<div className="center">
					<Button
						className="submit-receipt-btn fontSize-16"
						disabled={!isEligible}
						onClick={onSubmit}
						loading={isLoading}
					>
						{t('Submit')}
					</Button>
				</div>
			</div>

			<div>
				<Modal
					open={success}
					centered
					header={null}
					footer={null}
					onCancel={() => setSuccess(false)}
					closable={false}
					width={300}
				>
					<div className="text-center">
						<img
							style={{ width: '20%' }}
							src={successImg}
							alt=""
							className="submit-success-icon"
						></img>
						<div className="submit-receipt-success-header">{t('Success')}!</div>
						<div className="submit-pending-modal-font mt-1">
							<p className="text-center">
								{t(
									'Your receipt has been successfully uploaded and will be validated within 3 working days.'
								)}
							</p>
						</div>
						<Button
							className="receipt-success-button mt-1"
							style={{ backgroundColor: '#02BC7D' }}
							onClick={() => navigate('/home')}
						>
							{t('Done')}
						</Button>
					</div>
				</Modal>
			</div>

			<div>
				<SampleReceipt
					uploadSample={uploadSample}
					setUploadSample={setUploadSample}
				/>
			</div>
		</div>
	);
};

export default Upload;
