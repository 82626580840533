import { Modal, Button } from 'antd';
import capitaVoucher from '../../images/Haleon/capitaVoucher.svg';
const HowToRedeemCapitaVoucher = ({
	redeemCapitaVoucher,
	setRedeemCapitaVoucher,
}) => {
	return (
		<div>
			<Modal
				open={redeemCapitaVoucher}
				centered
				footer={null}
				closable={false}
				width={350}
			>
				<div className="text-center">
					<img
						style={{ width: '180px', height: '58px' }}
						src={capitaVoucher}
						alt={'capitaVoucher'}
					/>
					<div className="mt-1" style={{ lineHeight: '25px' }}>
						<h3
							style={{
								fontSize: '20px',
								width: 'auto',
							}}
							className="text-center"
						>
							How To Redeem Your
							<br /> Capita Voucher?
						</h3>
					</div>
					<div className="mt-2">
						<ol
							style={{
								padding: '0 15px',
								fontSize: '14px',
								textAlign: 'justify',
							}}
						>
							<li>
								Log in to the CapitaStar app and tap on your eCapitaVoucher
								balance at the top of the screen
							</li>
							<li>Select ‘Accept Gift’</li>
							<li>Enter the Reward Code and select ‘Accept Gift’</li>
						</ol>
					</div>
					<Button
						type="primary"
						className="mt-1"
						onClick={() => setRedeemCapitaVoucher(false)}
						style={{
							fontSize: '12px',
							borderRadius: '8px',
							background: '#30E700',
							color: 'black',
							border: 'none',
							fontWeight: '500',
							height: '40px',
							width: '90%',
						}}
					>
						Close
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default HowToRedeemCapitaVoucher;
