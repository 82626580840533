import React, { useEffect, useState } from 'react';
import { Form, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Modal, message } from 'antd';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import '../../css/Address.css';
import SubHeader from '../../components/SubHeader';
import axiosClient from '../../utils/axiosClient';
import confirmationIcon from '../../images/Haleon/confirmation.svg';

const AddressMainPage = ({t}) => {
	const navigate = useNavigate();
	// const [params] = useSearchParams();
	// const [submit, setSubmit] = useState(false);
	// const [allAddress, setAllAddress] = useState([]);
	const [deletedAddresses, setDeletedAddresses] = useState([]);
	const [addr, setAddr] = useState([]);
	const [error, setError] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');

	const checkUserAddr = async () => {
		try {
			const res = await axiosClient.get('/users/details');
			const activeAddr = res.data.data.addressInfo.filter(
				(address) => !address.deletedAt
			);
			setAddr(activeAddr);
		} catch (error) {
			setError(error.res.data.error);
			console.error('error', error);
		}
	};

	useEffect(() => {
		checkUserAddr();
	}, []);

	const deleteAddress = async (item) => {
		setIsDelete(false);
		try {
			await axiosClient.post('/address/update', {
				addrUpdate: item,
				addrDelete: true,
			});

			item.markedForDeletion = true;
			setAddr((prevAddresses) =>
				prevAddresses.filter((address) => address.id !== item.id)
			);

		} catch (error) {
			console.error('Error deleting address:', error);
		}
	};

	return (
		<div>
			<SubHeader setPreviousPage={'/profile'} />

			<div className="mt-1">
				<hr />
			</div>

			<div className="ml-3 mr-3 mt-2">
				<div className="d-flex" style={{ justifyContent: 'space-between' }}>
					<p className="delivery-details-font">{t('Delivery Details')}</p>
					<p className="max-address-font">{t('Max. 5 Addresses')}</p>
				</div>

				{addr.map((ele, index) => {
					if (deletedAddresses.includes(ele.id)) {
						return null;
					}

					return (
						<div className="mt-2" key={index}>
							<div
								style={{
									boxShadow:
										'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
									borderRadius: '15px',
									height: '130px',
									position: 'relative',
								}}
							>
								<div style={{ justifyContent: 'left' }}>
									<div className="reward-card">
										<div style={{ textAlign: 'left' }}>
											<p className="fontSize-16">{ele?.name || 'N/A'}</p>
											<p
												className="fontSize-14"
												style={{ color: '#64656A', width: '100%' }}
											>
												{ele.line1} {ele.line2},<br />
												{ele.postcode}, {ele.city}
											</p>
											<p className="fontSize-14" style={{ color: '#64656A' }}>
												{ele.phone}
											</p>
										</div>
									</div>
								</div>
								<div
									style={{ textAlign: 'right' }}
									onClick={() => setIsDelete(true)}
									// onClick={() => deleteAddress(ele)}
								>
									<div
										className="delete-address-btn"
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<DeleteOutlined />
									</div>
								</div>

								<div
									style={{ textAlign: 'right' }}
									onClick={() => navigate('/update/address', { state: ele })}
									// onClick = {()=> console.log("ele", ele)}
								>
									<div
										className="edit-address-btn"
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<FormOutlined />
										<span
											style={{ marginLeft: '5px', fontWeight: '420', fontSize: getLanguage === 'bm' ? '10px' : '12px' }}
										>
											{t('Edit Delivery Address')}
										</span>
									</div>
								</div>
							</div>
							<Modal
								open={isDelete}
								centered
								footer={null}
								width={300}
								closable={false}
								// onCancel={() => setIsDelete(false)}
							>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<img
										src={confirmationIcon}
										alt=""
										style={{ width: '20%' }}
									></img>
								</div>
								<br />
								<div
									style={{
										fontWeight: 'bold',
										fontSize: '20px',
										textAlign: 'center',
									}}
								>
									Are you sure you want to delete this address info?
								</div>
								<div style={{ display:'flex', justifyContent:'center', gap:'10px' }}>

								<Button
									onClick={() => deleteAddress(ele)}
									style={{
										textAlign: 'center',
										// display: 'flex',
										// justifyContent: 'center',
										color: 'white',
										backgroundColor: 'green',
										margin: '10px auto',
										width: '80%',
									}}
								>
									Yes
								</Button>
								<Button
									onClick={() => setIsDelete(false)}
									style={{
										textAlign: 'center',
										display: 'flex',
										justifyContent: 'center',
										color: 'white',
										backgroundColor: 'red',
										margin: '10px auto',
										width: '100%',
									}}
								>
									No
								</Button>
								</div>
							</Modal>
						</div>
					);
				})}
				{/* <div
					style={{ position: 'relative', top: '20px', left: '0', width: '90%' }}
				>
					<Whatsapp />
				</div> */}
				<div className="mt-2" style={{ marginBottom: '80px' }}>
					{/* {addr.length < 5 || deletedAddresses.length > 0 ? ( */}
					{addr.length < 5 ? (
						<p
							className="fontSize-16 text-center"
							onClick={() => navigate('/add/address')}
						>
							{t('Add New Address')}
						</p>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default AddressMainPage;
