import { tokenConfig, returnError } from './helperFunc';
import axios from 'axios';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

const SET_USER_DETAILS_LOADING = 'SET_USER_DETAILS_LOADING';
const CLEAR_USER_DETAILS_ERROR = 'CLEAR_USER_DETAILS_ERROR';

const FAIL_USER_DETAILS_DATA = 'FAIL_USER_DETAILS_DATA';
const SCAN_CODE_SUCCESS = 'SCAN_CODE_SUCCESS';

const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS';

const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';

const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
const ADD_CART_SUCCESS = 'ADD_CART_SUCCESS';
const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
const GET_STORE_LOCATOR_SUCCESS = 'GET_STORE_LOCATOR_SUCCESS';
const GET_RATING_REVIEW_SUCCESS = 'GET_RATING_REVIEW_SUCCESS';
const FETCH_RATING_REVIEW_SUCCESS = 'FETCH_RATING_REVIEW_SUCCESS';
const SET_ADDRESS_SUCCESS = 'SET_ADDRESS_SUCCESS';

const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';

const GET_POINTS_SUCCESS = 'GET_POINTS_SUCCESS';
const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
const GET_REWARD_SUCCESS = 'GET_REWARD_SUCCESS';

const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
const SUCCESS_DATA = 'SUCCESS_DATA';
const userUrlPath = '/api/user';

export const GLOBAL_CLEAR_ERROR = 'GLOBAL_CLEAR_ERROR';

export const clearError = () => ({
  type: GLOBAL_CLEAR_ERROR,
});
export const getUserDetails = (userDetails) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(`${userUrlPath}/details`, tokenConfig());
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const scanCode = (scanData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/scancode`,
      scanData,
      tokenConfig()
    );
    dispatch({ type: SCAN_CODE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const getOrderHistory = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/order/fetch`,
      sendData,
      tokenConfig()
    );
    dispatch({ type: GET_ORDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};


export const getAddresses = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/address/fetch`,
      {},
      tokenConfig()
    );
    dispatch({ type: GET_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};


export const addAddress = (address) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/address/add`,
      address,
      tokenConfig()
    );
    dispatch({ type: ADD_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};


export const updateAddress = (address) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/address/update`,
      { address },
      tokenConfig()
    );
    dispatch({ type: EDIT_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};
export const setAddress = (address) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/address/set`,
      { address },
      tokenConfig()
    );
    dispatch({ type: SET_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const editUserDetails = (userDetails, multipart) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      // `${userUrlPath}/details/edit`,
     `${userUrlPath}/profile/update`,
      userDetails,
      tokenConfig(multipart)
    );
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const getBrands = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/brands/fetch`,
      {},
      tokenConfig()
    );
    dispatch({ type: GET_BRANDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const getPointShop = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });
    const { data } = await axios.post(
      `/api/shop/pointshop/fetch`,
      {},
      tokenConfig()
    );
    dispatch({ type: GET_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const getPoints = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/point/fetch`,
      {},
      tokenConfig()
    );
    dispatch({ type: GET_POINTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};
export const getNews = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/news/fetch`,
      {},
      tokenConfig()
    );
    dispatch({ type: GET_NEWS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};
export const getCart = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `/api/shop/cart/fetch`,
      {},
      tokenConfig()
    );
    dispatch({ type: GET_CART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};
export const addCart = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      // `${userUrlPath}/cart/create`,
      `/api/shop/cart/create`,
      sendData,
      tokenConfig()
    );
    dispatch({ type: ADD_CART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};
export const updateCart = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `/api/shop/cart/set`,
      sendData,
      tokenConfig()
    );
    dispatch({ type: ADD_CART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};
export const checkItem = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `/api/shop/item/check`,
      sendData,
      tokenConfig()
    );
    dispatch({ type: GET_ITEM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const getReward = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/reward/fetch`,
      sendData,
      tokenConfig()
    );
    dispatch({ type: GET_REWARD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};


export const updateVoucherUsed = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/voucher/used`,
      sendData,
      tokenConfig()
    );

    dispatch({ type: SUCCESS_DATA, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};


export const createOrder = (sendData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `/api/shop/order/create`,
      sendData,
      tokenConfig()
    );
    dispatch({ type: CREATE_ORDER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};


export const getStoreLocator = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `/api/store/storeLocator/fetch`,
      {},
      tokenConfig()
    );
    dispatch({ type: GET_STORE_LOCATOR_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const createRatingReview = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `/api/shop/rating/create`,
      newData,
      tokenConfig()
    );
    dispatch({ type: GET_RATING_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

export const fetchRatingReview = (newData) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER_DETAILS_ERROR });
    dispatch({ type: SET_USER_DETAILS_LOADING });

    const { data } = await axios.post(
      `/api/shop/rating/fetch`,
      newData,
      tokenConfig()
    );
    dispatch({ type: FETCH_RATING_REVIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_USER_DETAILS_DATA, payload: returnError(error) });
  }
};

const initialState = {
  error: null,
  loading: false,
  userToken: localStorage.getItem('HALEON-REWARDS_USER_TOKEN') || null,
  addresses: [],
  categoryList: [],
  itemList: [],
  cartList: [],
  orderItems: [],
  orderVoucher: [],
  orderList: [],
  rewardList: [],
  rewardImage: [],
  usedVouchers: [],
  rewardVoucherImage: [],
  kids: [],
  newsList: [],
  mapStores: [],
  mapStates: [],
  // shops: [],
};

export const userDetailsReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter

    case SET_USER_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_USER_DETAILS_ERROR:
    case GLOBAL_CLEAR_ERROR:
      return {
        ...state,
        orderObj: null,
        code_group: null,
        message: null,
        error: null,
      };

    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
        error: null,
      };

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        newsList: action.payload.data,
        loading: false,
        error: null,
      };
    case SET_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: action.payload.data,
        message: action.payload.message,
        loading: false,
        error: null,
      };

    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: action.payload.data,
        loading: false,
        error: null,
      };

    case ADD_ADDRESS_SUCCESS:
    case EDIT_ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.payload.data,
        updateSuccess: action.payload.message, // for update api
        loading: false,
        error: null,
      };
    case SCAN_CODE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        code_group: action.payload.code_group,
        loading: false,
        error: null,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        shops: action.payload.shops,
        loading: false,
        error: null,
      };

    case GET_CART_SUCCESS:
      return {
        ...state,
        deliveryAddress: action.payload.deliveryAddress,
        getSelectedItems: action.payload.getSelectedItems,
        items: action.payload.items,
        loading: false,
        error: null,
      };
    case GET_REWARD_SUCCESS:
      return {
        ...state,
        voucherList: action.payload.voucherList,
        loading: false,
        error: null,
      };
    case GET_ITEM_SUCCESS:
      return {
        ...state,
        getPointShops: action.payload.getPointShops,
        loading: false,
        error: null,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orderObj: action.payload.orderObj,
        orderData: action.payload.orderData,
        savedOrderItems: action.payload.savedOrderItems,
        message: action.payload.message,
        loading: false,
        error: null,
      };
    case GET_POINTS_SUCCESS:
      return {
        ...state,
        pointsEarned: action.payload.earned,
        usedPoints: action.payload.used,
        loading: false,
        error: null,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        // orderList: action.payload.data,
        order: action.payload.data.order,
        orderItems: action.payload.data.items,
        orderVouchers: action.payload.data.vouchers,
        loading: false,
        error: null,
      };

    case GET_STORE_LOCATOR_SUCCESS: 
      return {
        ...state,
        mapStores: action.payload.mapStores,
        mapStates: action.payload.mapStates,
        loading: false,
        error: null,
      };
    case ADD_CART_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
        error: null,
      };
      case GET_RATING_REVIEW_SUCCESS: 
      return {
        ...state,
        message: action.payload.message,
        loading: false,
        error: null,
      };
      case FETCH_RATING_REVIEW_SUCCESS: 
      return {
        ...state,
        foundRating: action.payload.foundRating,
        foundAllRatings: action.payload.foundAllRatings,
        loading: false,
        error: null,
      };
    case SUCCESS_DATA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case LOGOUT_SUCCESS:
      return initialState;

    case FAIL_USER_DETAILS_DATA:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
