import React, { useState, useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Draggable from "react-draggable";
import { getReward } from "../reducer/userDetailsReducer";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Barcode from "react-barcode";
import useWindowDimensions from "../hooks/useWindowDimensions";

const COUNTDOWN_DURATION = 300;

const BarcodeTimer = () => {
	const { height, width } = useWindowDimensions();

	const { voucherList, foundAllRatings } = useSelector(
		(state) => state.userDetails
	);
	//   const [showBarcode, setShowBarcode] = useState(false);
	const [startCountdown, setStartCountdown] = useState(false);
	const [remainingTime, setRemainingTime] = useState(0);
	const [showBarcode, setShowBarcode] = useState(false);
	const [data, setData] = useState({ value: "", name: "" });
	const [displayTime, setDisplayTime] = useState(remainingTime);
	const [modalRemainingTime, setModalRemainingTime] = useState(0);

	const [timeRemaining, setTimeRemaining] = useState("");

	const diff_minutes = (dt2, dt1) => {
		var diff = (dt2.getTime() - dt1.getTime()) / 1000;
		// diff /= 60;
		return Math.abs(Math.round(diff));
	};
	useEffect(() => {
		if (voucherList && voucherList.length) {
			for (let i = 0; i < voucherList.length; i++) {
				if (voucherList[i].type === "BARCODE") {
					let dt1 = new Date(voucherList[i].dateuse);
					let dt2 = new Date();
					// console.log("dt1", dt1);
					// console.log("dt2", dt2);

					const result = diff_minutes(dt2, dt1);
					// console.log("result: ", result);
					if (result < COUNTDOWN_DURATION && remainingTime === 0) {
						setRemainingTime(COUNTDOWN_DURATION - result);
						setStartCountdown(true);
						setShowBarcode(true);

						setModalRemainingTime(COUNTDOWN_DURATION - result);
						setData({ value: voucherList[i].code, name: voucherList[i].name });
					}
				}
			}
		}
	}, [voucherList]);

	useEffect(() => {
		let timeout;
		if (remainingTime > 0) {
			timeout = setTimeout(() => {
				// const newTime = remainingTime - 1;
				// setRemainingTime(newTime);
				setDisplayTime((prevTime) => prevTime - 1);
			}, 1000);
		}
		return () => clearTimeout(timeout);
	}, [remainingTime, displayTime]);

	// console.log(remainingTime);
	// console.log(displayTime);

	return (
		<div>
			<div
				style={{
					// overlay container on top
					top: 20,
					zIndex: 3,
					position: "fixed",
					// prevent dragging to the edge
					width: "90vw",
					height: "85vh",
					// ensure the behind elements are clickable
					pointerEvents: "none",
					// always the overlay container div
					left: '50%',
					transform: 'translate(-50%, 0)'
				}}
			>
				{!startCountdown ? null : (
					<Draggable
						// ensure default is always within the parent div
						defaultPosition={{ x: width - 120, y: height - 190 }}
						style={{ position: "absolute" }}
						bounds="parent"
					>
						<div
							style={{ position: "absolute", pointerEvents: "initial" }}
							onTouchStart={() => setShowBarcode(true)}
						>
							<CountdownCircleTimer
								isPlaying={startCountdown && modalRemainingTime > 0}
								initialRemainingTime={
									modalRemainingTime < 0 ? 0 : modalRemainingTime
								}
								duration={COUNTDOWN_DURATION}
								colors={["#28BF00", "#EFD704", "#EF3C04", "#EF3C04"]}
								colorsTime={[COUNTDOWN_DURATION, 180, 60, 0]}
								onComplete={() => {
									setShowBarcode(false);
									setStartCountdown(false);
								}}
								size={70}
							>
								{({ remainingTime }) => {
									const minutes = Math.floor(remainingTime / 60);
									const seconds = remainingTime % 60;

									setTimeRemaining(
										`${minutes <= 0 ? 0 : minutes}:${
											seconds <= 0 ? `00` : seconds.toString().padStart(2, "0")
										}`
									);
									return (
										<div>
											<div>{timeRemaining}</div>
										</div>
									);
								}}
							</CountdownCircleTimer>
						</div>
					</Draggable>
				)}
			</div>
			{/* { remainingTime > 0 ? ( */}
			{remainingTime > 0 ? (
				<Modal
					open={showBarcode}
					centered
					footer={null}
					width={300}
					onCancel={() => {
						setShowBarcode(false);
					}}
					style={{
						zIndex: "2",
					}}
					closeIcon={<DownOutlined />}
				>
					<div className="text-center">
						<div className="mt-3"></div>
						<div>
							<div
								style={{
									display: "flex",
									padding: "8px 0px",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									gap: "16px",
									borderRadius: "8px",
									border: "0.5px solid #D3D3D3",
									lineHeight: "15px",
								}}
							>
								<Barcode
									style={{ objectFit: "contain" }}
									value={data.value}
									font={"monospace"}
									textAlign="center"
									fontSize={18}
								/>
								<p
									style={{
										textAlign: "center",
										fontSize: "22px",
										fontWeight: "700",
									}}
								>
									{data.name}
								</p>
							</div>
							<p className="mt-1">Please show this to the cashier.</p>
							<p className="mt-1">
								Barcode expires in:
								{timeRemaining}
							</p>
						</div>
					</div>
				</Modal>
			) : null}
		</div>
	);
};

export default BarcodeTimer;
