import { Button, Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const VoucherUnavailable = ({view, setView}) => {
    const navigate = useNavigate();

  return (
    <div>
      <Modal
        open={view}
        centered
        header={null}
        footer={null}
        closable={false}
        width={300}
      >
        <div>
          <h2 className="text-center">Before you proceed...</h2>
          <p className="text-center mt-1">
            Vouchers are currently out of stock. Do you wish to
            continue uploading receipt to earn points?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={() => setView(false)}
              style={{
                backgroundColor: "black",
                width: "30%",
                border: "none",
                color: "white",
              }}
            >
              YES
            </Button>
            <Button
              onClick={() => navigate("/home")}
              style={{
                backgroundColor: "black",
                width: "30%",
                border: "none",
                color: "white",
              }}
            >
              NO
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VoucherUnavailable;
